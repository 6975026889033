import {BackendModule, InitOptions, ReadCallback, Services} from 'i18next';
import type { CustomI18nBackendProps } from "@App/@types/translationTypes";

class CustomI18nBackend implements BackendModule<CustomI18nBackendProps> {
    type: 'backend' = 'backend';
    static type: 'backend' = 'backend';

    private services: Services;
    private options: CustomI18nBackendProps;

    /**
    * Constructor initializes the backend module.
    * @param services - The services provided by i18next
    * @param options - The backend options provided during initialization
    * @param i18nextOptions - Global i18next options, useful for accessing global configuration
    */
    constructor(
        services: Services,
        options: CustomI18nBackendProps,
        i18nextOptions: InitOptions // Store for potential future use (logging, debugging, etc.)
    ) {
        this.services = services;
        this.options = options;
    }

    /**
     * Required init method for i18next backend modules
     * Sets up backend configuration and services
     */
    init(
        services: Services,
        backendOptions: CustomI18nBackendProps,
        i18nextOptions: InitOptions
    ): void {
        this.services = services;
        this.options = {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
          ...backendOptions
        };
    }

    /**
     * Reads translations for a given language and namespace
     * Handles both API and local file loading
     * Merges backend translations with local translations while preserving the local namespace
     * @param language - Language code to load
     * @param namespace - Translation namespace
     * @param callback - Callback to return loaded translations or error
     */
    async read(language: string, namespace: string, callback: ReadCallback): Promise<void> {
        try {
            // If API endpoint is provided, use it
            if (this.options.translationsEndpoint) {
                const response = await fetch(`${this.options.translationsEndpoint}/${language}`);
                if (!response.ok) throw new Error('Error: failed to fetch translations.');

                const backendData = await response.json();

                // Get existing translations (local ones)
                const existingTranslations = this.services.resourceStore.data?.[language]?.['local'] || {};

                // Merge translations, keeping local namespace translations separate
                const mergedData = {
                    ...backendData,
                    local: existingTranslations
                };

                callback(null, mergedData);
                return;
            }

            // Fallback to i18next's default fileISH loading behavior
            fetch(this.options.loadPath!.replace('{{lng}}', language).replace('{{ns}}', namespace))
                .then(response => response.json())
                .then(data => callback(null, data))
                .catch(error => callback(error, null));

        } catch (error) {
            console.error('Translation fetch error:', error);
            callback(error as Error, null);
        }
    }
}

export default CustomI18nBackend;
