import React  from 'react';
import { AppHeader } from './AppHeader';
import { AppSidebar } from './AppSidebar';
import { AppBreadcrumbs } from './AppBreadcrumbs';
import { TransparentHeader } from './TransparentHeader';
import { WhiteHeader } from './WhitHeader';

const headerComponents = {
  AppHeader,
  AppSidebar,
  AppBreadcrumbs,
  WhiteHeader,
  TransparentHeader
}

export default headerComponents;
