import {
  PlusOutlined,
  ReloadOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {
  App,
  Button,
  Form,
  Divider,
  Input,
  Modal,
  Row,
  Space,
  Tag,
  Col,
  Grid,
  Typography,
  Flex,
  AutoComplete
} from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import type { AutoCompleteProps, TableProps} from 'antd/lib';
import type { ColumnsType, FilterValue, SorterResult } from 'antd/lib/table/interface';

import { capitalize, highlightText } from '@App/components/helpers';
import { useKeycloak } from '@App/settings/keycloak';
import { handler } from '@App/settings/ApiHandler';
import { Language } from '@AppRoot';
import AppTable from '@App/components/tables/TableComponent';
import KC from "@App/@types/keycloakTypes";


type TranslationValues = {
  et: string;
  en: string;
  ru?: string;
  de?: string;
}

type Translation = TranslationValues & {
  key: string;
};

type NewTranslationType = {
  key: string;
  namespace: string;
  translations: TranslationValues;
}

type TranslationType = NewTranslationType & {
  id: number;
}

type SuccessResponse = {
  languages: Array<Language>
  namespaces: Array<string|TranslationType['namespace']>;
  translations: Array<TranslationType> 
}
type PostResponse = Responses.Default & {
  status: Responses.Default['variant']
}
const getTranslations = (keycloak: KC.KCType) => handler<SuccessResponse>({
  method: 'GET',
  path: '/v3/manage/translations/',
}, keycloak);

type TranslationPayload = API.PayloadType & {
  items: NewTranslationType[]
}

const postTranslations = (keycloak: KC.KCType, payload: TranslationPayload) => handler<PostResponse>({
  method: 'POST',
  path: '/v3/manage/translations/',
  payload
}, keycloak);

type NsOption = {
  key: string;
  value: string;
  label: string;
  text: string;
}
const TranslationPage: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [postLoading, setPostLoading] = React.useState<boolean>(false);
  const [newTranslation, setNewTranslation] = React.useState<Translation | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [action, setAction] = React.useState<'new'|'update'>('new');
  const [filteredList, setFilteredList] = React.useState<TranslationType[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
  // const [namespaceOptions, setNamespaceOptions] = React.useState<AutoCompleteProps['options']>([]);

  const [filteredInfo, setFilteredInfo] = React.useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = React.useState<SorterResult<TranslationType>>({});

  const [data, setData] = React.useState<SuccessResponse|undefined>(); // fetched data will be stored here

  const {keycloak} = useKeycloak();
  const { message } = App.useApp();
  const { xs, sm, md } = Grid.useBreakpoint()
  const [form] = Form.useForm();
  const { t } = useTranslation();
  
  const handleModalClose = () => {
    setIsModalOpen(false);
    form.resetFields()
  };

  const handleChange: TableProps<TranslationType>['onChange'] = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<TranslationType>);
  };
 
  const handleUpdateTranslation = (key:string) => (e:React.MouseEvent<HTMLElement, MouseEvent>) => {
    const find = data?.translations.find(i => i.key == key );
    if (find) {
      form.setFieldsValue(find);
    }
    setAction('update')
    setIsModalOpen(true);
  }
 
  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  const handleNsChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const tableActionItems = [{
    key: "refresh",
    icon: <ReloadOutlined/>,
    label: 'Refresh',
    // onClick: rest.doRefresh,
  },{
    key: "new",
    icon: <PlusOutlined />,
    label: 'Import JSON file',
    // onClick: () => nav('/manage/access/users/new'),
  }];

  const namespaceOptions = [
    // { key: 'all', text: t('buttons.all'), label: t('buttons.all'), value: ''},
    ...data?.namespaces.map((i)=> ({
      key: i,
      value: i,
      label: capitalize(i),
      text: capitalize(i),
    })) ?? []
  ];
  
  const handleNewTranslation = () => {
    form.resetFields();
    setAction('new');
    setIsModalOpen(true);
  }

  const handleAddTranslation = () => {
    setPostLoading(true);
    // if (newTranslation) {
    //   // Send newTranslation to backend
    //   // Then, if successful, you can append it to your translations state or refetch translations from the backend
    // }
    form.validateFields().then(() => {
      const payload = form.getFieldsValue();

      postTranslations(
        keycloak!,
        { action, ...payload }
      )
      .then(suc => {
        console.info(suc);
        if (suc.status == 'success') {
          message.success(t(`forms.${suc.success}`, suc.message || ''))
        }
        fetchData();
      })
      .catch((err)=>{
        console.info(err)
        message.error('Failed to fetch translations')
      })
      .finally(() => {
        setIsModalOpen(false);
        setNewTranslation(null);
      });

    })
    .catch(() => {
      message.warning(t('forms.error.missingFields'))
    })
    .finally( () => {
      setIsLoading(false);
      setPostLoading(false);
    });
  };

  const fetchData = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getTranslations(keycloak!)
      .then(suc => {
        setData(suc);
      })
      .catch((err)=>{
        console.log(err)
        message.error('Failed to fetch translations')
      })
      .finally(() => {
        setIsLoading(false)
      });

    return () => {
      abortController.abort();
    };
  };

  React.useEffect(() => {
    // Fetch data on component mount
    const cleanup = fetchData();

    return () => {
      cleanup();
    };
  }, []);

  React.useEffect(() => {
    if (data?.translations) {
      const newList = selectedTags.length > 0
        ? data.translations.filter(({namespace}) => selectedTags.includes(namespace))
        : data.translations

      const newFilteredList = search.length > 2 ? newList.filter((trans) =>
        trans.key.toLowerCase().includes(search.toLowerCase()) ||
        trans.translations?.et?.toLowerCase().includes(search.toLowerCase()) ||
        trans.translations?.en?.toLowerCase().includes(search.toLowerCase()) ||
        // trans.translations?.de?.toLowerCase().includes(search.toLowerCase()) ||
        // trans.translations?.ru?.toLowerCase().includes(search.toLowerCase()) ||
        // trans.translations.toString().toLowerCase().includes(search.toLowerCase()) ||
        trans.namespace.toLowerCase().includes(search.toLowerCase())
      ) : newList

      setFilteredList(newFilteredList);
    }
  }, [search, data?.translations, selectedTags]);


  const columns: ColumnsType<TranslationType> = [
    {
      key: 'namespace',
      title: t('table.column.label.translationKeys'),
      dataIndex: 'namespace',
      filters: namespaceOptions,
      responsive: ['sm'],
      // filteredValue: activeNamespace.current || filteredInfo.namespace || null,
      onFilter: (value: boolean | React.Key, record:TranslationType) => typeof value == 'string' && record?.namespace?.includes(value),
      sorter: (a:TranslationType, b:TranslationType) => a.namespace.localeCompare(b.namespace),
      sortOrder: sortedInfo.columnKey === 'namespace' ? sortedInfo.order : null,
      ellipsis: true,
      width: '25%',
      render: (_, record)=> {
        return (
          <Flex vertical>
            <Typography.Text
              type={'secondary'}
              style={{
                fontSize: '1.1em',
                flexBasis: '100%',
                whiteSpace: 'normal', // Allow text to wrap
                wordBreak: 'break-word', // Break long words if necessary
              }}
            >
              {/* split into an array of substrings, reassembles it by adding the dot back and a zero-width space (\u200B).
                'string'.split('.').join('.\u200B')}
              */}
              {highlightText(record.key.split('.').join('.\u200B'), search)}
            </Typography.Text>
            <div>
              <Tag
                color={'gray'}
                bordered={false}
                style={{ borderRadius: 50 }}
              >
                {record.namespace}
              </Tag>
            </div>
          </Flex>
        )
      }
    },
    {
      key: 'translations',
      title: t('table.column.label.translations'),
      dataIndex: 'translations',
      width: 'auto',
      ellipsis: true,
      render: (_:TranslationValues , item:TranslationType) => {
        return (
          <Space
            wrap
            size={[0, 8]}
            direction={'vertical'}
            style={{ width: '100%' }}
          >
            {!sm && (
              <span>
                <Tag
                  color={'gray'}
                  bordered={false}
                  style={{
                    borderRadius: 50,
                    whiteSpace: 'normal', // Allow text to wrap
                  }}
                >
                  {highlightText(item.key.split('.').join('.\u200B'), search)}
                </Tag>
              </span>
            )}
            {
              data?.languages.map( (lng, idx) => {
                return (
                  <React.Fragment key={`${item.key}-${idx}`}>
                    {idx !== 0 && <Divider style={{ margin: 0 }}/>}
                    <Typography.Paragraph
                      editable={false}
                      style={{
                        margin: 0,
                        whiteSpace: 'normal', // Allow text to wrap
                        wordBreak: 'break-word',
                      }}
                    >
                      {highlightText(item.translations[lng], search)}
                    </Typography.Paragraph>
                    {/*<Tooltip key={idx} title={item.translations[lng]}>
                      <Tag color={item.translations[lng] ? 'success' : 'error'}>
                        { lng }
                      </Tag>
                    </Tooltip>*/}
                  </React.Fragment>
                )
              })
            }
        </Space>
        )
      },
    },
    {
      key: 'actions',
      title: !xs && t('table.column.label.action'),
      dataIndex: 'id',
      align: 'right',
      width: xs ? 50 : md ? 120 : 100,
      render: (_, item:TranslationType) => {
        return (
          <Space wrap direction={'vertical'}>
            <Button
              type="text"
              shape={md ? 'round' : 'circle'}
              icon={<EditOutlined />}
              onClick={handleUpdateTranslation(item.key)}
            >
              {md && t('forms.btn.update')}
            </Button>
            {/*<Button
              danger
              type="text"
              shape={md ? 'round' : 'circle'}
              icon={<DeleteOutlined />}
              onClick={handleUpdateTranslation(item.key)}
            >
              {md && t('forms.btn.delete')}
            </Button>*/}
          </Space>
        )
      }
    }
  ];
  
  return (
    <div>
      <Flex
        wrap
        gap={'1rem'}
        justify={'space-between'}
      >
        <Input.Search
          placeholder={t('forms.placeholders.search')}
          value={search}
          onSearch={handleSearch}
          onChange={handleSearchChange}
          style={{ flex: '1 1 400px' }}
        />

        {/*<Button.Group style={{ marginLeft: 'auto' }}>
          <Button
            type="primary"
            shape={'round'}
            icon={<PlusOutlined />}
            onClick={handleNewTranslation}
          >
            {t('forms.link.add.translation')}
          </Button>
          <Dropdown
            menu={{ items: tableActionItems }}
            trigger={['click']}
          >
            <Button shape={'round'} icon={<MoreOutlined />} />
          </Dropdown>
        </Button.Group>*/}
      </Flex>

      <Row style={{ margin: '1rem 0' }}>
        <Col span={24}>
          <Divider>
            {t('pages.section.translationCategories')}
          </Divider>
          <Space direction="horizontal" wrap>
            <Tag.CheckableTag
              key={'all'}
              checked={selectedTags.length == 0}
              onChange={(checked) => checked && setSelectedTags([])}
            >
              {t('buttons.all')}
            </Tag.CheckableTag>
            {
              namespaceOptions.map(ns => (
                <Tag.CheckableTag
                  key={ns.key}
                  checked={selectedTags.includes(ns.key)}
                  onChange={(checked) => handleNsChange(ns.key, checked)}
                >
                  {ns.label}
                </Tag.CheckableTag>
              ))
            }
          </Space>
        
        </Col>
      </Row>
       
      <Row style={{ margin: '1rem 0' }}>
        <AppTable<TranslationType & { status?: string | undefined; }>
          rowKey="key"
          tableName='translationTable'
          tableTitle='table.title.translations'
          loading={isLoading}
          dataSource={filteredList}
          extraColumns={columns}
          onChange={handleChange}
          counter={filteredList.length}
          sticky={{ offsetHeader: 64 }}
          isAdmin={false}
          isSelectable={false}
          headerButtons={
            <Button
              key={'add-new'}
              type="primary"
              shape={'round'}
              icon={<PlusOutlined />}
              onClick={handleNewTranslation}
            >
              {t('forms.link.add.translation')}
            </Button>
          }
        />
      </Row>
      
      <Modal
        title={t(`forms.title.${action}Translation`)}
        open={isModalOpen}
        confirmLoading={postLoading}
        okText={t(`forms.btn.${action}`)}
        onOk={handleAddTranslation}
        okButtonProps={{ shape: 'round' }}
        onCancel={handleModalClose}
        cancelButtonProps={{ shape: 'round' }}
        styles={{
          content: { borderRadius: '2rem' },
        }}
        width={600}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinishFailed={console.info}
        >
          <Form.Item
            label={t("forms.label.namespace", 'Tõlkemoodul')}
            name={['namespace']}
            rules={[
              {
                required: true
              }
            ]}
          >
            <AutoComplete
              maxLength={250}
              options={
                data?.namespaces.map((i)=> ({
                  key: i,
                  value: i,
                  label: capitalize(i),
                  text: capitalize(i),
                })) ?? []
              }
              style={{ width: 200 }}
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={(value) => setNewTranslation((prev) => ({ ...prev!, namespace: value }))}
            />
          </Form.Item>

          {/*<Form.Item
            label={t("Namespace")}
            name={['namespaceOld']}
            rules={[]}
          >
            <Input
              showCount
              maxLength={250}
              onChange={(e) => setNewTranslation((prev) => ({ ...prev!, key: e.target.value }))}
            />
          </Form.Item>*/}

          <Form.Item
            label={t(['forms.label.translationKey', 'table.column.label.translationKeys'])}
            name={['key']}
            rules={[
              {
                pattern: /^[a-zA-Z0-9öäüõÖÄÜÕ][a-zA-Z0-9_-öäüõÖÄÜÕ]*(\.[a-zA-Z0-9_-öäüõÖÄÜÕ]+)*$/,
                message: t('forms.validate.invalidSlug', 'Invalid key format!'),
              }, {
                required: true
              }
            ]}
          >
            <Input
              showCount
              maxLength={250}
              onChange={(e) => setNewTranslation((prev) => ({ ...prev!, key: e.target.value }))}
            />
          </Form.Item>

          <Form.Item
            label={t('forms.label.translationInEstonian', 'Estonian (ET)')}
            name={['translations', 'et']}
            rules={[{ required: true }]}
          >
            <Input.TextArea
              showCount
              maxLength={2000}
              onChange={(e) => setNewTranslation((prev) => ({ ...prev!, et: e.target.value }))}
            />
          </Form.Item>

          <Form.Item
            label={t('forms.label.translationInEnglish', 'English (EN)')}
            name={['translations', 'en']}
            rules={[{ required: true }]}
          >
            <Input.TextArea
              showCount
              maxLength={2000}
              onChange={(e) => setNewTranslation((prev) => ({ ...prev!, en: e.target.value }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};


export default TranslationPage
