import { Divider, DividerProps, Typography } from 'antd';
import React from 'react';

interface DividerTitleI extends DividerProps {
  title: string | React.ReactNode;
  titleStyles?: React.CSSProperties;
  big?: boolean;
}

export const DividerTitle: React.FC<DividerTitleI> = ({
  title, titleStyles, big, ...rest
}) => {
  return (
    <Divider orientation="left" style={{ marginTop: '3rem'}} {...rest}>
      <Typography.Title
        color='primary'
        style={{
          margin: 0,
          fontSize: Boolean(big) ? '1.5em' : '1em',
          ...titleStyles
        }}
      >
        { title }
      </Typography.Title>
    </Divider>
  )
};
