import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import React from 'react';
import { Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
  orderNumber?: number;
  index: number ;
}

export const DraggableRow = ({ children, orderNumber, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const {t} = useTranslation();

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9 } : {}),
  };

  const orderCell = (
    <td className="draggable-order-number">
      <Typography.Text strong type='secondary'>
        {orderNumber}
      </Typography.Text>
    </td>
  );
  
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {
        React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === 'field-order') {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <div data-order-number={orderNumber +""} className="draggable-order-number">
                  <MenuOutlined
                  // <MenuOutlined optionaly onHover it could render this menu icon
                    ref={setActivatorNodeRef}
                    // className='draggable-order-number'
                    style={{ touchAction: 'none', cursor: 'move' }}
                    {...listeners}
                  />

                  <Tooltip title={t("table.column.label.orderNumber")}>
                    <Typography.Text strong type='secondary'>
                      {/* @ts-ignore */}
                      {orderNumber ?? props.name}
                    </Typography.Text>
                  </Tooltip>

                </div>
              ),
            });
          }
          return child;
        })
      }
    </tr>
  );
};
