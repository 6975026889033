import { EditTwoTone, FileAddTwoTone } from '@ant-design/icons';
import { Typography, Row, Form, Divider} from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

// import { ActionTypes } from '../settings/reducers';
// import { ApplicationState } from '../settings/StateManager';
import { useKeycloak } from '@App/settings/keycloak';
import { FieldForm } from '@App/components/forms/FieldForm';
import { handler } from '@App/settings/ApiHandler';

export type NewFieldProps = {
  name: string;
  label: string | null;
  type: string;
  settings: {
    texts?: string[];
    options?: string[];
    required?: boolean;
    error_text?: string;
    helper_text?: string;
  };
};

export type FieldProps = NewFieldProps & {
  id: number;
};

const defaultValues = {
  name: '',
  label: '',
  type: '',
  settings: {
    helper_text: '',
    error_text: '',
  },
}

export const FieldSingle: React.FC = () => {
  const { id } = useParams();
  const [action, setAction] = React.useState<'new' | 'update'>('new');
  // const [formValues, setFormValues] = React.useState<NewFieldProps | FieldProps>(defaultValues);
  const {keycloak} = useKeycloak();
  const { t } = useTranslation();
  const isMounted = React.useRef(false);
  const newField = React.useRef<NewFieldProps|null>();
  const [form] = Form.useForm();

  const postField = (data: object) => {
    handler<{ field: object }>({
      path: '/v1/field/' + (id ?? action),
      method: 'POST',
      payload: { action, ...data },
    }, keycloak!).then((d) => {
      console.log(d);
    });
  };


  
  React.useEffect(() => {
    const ac = new AbortController();
    if (!isMounted.current) {
      isMounted.current = true;
      if ( id && !['new', 'add'].includes(id) ) {
        handler<{ field: FieldProps }>({
          path: '/v2/field/' + id,
          method: 'GET',
        }, keycloak!)
        .then(({ field }) => {
          if ('id' in field && field['id']) {
            setAction('update');
          }
          form.setFieldsValue(field);
          // setFormValues(field);
        });
        
      }
    }
    
    return () => {
      ac.abort()
      // console.info('unmounted');
    };
  }, []);

  const handleFormPost = (values: any) => {
    // console.log(values);
    // console.log(newField.current);
    postField({...values, id: !['new', 'add'].includes(id!) ? id : null });
  };
  // const handleFormValuesChange = (name: any, allValues: any) => {
  //   setFormValues(allValues);
  //   console.log(formValues);
  // };
  // const onValuesChange = (name: string, value: any) => {
  //   // console.log(name, value)
  //   newField.current = value;
  //   // setFormValues((prevValues) => ({ ...pnewFieldrevValues, [name]: value }));
  // };

  return (
    <div style={{ marginLeft: '1rem', maxWidth: 990 }}>
      <Row style={{ marginLeft: '1rem' }}>
        <Divider>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>
            {action === 'new' ? <FileAddTwoTone style={{ marginRight: 8}}/> : <EditTwoTone style={{ marginRight: 8}}/>}
            {t(`forms.title.${action}Field`)}
          </Typography.Title>
        </Divider>
      </Row>

      <FieldForm
        form={form}
        initialValues={defaultValues}
        onFinish={handleFormPost}
        // callback={handleFormValues}
        // formValues={formValues}
        // onValuesChange={onValuesChange}
        // onFormValuesChange={handleFormValuesChange}
      />

      {/* {
        process.env.REACT_APP_ENV !== 'production'
        && (
          <JsonValidator
            path='/v1/fields'
            action={action}
          />
        )
      } */}
    </div>
  );
};
