
import {
  Form, Input, Select, Space, Switch, Typography, FormProps, FormInstance, Descriptions, Tag, theme, List, Grid, Flex, SelectProps,
} from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { DataContext } from '@App/views/AccessView';
import { formatDateTime } from '../helpers';
import { Language } from '@AppRoot';


interface UserFormProps {
  action: 'new'|'update';
  form: FormInstance;
  isReady?: (isReady: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
}

export const UserForm: React.FC<FormProps & UserFormProps> = ({ action, isReady, ...props}) => {
  const { md } = Grid.useBreakpoint();
  const { token } = theme.useToken();
  const { t, i18n } = useTranslation();

  const {users, groups, facultiesGrouped} = React.useContext(DataContext);

  const username: string | undefined = Form.useWatch('username', props.form);
  const fullname: string | undefined = Form.useWatch('fullname', props.form);

  const checkUsername = async (_: any, username: string) => {
    const currentUserUuid = props.form.getFieldValue(['uuid']);
  
    // Check if the username exists in the users list, excluding the current user's uuid
    const isUsernameTaken = users.some(
      u => u.username === username && u.uuid !== currentUserUuid
    );
  
    if (isUsernameTaken) {
      // Throw error if username is taken
      return Promise.reject(new Error('Username is already taken'));
    }
    
    return Promise.resolve();
  };
  
  React.useEffect(() => {
    if (fullname && username && isReady) {
      if (
        username?.length > 2 &&
        fullname?.length > 2
      ) {
        isReady(true);
      } else {
        isReady(false);
      }
      
    }
  }, [username, fullname]);

  const lastUpdated = props.form?.getFieldValue('last_updated');
  const createdAt = props.form?.getFieldValue('created_at');

  const handleUsernameValueTrim = () => {
    const usr = props.form?.getFieldValue(['username']);
    if (usr && typeof usr === 'string') {
      props.form?.setFieldValue('username', usr.trim());
    }
  };

  const groupedFaculties = facultiesGrouped?.map((f, i) => 'children' in f
      ? ({
          key: f.code ?? i,
          label: (
            <Typography.Text strong color='secondary' style={{ fontSize: '.9em' }}>
            {f.labels[i18n.language as Language]}
            </Typography.Text>
          ),
          value: f.code,
          text: `${f.code} | ${f.name} | ${f.labels.toString()}`,
          options: f.children.map( child => ({
            key: child.code ?? `${f.code}-${child.code}`,
            value: child.code,
            text: `${f.code} | ${f.name} | ${f.labels.toString()}`,
            label: (
              <Flex key={`${i}-${child.code}`}>
                <Tag color='processing'>
                  <b>{child.code}</b>
                </Tag>
                <Typography.Text>
                  {child.labels[i18n.language as Language]}
                </Typography.Text>
              </Flex>
            )
          })).sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()))
        })
      : ({
          key: f.code ?? i,
          value: f.code,
          text: `${f.code} | ${f.name} | ${f.labels.toString()}`,
          label: (
            <Flex key={`${i}-${f.code}`}>
              <Tag color='processing'>
                <b>{f.code}</b>
              </Tag>
              <Typography.Text>
                {f.labels[i18n.language as Language]}
              </Typography.Text>
            </Flex>
          )
        })
  ).sort((a, b) => {
    // If both options are undefined or defined, compare by key.
    if ((a.options && b.options) || (!a.options && !b.options)) {
        return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
    } else {
        // If one option is undefined, sort such that defined option comes first.
        return a.options ? -1 : 1;
    }
});

  const groupOptions: SelectProps['options'] = groups.map((g,i) => ({
    key: g.id ?? g.name,
    value: g.id ?? g.name,
    text: `${g.label} | ${g.name} | ${g.description}`,
    label: (
      <Space key={`${g.name}-${i}`}>
        <Tag style={{ color: token.colorPrimary }} >
          {g.label}
        </Tag>
        <Typography.Text type='secondary'>
          {g.description}
        </Typography.Text>
      </Space>
    )
  }));

  return (
    <Form
      
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      name={action === 'new' ? t('forms.titles.newUser') : t('forms.titles.updateUser')}
      layout={md ? 'horizontal' : 'vertical'}
      onFieldsChange={console.log}
      {...props}
    >
      <Form.Item
        hidden
        name={['uuid']}
        label={t('forms.tooltip.uid')}
      >
        <Input readOnly />
      </Form.Item>
    
      <Form.Item
        name={['fullname']}
        label={t('forms.label.fullname')}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('forms.placeholders.name')} />
      </Form.Item>

      <Form.Item
        name={['username']}
        label={t('forms.label.username')}
        rules={[
          { required: true },
          { validator: checkUsername }
        ]}
      >
        <Input
          placeholder={t('forms.placeholders.username')}
          onBlur={handleUsernameValueTrim}
        />
      </Form.Item>

      <Form.Item
        name={['email']}
        label={t('forms.label.email')}
        rules={[{ required: false }]}
      >
        <Input placeholder={t('forms.placeholders.email')} />
      </Form.Item>

      <Form.Item
        name={['faculties']}
        label={t('forms.label.userFaculties')}
      >
        <Select
          placeholder={t('forms.placeholders.selectUserFaculties')}
          mode="multiple"
          showSearch
          allowClear
          options={groupedFaculties}
          filterOption={(input, option) => {
            return option?.text.toLowerCase().includes(input.toLowerCase())
              || false;
          }}
          // filterSort={(optionA, optionB) =>
          //   optionA?.key.toLowerCase().localeCompare(optionB?.key.toLowerCase())
          // }
        />
      </Form.Item>

      <Form.Item
        name={['roles']}
        label={t('forms.label.userGroups')}
      >
        <Select
          placeholder={t('forms.placeholders.selectUserGroups')}
          mode="multiple"
          allowClear
          showSearch
          options={groupOptions}
          filterOption={(input, option) => {
            return option?.text?.toLowerCase().includes(input.toLowerCase())
              || false;
          }}
        />
      </Form.Item>

      <Form.Item
        name={['isActive']}
        label={t('forms.label.isActive')}
        valuePropName='checked'
      >
        <Switch
          checkedChildren={t('forms.label.yes')}
          unCheckedChildren={t('forms.label.no')}
        />
      </Form.Item>

      { createdAt && (
        <Descriptions
          size='small'
          layout={md ? 'horizontal' : 'vertical'}
          column={1}
          labelStyle={{
            width: md ? '25%' : '100%',
            placeContent:  md ? 'end' : 'start',
          }}
          contentStyle={{
            marginLeft: md ? '0' : '1rem',
          }}
        >
          <Descriptions.Item label={t('forms.label.createdAt')}>
            {formatDateTime(createdAt, true, `DD MMMM YYYY - hh:mm:ss`)}
          </Descriptions.Item>
          { lastUpdated && (
            <Descriptions.Item label={t('forms.label.lastUpdate')}>
              {formatDateTime(lastUpdated, true, `DD MMMM YYYY - hh:mm:ss`)}
            </Descriptions.Item>
          )}
        </Descriptions>
      )}
      
      { props.children }

    </Form>
  );
};
