import type { MenuProps } from 'antd';
import { FloatButton, Grid, Layout, Space } from 'antd';
import { LoginOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { MobileDrawer } from './MobileDrawer';
import { useKeycloak } from '@App/settings/keycloak';
import { ActionTypes } from '@App/settings/reducers';
import { Theme } from '@AppRoot';


type MenuItem = Required<MenuProps>['items'][number];

interface IHeaderProps {
  navItems?: MenuItem[];
}

const { useBreakpoint } = Grid;

export const TransparentHeader: React.FC<IHeaderProps> = (props) => {
  const { state, dispatch } = React.useContext(ApplicationState);
  const {t} = useTranslation();
  const { keycloak, userData } = useKeycloak();
  const screens = useBreakpoint();


  const logoStyles = {
    color: state.theme === 'dark' ? '#ffffff' : '#2C5696'
  };

  const headerRowStyles: React.CSSProperties = {
    width: '100%',
    justifyContent: 'space-between'
  };

  const headerWrapperStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    // justifyItems: 'flex-start',
    width: '100%',
    height: screens.sm ? '1rem' : 64,
    // background: state.theme !== 'light' ? '#001529' : '#fff',
    background: 'transparent',
    padding: '0 1rem',
    position: 'sticky',
    top: 0,
    zIndex: 1
  };

  const handleThemeToggle = () => {
    dispatch({
      type: ActionTypes.Change_theme,
      payload: {
        theme: state.theme === Theme.Light ? Theme.Dark : Theme.Light,
      },
    });
  };

  const handleLangToggle = () => {
    dispatch({
      type: ActionTypes.Toggle_language,
      payload: {}
    });
  };

  const handleLogout = () => keycloak?.logout();

  const handleLogin = () => keycloak?.login();

  return (
    <Layout.Header style={headerWrapperStyles}>
      <Space align='center' wrap={false} style={headerRowStyles}>
        
        <MobileDrawer />

        <FloatButton.Group
          trigger="click"
          style={{
            top: 18,
            right: 18,
            bottom: -100
          }}
          icon={<UserOutlined />}
          >
            <FloatButton
              tooltip={t('site.theme.toggler')}
              onClick={handleThemeToggle}
              className='dark-toggler'
              icon={state.theme.includes(Theme.Dark) ? Theme.Light : Theme.Dark}
              style={{ fontWeight:700 }}
            />

          <FloatButton
            icon={state.language}
            onClick={handleLangToggle}
            className='language-toggler'
            tooltip={
              <>
                {t('site.language.toggler')}<br/>
                {t('site.language.current')}{' '}{state.language}
              </>
            }
          />

          {
            keycloak?.authenticated
            ? <FloatButton
                tooltip={t('buttons.logout', {username: userData?.username} )}
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              />
            : <FloatButton
                tooltip={t('buttons.login')}
                icon={<LoginOutlined />}
                onClick={handleLogin}
              />
          }
        </FloatButton.Group>
      </Space>
    </Layout.Header>
    
  );
};
