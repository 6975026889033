import dayjs from "dayjs";
import React from "react";
import { formatDateTime } from './helpers';
import type { ColumnType, TableProps } from 'antd/es/table';


const dateColumns = ['created_at', 'updated_at', 'updated']; // Replace with your actual date column keys
  const noListItemsText = 'No items detected. Are you sure you want to download an empty file?';

export type ExportFileTypes = 'csv' | 'xls' | 'xlsx' | 'pdf';

interface TableRow {
  [key: string]: any;
}

interface TableColumn {
  title?: string | any;
  dataIndex?: string | string[];
  children?: TableColumn[];
}

export type ExportProps = (
  columns: TableColumn[],
  dataSource: TableRow[],
  fileType: ExportFileTypes,
  fileName?: string
) => void;
/**
 * Exports table data to a file in either CSV, XLS, or XLSX format.
 * @param columns - Columns configuration of the table, including titles and dataIndex.
 * @param dataSource - Data source for the table, containing the actual data.
 * @param fileType - The type of file to export ('csv', 'xls', 'xlsx'). Defaults to 'csv'.
 */
export const exportToFile: ExportProps = (columns, dataSource, fileType = 'csv', fileName) => {
  if (!columns || !dataSource) {
    console.error('Invalid columns or dataSource');
    return;
  }
  // Check if the csvRows array is empty. If it is, prompt the user to confirm download of an empty file.
  if (!(dataSource.length > 0)) {
    if (!window.confirm(noListItemsText)) return;
  }

  const headers: string[] = columns.map(col => col.title ?? '');
  const data: string[][] = prepareData(dataSource, columns);

  const defaultFileName = `export ${dayjs().format('YYYY-MM-DD HH-mm')}`;
  const completeFileName = `${fileName || defaultFileName}`;
  console.log(headers)
  console.log(data)
  // Depending on fileType, process and download the appropriate format
  switch (fileType) {
    case 'csv':
      downloadCSV(headers, data, completeFileName);
      break;
    case 'xls':
      downloadSimpleXLS([headers, ...data], completeFileName);
      break;
    default:
      console.error('Unsupported file type');
  }
};

/**
 * Retrieves nested data from an object based on a given dataIndex.
 * @param data - The data object from which to retrieve the value.
 * @param dataIndex - A string or array of strings representing the path to the desired data.
 * @returns - The value from the data object at the specified dataIndex.
 */
 const getNestedData = (data: any, dataIndex: string | string[]) => {
  // If dataIndex is an array, iterate over the array to access nested data.
  if (Array.isArray(dataIndex)) {
    return dataIndex.reduce((acc, key) => acc[key], data);
  }
  // If dataIndex is a string, directly access the data.
  return data[dataIndex];
};

function prepareData(dataSource: TableRow[], columns: TableColumn[]): string[][] {
  return dataSource.map(row => columns.map(col => {
    let rawValue = getNestedData(row, col.dataIndex as string | string[]) ?? '';
    // Check if the column is a date column and format the date
    if (typeof col.dataIndex == 'string') {
      if (dateColumns.includes(col.dataIndex as string)) {
        rawValue = formatDateTime(rawValue); // Format known date values
      }
    }
    else if (Array.isArray(rawValue)) {
      rawValue = rawValue.join(', '); // Convert array into single string
    }

    if (typeof rawValue === 'number' || typeof rawValue === 'boolean') {
      return rawValue.toString(); // Convert numbers and booleans to string directly
    }
    else if (typeof rawValue === 'string') {
      return `"${rawValue.replace(/"/g, '""')}"`; // Escape double quotes for CSV
    }
    else {
      return ''; // Handle undefined or null values
    }
  }));
}

/**
 * Creates and downloads a CSV file with UTF-8 encoding.
 * @param data - Array of data rows (array of arrays).
 */
function downloadCSV(header: string[], rows: string[][], fileName: string) {
  const BOM = '\uFEFF';
  const firstRow = `${header.join(';')}\n`;
  const csvContent = BOM + firstRow + rows.map(row => row.join(';')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  triggerDownload(blob, `${fileName}.csv`);
}

function escapeHtml(text: string) {
  return text
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

/**
 * Creates and downloads a simple .xls file using pure JavaScript.
 * @param rows - Array of data rows (array of arrays).
 */
function downloadSimpleXLS(rows: string[][], fileName: string) {
  let tableHtml = '<html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"></head><body><table>';
  rows.forEach(row => {
    tableHtml += `<tr>${row.map(cell => `<td>${escapeHtml(cell.slice(1, -1))}</td>`).join('')}</tr>`;
  });
  tableHtml += '</table></body></html>';

  const blob = new Blob([tableHtml], { type: 'application/vnd.ms-excel' });
  triggerDownload(blob, `${fileName}.xls`);
}

/**
 * Helper function to trigger download of a blob as a file.
 * @param blob - Blob containing the file data.
 * @param fileName - Name of the file to save as.
 */
 function triggerDownload(blob: Blob, fileName: string) {
  const url = window.URL.createObjectURL(blob);
  // Create a temporary anchor ('<a>') element.
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = fileName;

  // Append the anchor element to the document's body.
  document.body.appendChild(a);
  
  a.click(); // Programmatically trigger a click event on the anchor.
  
  // Cleanup: remove the element and revoke the blob URL
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
