import {Button, Divider, Form, Space, Table, Tag, Typography} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import {ProjectForm} from "@App/components/forms/ProjectForm";
import UserSearchModal from "@App/components/UserSearchModal";

// import { ApplicationState } from '../settings/StateManager';

interface ProjectType {
  key: string;
  name: string;
  title: string;
  status: string;
  description?: string;
}

const onDelete = (record: ProjectType) => {
  console.log('Deleting record:', record);
  // Add delete logic here
};


export const Project: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <div>
      <Typography.Title level={3}>new or edit project</Typography.Title>
      <Typography.Paragraph>
        new project form or select or edit existing project
      </Typography.Paragraph>

      <Divider />

      <ProjectForm action={'new'} >
          <Form.List name={'shearedWith'}>
              {(fields, operation, meta) => {
                  return (
                      <Form.Item
                      >

                      </Form.Item>
                  )
              }}
          </Form.List>
      </ProjectForm>

      <UserSearchModal
          visible={open}
          onClose={() => setOpen(false)}
          onSelectUser={console.log}
      />
    </div>
  );
};
