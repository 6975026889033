import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, List, Row, Col, Spin } from 'antd';

interface ReportFormData {
  name: string;
  email: string;
  description: string;
}

type UrlItem = {
  key: string;
  name: string;
  path: string;
  icon?: React.ReactNode;
}

const mockLinks: UrlItem[] = [
  { key: "5", name: "Korduma kippuvad küsimused", path: "/kkk"},
  { key: "1", name: "Andmekaitse põhimõisted", path: "/to/blin"},
  { key: "2", name: "Privacy Policy", path: "/privacy-policy"},
  { key: "3", name: "Data Protection Guidelines", path: "/data-protection-guidelines"},
  { key: "4", name: "Terms of Use", path: "/terms-of-use"},
];

const ReportViolationForm: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: ReportFormData) => {
    try {
      setLoading(true);

      // Simulate sending data to the server using Fetch API
      const response = await fetch('your-api-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        message.success('Violation report submitted successfully! Thank you for helping us ensure data protection.');
        form.resetFields();
      } else {
        message.error('Failed to submit violation report. Please try again later.');
      }
    } catch (error) {
      message.error('An error occurred while submitting the report. Please try again later.');
    } finally {
      // setLoading(false);
    }
  };

  const onAnonymousSubmit = () => {
    form.validateFields().then((values) => {
      const anonymousValues: ReportFormData = {
        name: 'Anonymous',
        email: 'anonymous@example.com',
        description: values.description,
      };
      onFinish(anonymousValues);
    });
  };

  const renderLinks = (item: UrlItem) => (
    <List.Item
      // style={{ justifyContent: 'center' }}
    >
      <Typography.Link href={item.path}>
        { item.name }
      </Typography.Link>
    </List.Item>
  );
  return (
    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      
      <Row style={{ marginTop: '2rem' }}>
        <Col span={24}>
          <Typography.Title level={2} style={{ textAlign: 'center', marginBottom: '1rem' }}>
            Report Data Protection Violation
          </Typography.Title>
          <Typography>
            If you've come across any data protection violation or privacy concerns, please let us know. Your report will help us maintain a secure environment for our users.
          </Typography>
        </Col>
      </Row>

      <Row style={{ marginTop: '2rem' }}>
        <Col span={24}>

          <Spin spinning={loading}>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="name"
                label="Your Name (optional)"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="Your Email (optional)"
                rules={[
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label="Violation Description"
                rules={[{ required: true, message: 'Please describe the violation' }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>

              <Form.Item style={{ textAlign: 'center' }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit Report
                </Button>
                <Button onClick={onAnonymousSubmit} loading={loading} style={{ marginLeft: '1rem' }}>
                  Submit Anonymously
                </Button>
              </Form.Item>
            </Form>
          </Spin>

        </Col>
      </Row>

      <Row style={{ marginTop: '2rem' }}>
        <Col span={24}>
          <Typography.Title level={3}>
            Learn More about Data Protection:</Typography.Title>
          <List
            rowKey='key'
            dataSource={mockLinks}
            renderItem={renderLinks}
          />
        </Col>
      </Row>

    </div>
  );
};

export default ReportViolationForm;
