import { AllActions, ActionTypes } from '.';
import { ActionMap } from './types';

type SessionPayload = {
  [ActionTypes.Update_user]: {
    // isAuth?: boolean;
    name?: string | undefined;
    username?: string | undefined;
    email?: string | undefined;
    // expires?: number | undefined;
    roles?: App.SessionProps['roles'];
    groups?: App.SessionProps['groups'];
    faculties?: App.SessionProps['faculties'];
    permissions?: App.SessionProps['permissions'];
  };
  // [ActionTypes.Logout]: () => void;
};

export type SessionActions =
  ActionMap<SessionPayload>[keyof ActionMap<SessionPayload>];

export const sessionReducer = (state: App.SessionProps, action: AllActions) => {
  switch (action.type) {
    // case ActionTypes.Logout:
    //   return { isAuth: false };

    case ActionTypes.Update_user:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
