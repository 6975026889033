import {Button, Flex, Layout, Space, Spin, Typography} from 'antd';
import {LoadingOutlined, ReloadOutlined, WarningOutlined} from '@ant-design/icons';
import React from 'react';
import {UTLogo} from "@App/components/Logo";
import {useTranslation} from "react-i18next";


const pageStyles: React.CSSProperties = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

const wrapperStyles: React.CSSProperties  = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1rem 1rem 2.5rem'
};

const loadingIcon = <LoadingOutlined style={{fontSize: '1.6rem'}} spin />;
const warningIcon = <WarningOutlined style={{fontSize: '1.2em'}} />;

const doReload = () => (window || document).location.reload();

interface LoadingScreenProps {
  error?: string | {} | Error | null | undefined;
  onRetry?: () => void;
  isLoading?: boolean;
  currentStep?: string;
  currentStepKey?: string;
}

export const LoadingPage: React.FC<LoadingScreenProps> = ({
  error,
  onRetry,
  currentStep = 'Loading ...',
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Layout style={pageStyles}>
      <Layout.Content style={wrapperStyles}>

        <UTLogo />

        <Space
          direction={'vertical'}
          align={'center'}
          style={{
            gap: '.2rem',
            justifySelf: 'center',
            alignSelf: 'center'
          }}
        >
          <Typography.Title
            level={1}
            type={'secondary'}
            title={t('local:site.name')}
            about={t('local:site.name')}
            style={{  textAlign: 'center' }}
          >
            {t('local:site.shortName')}
          </Typography.Title>

          <Typography.Text
            strong
            type={error ? 'danger' : 'secondary'}
            style={{ fontSize: '1.2rem' }}
          >
            <Spin
              size={'small'}
              spinning={!error}
              indicator={loadingIcon}
            />
            {error && warningIcon}{' '}
            {t(`local:${rest.currentStepKey}`, currentStep)}
          </Typography.Text>

          {(error && onRetry) && (
              <Button.Group>
                {onRetry ? (
                    <Button type="primary" size="large" shape="round" onClick={onRetry}>
                      Retry
                    </Button>
                ) : (
                    <Button size="large" shape="round" icon={<ReloadOutlined/>} onClick={doReload}>
                      Reload
                    </Button>
                )}
              </Button.Group>
          )}

          {/*{error && (
              <Typography.Text type={'secondary'} ellipsis>
                <pre>{JSON.stringify(error)}</pre>
              </Typography.Text>
          )}*/}
        </Space>
      </Layout.Content>
    </Layout>
  )
};
