import { Breadcrumb, BreadcrumbProps, Typography } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import React from 'react';

import { capitalize } from '@App/components/helpers';
import { useTranslation } from 'react-i18next';

// BreadcrumbItem type
type BreadcrumbItem = {
  href?: string;
  title: React.ReactNode;
};

/**
 * Create an array of breadcrumb items from the given path.
 * @param path - The path from which to create breadcrumb items.
 * @returns An array of breadcrumb items that is accepted by ANT.Design tool.
 */
const createBreadcrumbItems = (path: string): BreadcrumbItem[] => {
  // Split the path into individual segments
  const pathSegments = path.split('/').filter((segment) => segment);
  const { t } = useTranslation();

  return [
    // The first breadcrumb item is always a link to the home page
    { title: <Link to='/'><HomeOutlined /></Link> },
    
    // Create a breadcrumb item for each segment of the path
    ...pathSegments.map((segment, index) => {
      // Determine if this is the last item in the path
      const isLastItem = index === pathSegments.length - 1;  

      // Generate the URL for this breadcrumb item
      const href = `/${pathSegments.slice(0, index + 1).join('/')}`;

      // Use translated labels
      const label = t(`pages.nav.${segment}`, capitalize(segment));

      return isLastItem
          // If this is the last item in the path, display it as bold text and do not include a link
        ? { title: <Typography.Text strong>{ label }</Typography.Text> }
          // If this is not the last item, include a link to the corresponding path
        : { title: <Link to={href}>{ label }</Link>  };
    }),
  ];
};

export const AppBreadcrumbs: React.FC<BreadcrumbProps> = (props) => {
  // Get the current location from the router
  const location = useLocation();

  // Generate the breadcrumb items from the current path
  const breadcrumbItems = createBreadcrumbItems(location.pathname);

  // Render the breadcrumb component with the generated items
  return <Breadcrumb items={breadcrumbItems} {...props} />;
};
