import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { useKeycloak } from '@App/settings/keycloak';
import { Avatar, Button, Divider, List, Skeleton, Space, Table, Tag, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';

import DeleteButton from '../../../components/buttons/DeleteButton';
import EditButton from '../../../components/buttons/EditButton';
import { handler } from '../../../settings/ApiHandler';
import { DataContext } from '../../AdminFormView';
import KC from "@App/@types/keycloakTypes";

// import { ApplicationState } from '../settings/StateManager';

interface FormType {
  id: number;
  key?: string;
  name: string;
  title: string;
  status: string;
  description?: string;
}

const onDelete = (record: FormType) => {
  console.log('Deleting record:', record);
  // Add delete logic here
};

const columns: ColumnsType<FormType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'key',
    width: 50,
  },
  {
    title: 'Name & Title',
    key: 'name',
    dataIndex: ['name', 'title'],
    render: (_, record) => {
      return (
        <>
          <Typography.Text type='secondary'>{record.name}</Typography.Text>
          <Typography.Title level={4} style={{ marginTop: 0 }}>
            {record.title}{' '}
          </Typography.Title>
        </>
      );
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    // ellipsis: true
  },
  {
    title: 'Questions',
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
    render: (_, record) => {
      return '0';
    },
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: 100,
    render: (status) => {
      const color = status === 'draft' ? 'cyan'
                  : status === 'active' ? 'green'
                  : status === 'deleted' ? 'red' : 'gold';
      return <Tag color={color}>{status!.toUpperCase()}</Tag>;
    },
  },
  {
    title: 'Action',
    key: 'action',
    width: 100,
    render: (_: string, record: FormType) => (
      <Space size='small'>
        <EditButton record={record} />
        <DeleteButton
          path='forms'
          onDelete={onDelete}
          record={record}
          item={'form'}        />
      </Space>
    ),
  },
];


const getForms = (kc: KC.KCType) =>
  handler<{ forms: FormType[] }>({
    path: '/v1/forms',
    method: 'GET',
  }, kc);

export function FormsList() {
  const [forms, setForms] = React.useState<FormType[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const {keycloak} = useKeycloak();

  const data = React.useContext(DataContext);
  // const { dispatch } = React.useContext(ApplicationState);
  const fetchForms = () => {
    setIsLoading(true);
    getForms(keycloak!)
      .then((data) => {
        // const currentTime = new Date().getTime();
        // setUpdateTime(currentTime);
        console.log(data);
        data.forms && setForms(data.forms);
        // message.open({content:'asd'});
      })
      .then((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false)
      });
  };

  const timeFormatter = (time: number) => {
    const timeObj = new Date(time);
    const title = 'Last updated: ' + timeObj.toLocaleString();
    return (
      <Typography.Text strong type='secondary' title={title}>
        {timeObj.toTimeString().split('(')[0]}
      </Typography.Text>
    );
  };
  return (
    <div>
      {/* <Typography.Title level={3}>Forms table</Typography.Title> */}

      <DividerTitle title='All forms' />

      <Space
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1.5rem',
        }}
      >
        <Typography.Paragraph>
          {data.updateTime ? timeFormatter(data.updateTime) : 'Demo table with mock data'}
        </Typography.Paragraph>

        <Button.Group>
          <Button onClick={data.onRefresh}>Refresh</Button>
          <Button title='Not implemented action, jet.'>Add</Button>
          <Button title='Not implemented action, jet.'>Actions</Button>
        </Button.Group>
      </Space>

      <Table
        rowKey='id'
        //@ts-ignore
        columns={columns}
        dataSource={data.forms}
      />
    </div>
  );
}
