import React from 'react';

interface PageWrapperProps {
  children: string | React.ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = (props) => (
  <div className='app-page-wrapper'>
    { props.children }
  </div>
);

export default PageWrapper;