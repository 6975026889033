import React from 'react';

import { LoadingPage } from '@App/views/LoadingPage';
import type KC from "@App/@types/keycloakTypes";
import createKeycloak from "./createKeycloakInstance";
import KeycloakContext from './index';

const logger = (...other: unknown[]) => {
  if (!import.meta.env.PROD || import.meta.env.MODE !== 'production') {
    console.info(...other);
  }
};

// Storage helpers
export const SESSION_STORAGE_KEY = 'keycloak_session';
/**
 *
 */
const storage = {
  // Helper function to get session data from localStorage. This is used to persist the session across browser refreshes.
  get: (): KC.SessionTokens => {
    const data = localStorage.getItem(SESSION_STORAGE_KEY);
    return data ? JSON.parse(data) : {
      token: undefined,
      idToken: undefined,
      refreshToken: undefined
    };
  },
  // Helper function to set session data in localStorage. This is used to persist the session across browser refreshes.
  set: (data: KC.SessionTokens) => {
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(data));
  },
  // Helper function to clear session data from localStorage. This is used when logging out or if there is an error.
  clear: () => {
    localStorage.removeItem(SESSION_STORAGE_KEY);
  }
};

const KeycloakContent: React.FC<KC.KeycloakContentProps> = ({ children, keycloak }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [authState, setAuthState] = React.useState<Partial<KC.State>>({
    status: 'initializing',
    keycloak: undefined,
  });

  // Separate event handlers setup
  const setupEventHandlers = React.useCallback(() => {
    keycloak.onAuthSuccess = () => {
      storage.set({
        token: keycloak.token,
        idToken: keycloak.idToken,
        refreshToken: keycloak.refreshToken
      });
    };

    keycloak.onAuthRefreshSuccess = keycloak.onAuthSuccess;

    keycloak.onAuthLogout = () => {
      storage.clear();
      setAuthState({
        status: 'unauthenticated',
        keycloak,
        authenticated: false
      });
    };

    keycloak.onTokenExpired = () => {
      keycloak.updateToken(30)
          // .then(() => console.info('token refreshed!'))
          .catch(() => keycloak.logout());
    };
  }, [keycloak]);

  // Simplified initialization
  const initializeKeycloak = React.useCallback(async () => {
    try {
      setupEventHandlers();

      const isAuth = await keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        // redirectUri: window.location.origin + '/callback',
        redirectUri: window.location.origin + window.location.pathname,
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      });

      if (isAuth) {
        const profile = await keycloak.loadUserProfile();
        setAuthState({
          status: 'authenticated',
          authenticated: true,
          keycloak,
          userData: profile
        });
      } else {
        setAuthState({
          status: 'unauthenticated',
          keycloak,
          authenticated: false
        });
      }
    } catch (error) {
      console.error('Keycloak initialization failed:', error);
      setAuthState({
        status: 'error',
        error: error instanceof Error ? error : new Error('Failed to initialize'),
        keycloak,
        authenticated: false
      });
    } finally {
      setLoading(false);
    }
  }, [keycloak, setupEventHandlers]);

  React.useEffect(() => {
    initializeKeycloak();

    return () => {
      // Clean single level of handlers
      keycloak.onAuthSuccess = undefined;
      keycloak.onAuthRefreshSuccess = undefined;
      keycloak.onAuthLogout = undefined;
      keycloak.onTokenExpired = undefined;
    };
  }, [initializeKeycloak]);

  // Do not render children until Keycloak is fully initialized or has no errors
  if (!keycloak.didInitialize || !keycloak.authenticated || !authState.keycloak || authState.error) {
    return <LoadingPage
        isLoading={loading}
        currentStepKey={authState.error ? 'site.authFailed' : authState.status}
        error={authState.error || {message: 'Auth service loading has failed'} as Error}
        // onRetry={() => window.location.reload()}
    />;
  }

  // The Keycloak.current instance and related data are provided to child components via context.
  return (
    <KeycloakContext.Provider
      value={{
        keycloak: authState.keycloak,
        authenticated: authState.authenticated || false,
        userData: authState.userData,
        error: authState.error,
        status: authState.status || 'initializing'
      }}
    >
      {children}
    </KeycloakContext.Provider>
  );
};

/**
 * KeycloakProvider is a React component that handles Keycloak initialization, authentication,
 * token refreshing, and user profile loading. It provides the Keycloak instance, authentication status,
 * user profile data, and a function to get a valid token through context to its child components.
 *
 * @param {object} props The properties passed to the component.
 * @param {ReactNode} props.children The child components of KeycloakProvider.
 *
 * @returns {JSX.Element} Returns a KeycloakSessionData.Provider that provides Keycloak-related data to child components.
 *
 * @usage
 * ```jsx
 * import KeycloakProvider from './KeycloakProvider';
 * import App from './App';
 *
 * function Main() {
 *   return (
 *     <KeycloakProvider>
 *       <App />
 *     </KeycloakProvider>
 *   );
 * }
 * ```
 *
 * In this example, the KeycloakProvider wraps around the main App component,
 * thus providing Keycloak-related functionalities to all child components inside App.
 */
const KeycloakProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const kc = React.useMemo(() => createKeycloak(), []);

  return (
    <>
    {/*<React.Suspense fallback={<LoadingPage currentStepKey={'site.initializing'} />}>*/}
      <KeycloakContent keycloak={kc}>
        {children}
      </KeycloakContent>
    {/*</React.Suspense>*/}
    </>
  );
};

export default KeycloakProvider;
