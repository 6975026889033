import React, { useMemo, useState } from 'react';

import { Button, Tooltip } from 'antd';
import { ApplicationState } from '../../settings/StateManager';
import { ActionTypes } from '../../settings/reducers';
import { useTranslation } from 'react-i18next';


export function SidebarCloseBtn() {
  const { state, dispatch } = React.useContext(ApplicationState);
  const [arrow, setArrow] = useState('Show');

  const {t} = useTranslation();

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }

    if (arrow === 'Show') {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const toggleSidebar = () => {
    dispatch({
      type: ActionTypes.Toggle_sidebar, payload: {},
    });
  };

  return (
    <Tooltip
      placement="right"
      title={
        <span>
          { state.isSidebarCollapsed
            ? t('settings.btn.openSidebar')
            : t('settings.btn.closeSidebar')
          }
        </span>
      }
      arrow={{
        pointAtCenter: true,
      }}
    >
      <button
        className={`sidebar-toggle-button ${state.isSidebarCollapsed ? 'closed' : 'open'}`}
        onClick={toggleSidebar}
      >
        <div className="arrow">
          <div className="arrow-part top"></div>
          <div className="arrow-part bottom"></div>
        </div>
      </button>
    </Tooltip>
  );
}
