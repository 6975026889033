import { SearchOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { Form, Divider, Row, Spin, Tag, Typography, Col, Select, App, Button, List, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { useLoaderData } from 'react-router-dom';
import React from 'react';

import { useNavigation } from '@App/settings/NavigationProvider';
import { ApplicationState, StateManager } from '@App/settings/StateManager';
import { DynamicFormModal } from '@App/components/forms/DynamicFormModal';
import { handler } from '@App/settings/ApiHandler';
import { hasAccess, permissions } from '@App/settings/navigation';
import { useKeycloak } from '@App/settings/keycloak';
import { PublicFormsData } from './FormsView';
import UserSearchModalPublic, {LdapUserInfo} from "@App/components/UserSearchModalPublic";
import KC from "@App/@types/keycloakTypes";
import { Language } from '@AppRoot';


type FormsTypes = {
  forms: Form.FormType[],
  granted?: Form.FormType[],
  ttr?: Form.FormType[],
}

type GetFormsRes = FormsTypes & {
  faculties?: Responses.FacultyType[],
  // users?: Responses.UserType[]
}
const getPublicFormsData = (kc: KC.KCType, uuid: string | undefined) => handler<GetFormsRes>({
  method: 'GET',
  path: `/v3/forms/${uuid ?? ''}`,
}, kc!)

type PostResponse = Responses.Default & {
  forms: Form.FormType[]
};

const postFormsData = (kc: KC.KCType, payload: API.PayloadType, path:string|boolean = false) => handler<PostResponse>({
  method: 'POST',
  path: `/v1/${path ?? 'fill/form/'}`,
  payload
}, kc!)

const postFormsValues = (kc: KC.KCType, payload: API.PayloadType, slug: string | Form.FormType['slug']) => {
  let newPath: string = 'fill/form/';
  if (['researchProject', 'research_project'].includes(slug)) {
    newPath = 'researchProjects/'
  } else if (['oppetoo', 'ttr'].includes(slug)) {
    newPath = 'oppetoo/'
  }
  console.info('* SEND TO PATH:', newPath, slug)
  return handler<PostResponse>({
    method: 'POST',
    path: `/v1/${newPath}`,
    payload
  }, kc!)
}

export const SingleFormPage: React.FC = () => {
  const { state } = React.useContext<StateManager>(ApplicationState);
  const { isLoading, setLoading } = React.useContext(PublicFormsData);
  const [showLdapUserSearch, setShowLdapUserSearch] = React.useState<boolean>(false);
  const [sharedWithUsers, setSharedWithUsers] = React.useState<LdapUserInfo[]>([]);

  const {message} = App.useApp();
  const { t, i18n } = useTranslation('forms');
  const {keycloak} = useKeycloak();
  const loaderData = useLoaderData() as API.GetForm;
  const { handleNavigate: nav } = useNavigation();

  const handlePost = (formPayload: object) => {  
    const payload = {
      action: 'new' as API.ActionType,
      payload: {
        form: {
          uuid: loaderData?.form.uuid,
          slug: loaderData?.form?.slug,
          name: loaderData?.form?.name,
        },
        values: formPayload,
        sharedWith: sharedWithUsers
      }
    };

    // postFormsData(keycloak!, payload, `${loaderData?.form?.slug}/`)
    postFormsValues(keycloak!, payload, loaderData?.form?.slug)
    .then((res) => {
      if (res.success) {
        message.success(t('forms.success.'+res.success, res.success))
        nav('/forms')
      }

      if (res.error) {
        message.error(t('forms.'+res.error, res.error))
        // throw { type: 'maintenance', message: 'The system is currently under maintenance.' };
      }

    })
    .catch((res) => {
      console.log(res)
      if (res.error) {
        console.log(res.error)
        // nav('/forms')
      }
      else {
        message.info('Palun proovi uuesti')
      }
    })
    .finally(() => {
      setLoading(false);
    });
  };

  type OptItem = DefaultOptionType & {
    search: string
  };

  const facultyOptionItems = React.useMemo(() => {
    const nonCategoryOptions = loaderData?.faculties?.filter((a) => a.category !== true) ?? [];
    return nonCategoryOptions.map(
      ({key, id, ...faculty}, optionIdx: number) => ({
        key: key ?? `${optionIdx + 1}-${faculty.code}`,
        value: faculty.code,
        search: `${faculty.code} ${faculty.labels.toString()}`,
        // label: faculty.labels[i18n.language == 'et' ? 'et' : 'en'],
        label: (
          <>
            <Tag color={'processing'}>
              <b>{faculty.code}</b>
            </Tag>
            <Typography.Text>
              {faculty.labels[i18n.language == 'et' ? 'et' : 'en']}
            </Typography.Text>
          </>
        )
      })
  );
  }, [loaderData.faculties, i18n.language]);

  const filterProjects = (input: string, option?: OptItem) => {
    return option?.search?.toLowerCase().includes(input.toLowerCase()) ||
      (option?.label + '').toLowerCase().includes(input.toLowerCase()) ||
      (option?.value + '').toLowerCase().includes(input.toLowerCase())
    ;
  };

  const handleProjectSelectChange = (value: string[], option: OptItem | OptItem[]) => {
    // Updating the state to keep only the latest value
    const newValue = value.length > 0 ? [value[value.length - 1]] : [];
    // form?.setFieldValue('projectName', newValue);
  };

  const projectsOptionItems: OptItem[] = React.useMemo(() => {
    return loaderData?.projects?.map(
      ({slug, name}) => ({
        value: slug,
        label: t(slug, name),
        search: `${slug} ${name}`
      })
    ) ?? []
  }, [loaderData?.projects, t]);

  const handleSharedUserSelection = (user: LdapUserInfo) => {
    const userExists = sharedWithUsers.find(u => u.username === user.username);
    console.log(user, userExists)
    if (userExists) {
      message.info('Kasutaja juba valitud');
    } else {
      setSharedWithUsers((prev) => [...prev, user])
    }
  };
  const handleSharedUserRemove = (user: LdapUserInfo) => {
    setSharedWithUsers((prev) => {
      return prev.filter(u => u.username !== user.username);
    })
  };

  return (
    <Spin spinning={isLoading}>

      <Row>
        <Col
          sm={{offset: 0, span: 24}}
          md={{offset: 2, span: 20}}
          lg={{offset: 3, span: 18}}
        >
          <Typography.Title level={3}>
            {loaderData.form?.settings?.labelTexts?.[i18n.language as Language] ?? loaderData.form?.title}
          </Typography.Title>
          <Typography.Paragraph type='secondary'>
            {loaderData.form?.settings?.descriptionTexts?.[i18n.language as Language] ?? loaderData.form?.description}
          </Typography.Paragraph>
          {/*           
          <Typography.Text type='secondary' style={{fontSize:'.8em'}}>
            {t('table.column.lastUpdated')} {formatDateTime(formData?.updated ?? null)}
          </Typography.Text>
          */}
        </Col>
      </Row>
      
      <Divider />

      <Row>
        <Col
          sm={{offset: 0, span: 24}}
          md={{offset: 2, span: 20}}
          lg={{offset: 3, span: 18}}
        >
          <DynamicFormModal
            formData={loaderData.form}
            onFinish={handlePost}
            facultyOptions={facultyOptionItems}
            //@ts-ignore
            projectOptions={projectsOptionItems}
            isAdmin={hasAccess(permissions.admin, state.session.permissions)}
            action='new'
            showResult={false}
            extra={['research_project', 'research_project', 'researchProject'].includes(loaderData?.form?.type) && (
              <>
                <Button
                    shape={'round'}
                    type={'default'}
                    icon={<SearchOutlined />}
                    onClick={() => setShowLdapUserSearch(true)}
                    style={{ margin: '0 1rem 1rem' }}
                >
                  {t('forms.btn.searchUtUser', 'UT kasutaja otsing')}
                </Button>
                { sharedWithUsers.length > 0 ? (
                  <List<LdapUserInfo>
                    itemLayout="horizontal"
                    size={'small'}
                    dataSource={sharedWithUsers}
                    renderItem={(user, i) => {
                      // const isExistingUser = userExists(user.username);
                      const isExistingUser = false;
                      return (
                          <List.Item
                              extra={
                                <Button
                                    shape={'round'}
                                    size={'small'}
                                    type={'dashed' }
                                    icon={<UserDeleteOutlined />}
                                    className={'user-action-button'}
                                    onClick={() => handleSharedUserRemove(user)}
                                >
                                  {t(`forms.btn.remove`)}
                                </Button>
                              }
                          >
                            <List.Item.Meta
                              title={
                                <Space>
                                  {`${user.firstName} ${user.lastName}`}
                                  <Tag color="processing" bordered={false}>{user.email}</Tag>
                                </Space>
                              }
                            />
                          </List.Item>
                      );
                    }}
                  />
                ) : null}
              </>
            )}
          >
            { loaderData?.form?.type == 'artikkel_30' && (
              <Form.Item
                name={['faculty_code']}
                label={t('forms.label.forFaculty')}
                rules={[{ required: true }]}
                wrapperCol={{ offset: 1 }}
                initialValue={(facultyOptionItems?.length == 1 && facultyOptionItems?.[0]?.value) ?? undefined}
              >
                <Select
                  key='code'
                  allowClear
                  showSearch
                  placeholder={t('forms.placeholders.selectFacultyParent')}
                  options={facultyOptionItems}
                  // filterSort={(a, b) => a?.code?.localeCompare(b?.code)}
                  filterOption={(input: string, option?: OptItem) => {
                    return option?.search?.toLowerCase().includes(input.toLowerCase()) ||
                      option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
                      option?.code?.toLowerCase().includes(input.toLowerCase()) ||
                      false;
                  }}
                />
              </Form.Item>
            )}

          </DynamicFormModal>
        </Col>
      </Row>

      <UserSearchModalPublic
          visible={showLdapUserSearch}
          onClose={() => setShowLdapUserSearch(false)}
          onSelectUser={handleSharedUserSelection}
          existingUsers={sharedWithUsers}
      />

    </Spin>
  );
};
