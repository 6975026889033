import { AllActions, ActionTypes } from './';
import { ActionMap } from './types';


type TablePayload = {
  // [ActionTypes.Update_table]: {
  //   density: boolean;
  // };
  [ActionTypes.Update_table]: {
    tableName: string;
    [key: string]: any;
  }
  [ActionTypes.Update_table_value]: {
    tableName: string;
    data: App.TableMeta;
  }
  [ActionTypes.SetHiddenCols]: {
    tableName: string;
    newHiddenColumns: string[] | App.TableMeta['hiddenColumns'];
  }
  [ActionTypes.Load_table]: {
    tableName: string;
  }
};

export type TableActions =
  ActionMap<TablePayload>[keyof ActionMap<TablePayload>];


// export type TableActionsOri =
//   | { type: ActionTypes.Update_table; payload: { tableName: string; data: App.TableMeta } }
//   | { type: ActionTypes.Load_table; payload: { tableName: string } };

export const tableReducer = (state: App.TableConfigs, action: AllActions): App.TableConfigs => {
  switch (action.type) {
    case ActionTypes.Update_table:
      let updatedTables = state;
      if ('data' in action.payload) {
        updatedTables[action.payload.tableName] = action.payload.data;
      } else {
        const {tableName, ...newValues} = action.payload;
        updatedTables[tableName] = {...newValues} as App.TableMeta
      }
      if (typeof window !== 'undefined') {
        localStorage.setItem('tables', JSON.stringify(updatedTables));
      }
      return updatedTables;

    case ActionTypes.Update_table_value:
      const updatedState = {
        ...state,
        [action.payload.tableName]: action.payload.data
      };
      if (typeof window !== 'undefined') {
        localStorage.setItem('tables', JSON.stringify(updatedState));
      }
      return updatedState;

    case ActionTypes.Load_table:
      return {
        ...state,
        [action.payload.tableName]: state[action.payload.tableName] || { pageSize: 50, hiddenColumns: ['status'], compactMode: true, size: 'middle' }
      };

    case ActionTypes.SetHiddenCols:
      return {
        ...state,
        [action.payload.tableName]: {
          ...state[action.payload.tableName],
          hiddenColumns: action.payload.newHiddenColumns
        }
      };
  
    default:
      return state;
  }
};