import {
  Button, Col, Form, Input, Select, Space, Switch, Typography, message, Row, Flex,
} from 'antd';
import { useLoaderData, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { DividerTitle } from '@App/components/texts/DividerTitle';
import { handler } from '@App/settings/ApiHandler';
import { UserForm } from '@App/components/forms/UserForm';
import { useKeycloak } from '@App/settings/keycloak';
import { FacultyForm } from '@App/components/forms/FacultyForm';
import KC from "@App/@types/keycloakTypes";
import { useNavigation } from '@App/settings/NavigationProvider';


const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

type FacultyResponse = Responses.Default & {
  item?: API.FacultyItem;
};

const postFaculty = (kc: KC.KCType, payload: API.PayloadType, code?: string) => handler<FacultyResponse>({
  method: code ? 'PUT' : 'POST',
  path: '/v1/manage/faculty/' + (code ?? ''),
  payload,
}, kc)

export const FacultyFormPage: React.FC = () => {
  const [action, setAction] = React.useState<'new' | 'update'>('new');
  const facultyValues = React.useRef<API.FacultyItem>();
  const [ form ] = Form.useForm();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { code } = useParams();
  const loaderData = useLoaderData() as FacultyResponse;
  const { handleNavigate: nav } = useNavigation();

  const handleFacultyData = (values: object) => {
    const payload = {
      action,
      newValues: values,
    };

    postFaculty(keycloak!, payload, code)
    .then((data) => {
      console.log(data)
      if (data.success) {
        message.success(t([
          `forms.success.${data.success}`,
          `forms.success.default${action == 'update' ? '.update' : ''}`
        ], data.message ?? '' ))
        nav('/manage/access/faculties/')
      }
      else if (data.error) {
        message.error(t([
          `forms.error.${data.error}`,
          `forms.error.default${action == 'update' ? '.update' : ''}`
        ], data.message ?? '' ))
        
      }
      // if ('error' in data && typeof(data)== 'string' ) {
      //   message.error(data['error'])
      // }
    });
  };

  const onFinish = (values: any) => {
    handleFacultyData(values)
  };

  // Assuming 'loaderData' has the user data structure needed for the form
  React.useEffect(() => {
    if (loaderData && 'item' in loaderData) {
      facultyValues.current = loaderData.item;
      form.setFieldsValue(loaderData.item);
      setAction('update')
    }
  }, [loaderData, form]);

  return (
    <div style={{ padding: '1rem 0'}}>
      {/* <AsyncModal title={action === 'new' ? 'Add new user' : 'Update user'} /> */}
      <DividerTitle
        // title={action === 'new' ? 'Add new user' : 'Update user'}
        title={t(`forms.title.${action}Faculty`) }
        orientation="center"
        style={{ margin: '1.6rem 1rem'}}
      />
      <Row justify={'center'}>
        <Col lg={16} md={{span: 20}} xs={{span: 24}}>

          <FacultyForm
            {...layout}
            form={form}
            name={action === 'new' ? 'Add new user' : 'Update user'}
            layout='horizontal'
            action={action}
            onFinish={handleFacultyData}
            initialValues={facultyValues.current}
          >
            <Form.Item noStyle>
              <Flex gap='1rem' justify='space-between' style={{ marginTop: '1rem' }}>
                <Space direction='vertical'>
                  <Button
                    htmlType='reset'
                    shape='round'
                    onClick={() => form.resetFields()}
                  >
                    {t(`forms.btn.reset`)}
                  </Button>
                  <Button
                    shape='round'
                    onClick={() => nav(-1) }
                  >
                    {t('buttons.back')}
                  </Button>
                </Space>
                <Button
                  type='primary'
                  shape='round'
                  htmlType='submit'
                >
                  {t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
                </Button>
              </Flex>
            </Form.Item>
          </FacultyForm>

        </Col>
      </Row>

    </div>
  );
};
