import {
  Form, Input, Checkbox, Radio, Select, FormProps, DatePicker,
  Tooltip, Typography, Button, Space, Divider, Grid, Row, Col, Tag, FormInstance,
} from 'antd';
import { QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { DefaultOptionType } from 'antd/es/select';

import { useNavigation } from '@App/settings/NavigationProvider';
import React, { startTransition } from 'react';

import DeleteButton from '../buttons/DeleteButton';
import { Language } from '@AppRoot';
import dayjs from 'dayjs';

const tooltipStyles: React.CSSProperties = {
  position: 'absolute',
  right: '11px',
  top: '10px',
  zIndex: 1000,
};

const isNotProd = window.location.hostname !== 'ak.ut.ee';

type StylesProps = React.CSSProperties | boolean;

const infoTooltip = (tooltipMessage?: string, fixedStyles: StylesProps = false) => tooltipMessage ? (
  <Tooltip title={tooltipMessage}>
    <QuestionCircleOutlined
      className="textarea-suffix-icon"
      style={fixedStyles ? tooltipStyles : {}}
    />
  </Tooltip>
) : null;

const defaultYesNo = [
  { value: 'ei', label: 'Ei' },
  { value: 'jah', label: 'Jah' },
];

type ObjectSelectValue = { [key: string]: string };

type FieldProps = API.Field & {
  form: FormInstance;
  faculties?: any[];
  projects?: any[];
  // field: API.Field;
  extra?: React.ReactNode
}

const FieldInput: React.FC<FieldProps> = ({
  type, form, id, name, label, settings, required, order, ...props
}) => {
  const [customSelectValue, setCustomSelectValue] = React.useState<string>('');
  const [selectValue, setSelectValue] = React.useState<string>('');
  const [customValue, setCustomValue] = React.useState<string>('');
  const [customYesNo, setCustomYesNo] = React.useState<'jah' | 'ei' | undefined>();
  const [customRadio, setCustomRadio] = React.useState<string | undefined>();
  // const [customNoOrText, setNoOrText] = React.useState<string|undefined>();
  const { t, i18n } = useTranslation();

  const fieldProps = {
    ...(
      settings.placeholderTexts || settings.placeholderText
        ? {
          ...props,
          placeholder: settings.placeholderTexts?.[i18n.language as Language] ?? settings.placeholderText
        }
        : props
    )
  }

  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);

  const handleNoOrTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (customYesNo == 'ei') {
      form.setFieldValue(name, 'ei')
    } else {
      form.setFieldValue(name, event.target.value)
    }
  }
  const handleMultiSelectChange = (selected: string[]) => {
    setSelectedValues(selected);
  };

  const handleSelectChange = (value: string) => {
    setSelectValue(value)
  };

  const handleCustomSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCustomSelectValue(event.target.value);
  };

  const translatedOptions = settings.options?.map((i: AppFields.OptionType) => {
    return {
      value: i.value,
      label: i.label[i18n.language as Language] ?? i.label,
    } as { value: string, label: string }
  }) ?? undefined;


  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (settings.options) {
      settings.options.push({
        label: {
          en: customSelectValue || `New item`,
          et: customSelectValue || `Uus element`
        },
        value: customSelectValue || `New item`
      });
    }
    setCustomSelectValue('');
  };

  const includesCustomOption = settings.options?.some(
    (opt: any) => opt.value === 'other'
  );

  const handleFilterOptions = (input: string, option?: DefaultOptionType) => {
    return option?.search?.toLowerCase().includes(input.toLowerCase()) ||
      option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ||
      option?.code?.toLowerCase().includes(input.toLowerCase()) ||
      false;
  };

  React.useEffect(() => {
    if (customYesNo == 'ei') {
      form.setFieldValue(name, 'ei')
    }
  }, [customYesNo]);

  switch (type) {
    case 'text':
    case 'text-multi':
    case 'multi-text':
      return (
        <Input
          multiple={type.includes('multi')}
          // suffix={infoTooltip(settings.helper_text)}
          {...fieldProps}
        />
      );

    case 'textarea':
      return (
        <>
          {/* {renderTooltip(settings.helper_text)} */}
          <Input.TextArea
            rows={2}
            autoSize
            allowClear
            style={{ minHeight: 60 }}
            {...fieldProps}
          />
          {/* {
            settings.helper_text && infoTooltip(
              settings.helper_text,
              tooltipStyles
            )
          } */}
        </>
      );

    case 'checkbox': //@ts-ignore
      return <Checkbox {...fieldProps} options={translatedOptions} />;

    case 'radio':
      return (
        <Radio.Group {...fieldProps} options={translatedOptions} />
      );

    case 'radio-text':
      return (
        <Space direction='vertical'>
          <Radio.Group
            // value={customRadio}
            // onChange={e => {
            //   setCustomRadio(e.target.value);
            // }}
            options={translatedOptions ?? defaultYesNo}
          />
          {/* { customRadio ? <Input.TextArea {...fieldProps} /> : null} */}
          {/*{ <Input.TextArea {...fieldProps} />}*/}
        </Space>
      );

    case 'no-or-text':
      return (
        <Space direction='vertical' style={{ width: '100%' }}>
          <Radio.Group
            // {...fieldProps}
            value={customYesNo}
            onChange={e => {
              setCustomYesNo(e.target.value);
              if (e.target.value == 'ei') {
                form.setFieldValue(name, 'ei')
              } else {
                form.setFieldValue(name, '')
              }
            }}
            options={defaultYesNo}
          />
          <Input
            {...fieldProps}
            onChange={handleNoOrTextChange}
            hidden={customYesNo !== 'jah'}
          />
        </Space>
      );

    case 'multi-select-text':
      return (
        <>
          <Select
            // allowClear
            mode="multiple"
            value={selectedValues}
            onChange={handleMultiSelectChange}
            {...fieldProps}
            style={{ marginBottom: '1.5rem' }}
          // suffixIcon={settings.helper_text && (
          //   <Tooltip title={settings.helper_text}>
          //     <QuestionCircleOutlined className="textarea-suffix-icon" style={{ color: 'rgba(0,0,0,.45)' }}/>
          //   </Tooltip>
          // )}
          >
            {settings.options?.map((opt: any) => (
              <Select.Option key={opt.value} value={opt.value}>
                {opt.label}
              </Select.Option>
            ))}
          </Select>
          {/* {infoTooltip(settings.helper_text)} */}

          {settings.options?.map((value, index) => (
            <Form.Item
              key={`${value.label}-${index}`}
              label={`${value.label}`}
              labelAlign='right'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              name={[`${name}_${value.value.replaceAll(' ', '_')}`]}
              //@ts-ignore
              required={props.value?.includes(value.value)}
              //@ts-ignore
              hidden={!props.value?.includes(value.value)}
            >
              <Input
                placeholder={`Kirjelda ${value.value}`}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCustomValue(e.target.value)
                }}
                value={customValue}
              />
            </Form.Item>
          ))}
        </>
      );

    case 'select':
    case 'multiselect':
    case 'multi-select':
      return (
        <Select
          allowClear
          mode={type.includes('multi') ? "multiple" : undefined}
          onChange={handleSelectChange}
          value={selectValue}
          // suffixIcon={infoTooltip(settings.helper_text)}
          {...fieldProps}
          // onInputKeyDown={console.log}
          dropdownRender={includesCustomOption ? (menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space style={{ padding: '0 8px 4px' }}>
                <Input
                  placeholder={t("forms.placeholders.customOption")}
                  value={customSelectValue}
                  onChange={handleCustomSelectChange}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addItem}
                  disabled={customSelectValue.trim() === ''}
                >
                  {t('forms.label.customOption')}
                </Button>
              </Space>
            </>
          ) : undefined}
        >
          {
            settings.options
            && settings.options?.length > 0
            && settings.options?.map((opt: Form.OptionType, idx: number) =>
              (opt.value !== 'other') && (
                <Select.Option key={opt.value ?? idx} value={opt.value}>
                  {opt.label[i18n.language as Language] ?? opt.value}
                </Select.Option>
              )
            )
          }
        </Select>
      );

    case 'date':
    case 'date-time':
      return (
        <DatePicker
          {...props}
          showTime={type.includes('time')}
          defaultValue={null}
          format={type.includes('time') ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"}
        />
      );

    case 'date-range':
    case 'date-range-time':
      const handleCalendarChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null], dateStrings: [string, string]) => {
        // Check if any date is null
        if (!dates[0] || !dates[1]) {
          // If any date is null, set the Form.Item value to undefined or null
          form.setFieldsValue({ [name]: undefined });
          return;
        }

        // Your custom validation logic here
        // For example, you can check if the start date is after the end date
        if (dates[0].isAfter(dates[1])) {
          form.setFieldsValue({ [name]: undefined });
          return;
        }

        // If both dates are valid, update the Form.Item value
        form.setFieldsValue({ [name]: dates });
      };

      return (
        <DatePicker.RangePicker
          allowEmpty
          format={type.includes('time') ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"}
          defaultValue={[null, null]}
          onCalendarChange={handleCalendarChange}
          {...props}
        />
      );
    // nt Enlight, Horisont
    case 'project-name':
      return (
        <Select
          mode='tags'
          maxCount={1}
          allowClear
          placeholder={settings.placeholderTexts?.[i18n.language as Language] ?? settings.placeholderText}
          options={props.projects ?? []}
          filterOption={handleFilterOptions}
          {...props}
        />
      )

    case 'faculty-select':
      const handle = (name: string) => { }
      return (
        <Select
          // key='code'
          allowClear
          showSearch
          placeholder={t('forms.placeholders.selectFacultyParent')}
          options={props.faculties ?? []}
          // filterSort={(a, b) => a?.code?.localeCompare(b?.code)}
          filterOption={handleFilterOptions}
          {...props}
        />
      )

    default:
      return null;
  }
};

interface DynamicFormProps {
  action: API.ActionType;
  formData: Form.FormType | API.Form;
  // options?: Array<Responses.FacultyType | API.FacultyItem> | undefined;
  isAdmin?: boolean;
  setFormStatus?: boolean;
  initialValues?: object;
  onUpdate?: (allValues: any) => void;
  onFinish?: (allValues: any) => void;
  onDelete?: (allValues: any) => void,
  onCancel?: () => void;
  doReset?: boolean;
  facultyOptions?: Array<API.FacultyItem | API.FacultyGroupType> | Responses.FacultyType[] | any[]
  projectOptions?: Array<API.ProjectNames>;
  children?: React.ReactNode;
  showResult?: boolean;
  extra?: React.ReactNode;
}

export const DynamicFormModal: React.FC<DynamicFormProps & FormProps> = ({
  formData,
  action,
  initialValues,
  onCancel,
  onUpdate,
  onFinish,
  facultyOptions,
  projectOptions,
  showResult,
  isAdmin = false,
  doReset = false,
  ...rest
}) => {
  const [form] = Form.useForm();
  const { sm } = Grid.useBreakpoint();
  const { t, i18n } = useTranslation();

  const { handleNavigate: nav } = useNavigation();



  const [dependenciesState, setDependenciesState] = React.useState({});

  // Handle field change event
  const handleFieldChange = (changedFields: AppFields.FieldType[] | any[], allFields: AppFields.FieldType[] | any[]) => {
    // Update dependencies state
    const updatedDependenciesState = { ...dependenciesState };
    changedFields.forEach(({ name, value }) => {
      if (formData.fields.find((field) => field.name === name[0])?.settings.dependencies?.dependsOn) {
        // @ts-ignore
        updatedDependenciesState[name[0]] = value;
      }
    });
    setDependenciesState(updatedDependenciesState);
    // console.log(dependenciesState)
  };
  let conditionallyRenderedFields: string[] = [];

  formData?.fields!.map((f: AppFields.FieldType) => {
    if (f.settings.dependencies !== undefined) {
      const hasDepends = f.settings.dependencies;
      if (hasDepends && typeof hasDepends.dependsOn == 'string') {
        conditionallyRenderedFields.push(hasDepends.dependsOn)
      }
      if (hasDepends && Array.isArray(hasDepends.dependsOn)) {
        conditionallyRenderedFields = [
          ...conditionallyRenderedFields,
          ...hasDepends.dependsOn
        ]
      }
    }
  });

  const onValuesChange = (changedValues: object, allValues: object) => {
    // setFormValues(allValues);
    onUpdate && onUpdate(allValues);
  };

  const onFill = () => {
    formData?.fields.map((fieldKey) => {
      if (['text', 'textarea', 'multi-text', 'text-multi'].includes(fieldKey.type)) {
        form.setFieldValue(fieldKey.name, 'Auto-filled')
      }
      else if (['select', 'multi-select'].includes(fieldKey.type)) {
        const options = fieldKey.settings?.options;
        if (options && options.length > 0) {
          form.setFieldValue(fieldKey.name, options[0]);
        }
      }
      else if (['radio'].includes(fieldKey.type)) {
        const options = fieldKey.settings?.options;
        if (options && options.length > 0) {
          form.setFieldValue(fieldKey.name, options[0].value);
        }
      }
      else if (['radio-text'].includes(fieldKey.type)) {
        const options = fieldKey.settings?.options;
        console.log(fieldKey.type, options, fieldKey.name)
        if (options && options.length > 0) {
          form.setFieldValue(fieldKey.name, options[0].value);
        }
      }
      else if (['no-or-text'].includes(fieldKey.type)) {
        const options = fieldKey.settings?.options;
        console.log(fieldKey)
        if (options && options.length > 0) {
          form.setFieldValue(fieldKey.name, 'ei');
        }
      }
      else if (fieldKey.settings.options?.length) {
      }
    })
  };

  const handleFinish = (values: { [k: string]: string[] | string | number }) => {
    let stringArrayValues: { [k: string]: string[] | string | number } = values;
    // // console.log('values:', values)
    // Object.keys(values).forEach(key => {
    //   stringArrayValues[key] = Array.isArray(values[key]) 
    //     ? values[key].map(String) 
    //     : [String(values[key])];
    // });

    // Now stringArrayValues contains all values as arrays of strings
    // console.log('stringArrayValues:', stringArrayValues);
    // console.log('new values', values)

    if (['project', 'research_project', 'oldResearch_project'].includes(formData?.name ?? '')) {
      stringArrayValues.projectName = form.getFieldValue('projectName')?.[0]
    }

    onFinish && onFinish(stringArrayValues);
  };

  React.useEffect(() => {
    form.resetFields();
  }, [formData, doReset]);

  React.useEffect(() => {
    // Check if initialValues is not undefined
    if (initialValues !== undefined) {
      form.setFieldsValue(initialValues.form_data);
    }
  }, [initialValues]);


  // Render fields dynamically based on data
  const renderFields = () => {
    return formData.fields
      .sort((a, b) => ((a.order ?? 99) > (b.order ?? 99)) ? 1 : -1)
      .map((field, idx) => {
        let extraStyles = {};
        let isRequired = true;
        let fieldProps: { shouldUpdate?: any } = {};

        const { dependencies } = field.settings;
        // Check if field has dependencies
        if (dependencies) {
          const dependentValues = dependencies.dependsOn.map(depField =>
            form.getFieldValue(depField)
          );
          // Check if any dependency field has required value
          const shouldRender = dependentValues.every((val, index) =>
            dependencies.values.length == 0
              ? val?.length > 2
              : dependencies.values.includes(val)
          );

          fieldProps.shouldUpdate = () => {
            return shouldRender;
          }
          // Render field only if dependencies are met
          if (!shouldRender) {
            extraStyles = { display: shouldRender ? 'block' : 'none' };
            isRequired = false
            return null;
          }
        }

        const { settings } = field;
        const labelText = settings.labelTexts?.[i18n.language as Language] ?? field.label;
        const helpText = settings.helpTexts?.[i18n.language as Language] ?? settings.helpText;
        const tooltip = settings.tooltipTexts?.[i18n.language as Language] ?? settings.tooltipText;
        const errorText = settings.errorTexts?.[i18n.language as Language] ?? settings.errorText;
        return (
          <Form.Item
            key={field.name + '_' + idx}
            {...fieldProps}
            colon={false}
            name={field.name}
            label={labelText}
            help={helpText}
            tooltip={tooltip}
            valuePropName={field.type === 'checkbox' ? 'checked' : undefined}
            labelAlign='left'
            labelCol={{
              span: dependencies ? 22 : 24,
              offset: dependencies ? 2 : 0,
            }}
            wrapperCol={{
              span: dependencies ? 22 : 23,
              offset: dependencies ? 2 : 1,
            }}
            rules={[
              {
                required: isRequired && Boolean(field.required ?? field.settings.required),
                message: errorText ?? t('forms.error.requiredField', { fieldName: field.label }),
              },
            ]}
            style={{ marginBottom: 20, paddingBottom: 20, ...extraStyles }}
          >
            <FieldInput
              key={field.id}
              form={form}
              faculties={facultyOptions}
              projects={projectOptions}
              {...field}
            />
          </Form.Item>
        )
      }
      );
  };

  return (
    <Form
      layout="horizontal"
      wrapperCol={{ span: 23 }}
      labelCol={{ span: 24 }}
      labelWrap
      onValuesChange={onValuesChange}
      onFinish={handleFinish}
      {...rest}
      form={form}
      onFieldsChange={handleFieldChange}
    >
      {rest.children}

      {renderFields()}

      {rest.extra}

      <Form.Item wrapperCol={{ span: 24, offset: 0 }}>
        <Divider style={{ marginTop: 0 }} />
        <Row justify='space-between' gutter={[20, 20]} style={{ padding: '0 1rem 1rem' }}>
          <Col order={1} xs={24} sm={12} md={8}>
            <Space direction='vertical' style={{ width: '100%' }}>
              {onCancel ? (
                <Button
                  block
                  shape='round'
                  htmlType="button"
                  onClick={onCancel}
                >
                  {t('forms.btn.cancel')}
                </Button>
              ) : (
                <Button
                  block
                  shape='round'
                  htmlType="button"
                  onClick={() => nav(-1)}
                >
                  {t('buttons.back')}
                </Button>
              )}
              <Button
                block
                type="text"
                shape='round'
                htmlType="reset"
                onClick={() => form.resetFields()}
              >
                {t('forms.btn.reset')}
              </Button>
              {isNotProd && (
                <Button
                  block
                  type="link"
                  htmlType="button"
                  onClick={onFill}
                >
                  {t('forms.btn.fillForm', 'Fill form')}
                </Button>
              )}
            </Space>
          </Col>
          {
            action == 'update' && (
              <Col order={5} xs={24} sm={12} md={8}>
                <DeleteButton
                  block
                  size='large'
                  type="dashed"
                  shape='round'
                  path={`/v1/${['ttr', 'oppetoo'].includes(formData?.name ?? '') ? 'ttr' : 'researchProject'}/${initialValues?.uuid}/delete`}
                  item={['ttr', 'oppetoo'].includes(formData?.name ?? '') ? 'art30' : 'researchProject'}
                  record={initialValues}
                  onDelete={rest.onDelete}
                />
              </Col>
            )
          }
          <Col order={sm ? 10 : 0} xs={24} sm={12} md={8}>
            <Button
              block
              shape='round'
              size='large'
              type="primary"
              htmlType="submit"
            >
              {t(`forms.btn.${action == 'new' ? 'submit' : 'save'}`)}
            </Button>
          </Col>
        </Row>
      </Form.Item>
      {
        showResult && (
          <Form.Item noStyle shouldUpdate>
            {() => (
              <Typography>
                <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
              </Typography>
            )}
          </Form.Item>
        )
      }
    </Form>
  );
}

export default DynamicFormModal;
