import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';

import { useKeycloak } from '@App/settings/keycloak';


type Props = {
  roles: string[];
  children?: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[];
};

export const Protected: React.FC<Props> = ({roles, ...rest}) => {
  const { authenticated, keycloak } = useKeycloak();

  const isAuthorized = React.useMemo(() => {
    if (authenticated && roles) {
      return roles.some((role) => {
        const realm = keycloak?.hasRealmRole(role);
        const resource = keycloak?.hasResourceRole(role);
        const ldapGroups = keycloak?.tokenParsed?.['groups'] as string[] | undefined ?? [];
        const hasPermission = ldapGroups.includes(role);
        
        return realm || resource || hasPermission;
      });
    }

    return false;
  }, [keycloak]);

  return isAuthorized ? <Outlet /> : <Navigate to={"/unauthorized"} replace />;
};
