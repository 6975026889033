import React, { createContext, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { startTransition } from 'react';

// Define the context type
interface NavigationContextType {
  handleNavigate: (pathOrSteps: string | number) => void;
}

// Create the context
const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

// Create a provider
export const NavigationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const handleNavigate = (pathOrSteps: string | number) => {
    startTransition(() => {
      if (typeof pathOrSteps === 'string') {
        // Absolute or relative path
        navigate(pathOrSteps);
      } else if (typeof pathOrSteps === 'number') {
        // Relative navigation (e.g., -1 for back, 1 for forward)
        navigate(pathOrSteps);
      }
    });
  };

  return (
    <NavigationContext.Provider value={{ handleNavigate }}>
      {children}
    </NavigationContext.Provider>
  );
};

// Create a custom hook to use the context
export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
