import React from 'react';
import { Layout, Result, Button } from 'antd';
import { useNavigation } from '@App/settings/NavigationProvider';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;

export const MaintenancePage: React.FC = () => {
  const { handleNavigate: navigate } = useNavigation();
  const { t } = useTranslation();

  const goBack = () => {
    navigate(-2);
  };

  const goHome = () => {
    navigate(-2);
  };

  const buttons = [
    <Button type="primary" onClick={goHome}>{t('buttons.homepage')}</Button>,
    <Button type="primary" onClick={goBack}>{t('buttons.back')}</Button>,
  ];

  return (
    <Layout>
      <Content style={{ padding: '50px', textAlign: 'center' }}>
        <Result
          status="warning"
          title={t('site.title.maintenance', "Site Under Maintenance")}
          subTitle={t('site.subTitle.maintenance', "Sorry, the site is currently under maintenance. Please check back later.")}
          extra={buttons}
        />
      </Content>
    </Layout>
  );
};
