import React, { useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { Dropdown } from 'antd';


type StatusOptions = Array<{key: string, label: string }>;

export const statusItems:StatusOptions = [
  {
    key: 'confirmed',
    label: 'Confirmed',
  },
  {
    key: 'draft',
    label: 'Draft',
  },
  {
    key: 'pending',
    label: 'Pending',
  },
  {
    key: 'active',
    label: 'Active',
  },
  {
    key: 'inactive',
    label: 'Inactive',
  },
  {
    key: 'deleted',
    label: 'Deleted',
  },
];
const items: MenuProps['items'] = statusItems;
interface StatusDropdownProps {
  onChange: (newValue:string) => void;
  initialValue?: string;
  isLoading?: boolean;
}

const StatusDropdown: React.FC<StatusDropdownProps> = (props) => {
  const [value, setValue] = React.useState<string|undefined>(props.initialValue);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setValue(e.key);
  };

  React.useEffect(() => {
    if (value !== props.initialValue) {
      setValue(props.initialValue)
    }
  }, [props.initialValue])

  return (
    <Dropdown.Button
      type={ value !== props.initialValue ? "primary" : undefined}
      icon={<DownOutlined />}
      loading={props.isLoading}
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      onClick={() => value && props.onChange(value)}
    >
      { value ?? '--' }
    </Dropdown.Button>
  );
};

export default StatusDropdown;
