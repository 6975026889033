import React from 'react';
import { useNavigation } from '@App/settings/NavigationProvider';
import { Button, Card, List, Result, Typography } from 'antd';

import { capitalize } from '@App/components/helpers';

type CountItem = {
  name: string;
  count: number;
}

export function UnderConstruction() {
  const { handleNavigate: nav } = useNavigation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const renderCard = (item: CountItem) => (
    <List.Item>
      <Card title={ capitalize(item.name) } loading={loading}>
        <Typography.Title type={ item.count ? 'success' : 'secondary'}>
          { item.count }
        </Typography.Title>
      </Card>
    </List.Item>
  )
  

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      height: '75%'
    }}>
      <Result
        status={'info'}
        title={ 'This page is under construction' }
        subTitle={ 'errorData?.msg' }
        extra={[
          <Button
            key='to-home'
            type='primary'
            onClick={()=>nav('/')}
            >
            Homepage</Button>,
          <Button onClick={()=>nav(-1)} key='go-back'>
            Back</Button>
        ]}
      />
    </div>
  );
}
