// ErrorPage.tsx
import { Button, Result, Typography } from 'antd';
import { useNavigation } from '@App/settings/NavigationProvider';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface UnauthorizedPageProps {
  errorCode?: number;
}

type ErrorData = {
  code: number;
  title: string;
  msg: string;
};

const errorMessages: ErrorData[] = [
  { code: 0, title: '', msg: 'An unknown error occurred.' },
  {
    code: 400,
    title: 'Bad Request',
    msg: 'The server could not understand the request due to invalid syntax.',
  },
  {
    code: 401,
    title: 'Unauthorized',
    msg: 'You do not have access to this resource.',
  },
  {
    code: 403,
    title: 'Forbidden',
    msg: 'You do not have the necessary permissions to access this resource.',
  },
  {
    code: 404,
    title: 'Page not found',
    msg: 'The requested page could not be found.',
  },
  {
    code: 405,
    title: 'Method Not Allowed',
    msg: 'The HTTP method used in the request is not supported by this resource.',
  },
  {
    code: 408,
    title: 'Request Timeout',
    msg: 'The server did not receive a complete request within the allowed time.',
  },
  {
    code: 429,
    title: 'Too Many Requests',
    msg: 'You have sent too many requests in a given amount of time.',
  },
  {
    code: 500,
    title: 'Internal Server Error',
    msg: 'An error occurred on the server.',
  },
  {
    code: 501,
    title: 'Not Implemented',
    msg: 'The server does not support the functionality required to fulfill the request.',
  },
  {
    code: 502,
    title: 'Bad Gateway',
    msg: 'The server received an invalid response from an upstream server.',
  },
  {
    code: 503,
    title: 'Service Unavailable',
    msg: 'The server is temporarily unable to handle the request.',
  },
  {
    code: 504,
    title: 'Gateway Timeout',
    msg: 'The gateway did not receive a timely response from the upstream server.',
  },
];

export const UnauthorizedPage: React.FC<UnauthorizedPageProps> = ({ errorCode = 403 }) => {
  const { handleNavigate: nav } = useNavigation();
  const {t} = useTranslation();
  const [title, setTitle] = React.useState<string>('')
  const [msg, setMsg] = React.useState<string>('')

  useEffect(() => {
    const errorData = errorMessages.find((i) => i.code == errorCode);
    if (errorData) {
      setMsg(errorData.msg);
      setTitle(errorData.title);
    };
  }, [errorCode]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '75%',
        minHeight: '75vh',
      }}
    >
      <Result
        status="403"
        title={title}
        subTitle={msg}
        extra={[
          <Button key='to-home' type='primary' onClick={() => nav('/')}>
            {t('buttons.homepage')}
          </Button>,
          // <Button key='to-login' onClick={() => nav('/login')}>
          //   {t('buttons.login')}
          // </Button>,
          <Button key='go-back' onClick={() => nav(-2)}>
            {t('buttons.back')}
          </Button>,
        ]}
      />
    </div>
  );
};
