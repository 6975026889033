import { ReloadOutlined, ArrowLeftOutlined, CalendarOutlined, FieldTimeOutlined} from '@ant-design/icons';
import {
  Button, Form, Input, Select, Space, FormProps, FormInstance, Descriptions, theme, Grid, Divider, Flex,
} from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { formatDateTime } from '../helpers';
import { statusItems } from '../buttons/StatusButton';


const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

interface ProjectFormProps {
  action: API.ActionType;
  children?: React.ReactNode;
  handleClose?: () => void;
}

export const ProjectForm: React.FC<FormProps & ProjectFormProps> = ({
  action,
  handleClose,
  ...props
}) => {
  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();
  
  const statusOptions = statusItems.map( ({key}) => ({
    key, value: key, label: t(`settings.status.${key}`),
  }))

  const lastUpdated = props.form?.getFieldValue('updatedAt') as number | null;
  const createdAt = props.form?.getFieldValue('createdAt') as number;

  return (
    <Form
      name={action === 'new' ? t('forms.titles.newUser') : t('forms.titles.updateUser')}
      layout='horizontal'
      initialValues={{
        status: 'draft'
      }}
      {...layout}
      {...props}
    >

      <Form.Item
        name={['slug']}
        label={t('forms.label.key')}
        tooltip={t('forms.tooltip.uid')}
      >
        <Input readOnly />
      </Form.Item>

      <Form.Item
        name={['name']}
        label={t('forms.label.name')}
        rules={[{ required: true }]}
      >
        <Input placeholder={t('forms.placeholders.projectName')} />
      </Form.Item>

      <Form.Item
        name={['translation', 'et']}
        label={t('forms.label.etTranslation')}
        rules={[{ required: false }]}
      >
        <Input
          // prefix={t('forms.label.et')}
          placeholder={t('forms.placeholder.et')}
        />
      </Form.Item>

      <Form.Item
        name={['translation', 'en']}
        label={t('forms.label.enTranslation')}
        rules={[{ required: false }]}
      >
        <Input
          // prefix={t('forms.label.en')}
          placeholder={t('forms.placeholder.en')}
        />
      </Form.Item>

      <Form.Item
        name={['grandikeskus_project_id']}
        label={t('forms.label.grandikeskus_project_id')}
        rules={[{ required: false }]}
      >
        <Input
          showCount
          maxLength={63}
          placeholder={t('forms.placeholders.username')}
        />
      </Form.Item>

      <Form.Item
        name='status'
        label={t('forms.label.status')}
      >
        <Select
          showSearch
          options={statusOptions}
          placeholder={t('forms.placeholders.status')}
        />
      </Form.Item>

      <Descriptions
        size='small'
        layout={md ? 'horizontal' : 'vertical'}
        column={1}
        labelStyle={{
          width: md ? '25%' : '100%',
          placeContent:  md ? 'end' : 'start',
        }}
        contentStyle={{
          marginLeft: md ? '0' : '1rem',
        }}
      >

      { createdAt && (
        <Descriptions.Item label={t('forms.label.createdAt')}>
          {formatDateTime(createdAt, true, 'D. MMMM YYYY - hh:mm')}
        </Descriptions.Item>
      )}

      { lastUpdated && (
        <Descriptions.Item label={t('forms.label.lastUpdate')}>
          {formatDateTime(lastUpdated, true, 'D. MMMM YYYY - hh:mm')}
        </Descriptions.Item>
      )}
      </Descriptions>
      
      <Divider />
      
      <Form.Item {...tailLayout} noStyle>
        <Flex justify='end' gap={6}>
          <Button
            type='text'
            shape='round'
            htmlType='reset'
          >
            {t('forms.btn.reset')}
          </Button>
          {
            handleClose && (
              <Button
                type='text'
                shape='round'
                htmlType='button'
                onClick={handleClose}
              >
                {t('forms.btn.cancel')}
              </Button>
          )}
          <Button
            htmlType='submit'
            type='primary'
            shape='round'
            style={{
              alignSelf: 'end',
              marginLeft: 'auto',
              order: 0
            }}
          >
            {t('forms.btn.save')}
          </Button>
        </Flex>
      </Form.Item>

      {props.children}

    </Form>
  );
};
