import { Button, Divider, Flex, Form, FormProps, Input, Select, Space, Spin, Tag, theme, Typography } from 'antd';
import { ReloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterFunc } from 'rc-select/lib/Select';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { useKeycloak } from '@App/settings/keycloak';
import JsonValidator from '@App/components/fields/JsonValidator';
import { DataContext } from '@App/views/AccessView';
import { useTranslation } from 'react-i18next';
import { Language } from '@AppRoot';
import KC from "@App/@types/keycloakTypes";


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};


const postNewGroup = (kc: KC.KCType, payload: API.PayloadType) => handler<API.GroupType>({
  method: 'POST', path: '/v1/manage/group/', payload
}, kc);

const updateNewPermission = (kc: KC.KCType, payload: API.PayloadType, id:string) => handler<API.GroupType>({
  method: 'PUT', path: '/v1/manage/group/'+id, payload
}, kc);

type GroupFormProps = FormProps & {
  action: 'new' | 'update';
  children?: string | number | boolean 
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | Iterable<React.ReactNode> | React.ReactPortal | React.ReactNode;
}

export const GroupForm: React.FC<GroupFormProps> = ({
  action,
  ...props
}) => {
  const {permissions, facultyOptions} = React.useContext(DataContext);
  const { id } = useParams();
  const { keycloak } = useKeycloak();
  const { t, i18n } = useTranslation();
  
  const { token } = theme.useToken();

  const onFinish = (values: API.NewGroupType) => {
    if (action == 'new') {
      postNewGroup(keycloak!, { action, newValues: values })
    }
    else {
      updateNewPermission(keycloak!, { action, newValues: values }, id!)
    }
  };

  const filterFaculties: FilterFunc<API.FacultyItem> = (input, option) => {
    return option?.code.toLowerCase().includes(input.toLowerCase())
      || option?.name.toLowerCase().includes(input.toLowerCase())
      || option?.labels.toString().toLowerCase().includes(input.toLowerCase())
      || false;
  };

  return (
    <div>
      <Form
        {...layout}
        name={action === 'new' ? 'Add new group' : 'Update group'}
        onFinish={onFinish}
        layout='horizontal'
        style={{ maxWidth: 600 }}
        {...props}
      >
          <Form.Item
            name='name'
            label='Gruppi nimi'
            rules={[{ required: true }]}
          >
            <Input showCount maxLength={60}/>
          </Form.Item>
          
          <Form.Item
            name='label'
            label='Kuvatav nimi'
            rules={[{ required: true }]}
          >
            <Input showCount maxLength={60}/>
          </Form.Item>

          <Form.Item
            name='permissions'
            label={t('forms.title.permissions')}
            rules={[{ required: false }]}
          >
            <Select
              allowClear
              mode="multiple"
              placeholder='Vali gruppi õigus(ed)'
              options={permissions.map((role) => ({
                key: role.id,
                value: role.id,
                label: <b>{role.label}</b>,
                title: role.description
              }))}
           />
          </Form.Item>

          <Form.Item
            name='faculties'
            label='Vali gruppi üksus(ed)'
            rules={[{ required: false }]}
          >
            <Select
              allowClear
              mode="multiple"
              filterOption={filterFaculties}
              //@ts-ignore
              options={facultyOptions.map((faculty, idx) => ({
                labels: faculty.labels,
                name: faculty.name,
                code: faculty.code,
                value: faculty.code,
                label: (
                  <Flex key={`${idx}-${faculty.code}`} >
                    <Tag
                      color={ !faculty.category ? 'processing' : token.colorPrimary}
                      bordered={false}
                    >
                      <b>{faculty.code}</b>
                    </Tag>
                    <Typography.Text strong={faculty.category}>
                      {faculty.labels[i18n.language as Language]}
                    </Typography.Text>
                  </Flex>
                )
              }))}
            />
          </Form.Item>

          <Form.Item
            name='description'
            label='Kirjeldus'
            rules={[{ required: false }]}
          >
            <Input.TextArea showCount maxLength={2000}/>
          </Form.Item>
          
          {/* <Form.Item {...tailLayout}>
            <Space.Compact block>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
              {
                action === 'update' && (
                  <Button danger htmlType='button' onClick={onDelete}>
                    delete
                  </Button>
                )
              }
            </Space.Compact>
          </Form.Item> */}

          { props.children }

      </Form>

    </div>
  );
};
