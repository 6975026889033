import { Tooltip } from 'antd';
import React from 'react';

interface WrapWithTooltipProps {
    children: React.ReactNode;
    translationKey: React.ReactNode | string;
    fallbackValue?: React.ReactNode;
    show?: boolean;
}

/**
 * Conditionally wraps an element with an Ant Design Tooltip showing the translation key
 * Only shows the tooltip in staging environment
 */
export const wrapWithTooltip = ({
    children,
    translationKey,
    show = import.meta.env.MODE !== 'production' || window.location.hostname.includes('demo.')
}: WrapWithTooltipProps): React.ReactElement => {
    if (!show) {
        return <React.Fragment>{children}</React.Fragment>;
    }

    const tooltipText = typeof translationKey === 'string'
        ? `Translation Key: ${translationKey}`
        : translationKey;

    return <Tooltip title={tooltipText} placement="top">{children}</Tooltip>;
};
