// import Keycloak from 'keycloak-js';
import React from 'react';
import type KC from "@App/@types/keycloakTypes";

/**
 * Initial state for the Keycloak context
 */
const initialState: KC.State = {
  status: 'initializing',
  authenticated: false,
  keycloak: undefined,
  userData: undefined,
  error: undefined
};

/**
 * React context for Keycloak authentication state
 * @internal
 */
const KeycloakContext = React.createContext<KC.State>(initialState);

KeycloakContext.displayName = 'KeycloakContext';

/**
 * Custom hook to access Keycloak authentication state
 *
 * @throws {Error} If used outside of KeycloakProvider
 * @returns {KC.State} Current authentication state
 *
 * @example
 * ```tsx
 * const MyComponent = () => {
 *   const { authenticated, userData } = useKeycloak();
 *
 *   if (!authenticated) {
 *     return <div>Please log in</div>;
 *   }
 *
 *   return <div>Welcome, {userData?.firstName}</div>;
 * };
 * ```
 */
export function useKeycloak(): KC.State {
  const context = React.useContext(KeycloakContext);

  if (context === undefined) {
    throw new Error(
      'useKeycloak must be used within a KeycloakProvider'
    );
  }

  return context;
}

export default KeycloakContext;
