import { Descriptions, Divider, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';


const labelStyles: React.CSSProperties= {
  fontFamily: 'Montserrat',
  fontWeight: 600,
  textTransform: 'uppercase'
}

const contentStyles: React.CSSProperties= {
  fontFamily: 'Rubik',
  // fontSize: '1rem'
}

const ContactInfo: React.FC = () => {
  const {t} = useTranslation('info');

  return (
    <>
      {/* <Divider>
        <Typography.Title type='secondary' level={4}>
          {t('site.contact.name')} {t('site.label.contact')}
        </Typography.Title>
      </Divider> */}

      <Descriptions
        layout="vertical"
        contentStyle={contentStyles}
        labelStyle={labelStyles}
        // column={2}
      >
        <Descriptions.Item label={t('site.label.email')}>
          {t('site.contact.email')}
        </Descriptions.Item>

        <Descriptions.Item label={t('site.label.room')}>
        {t('site.contact.address')}-{t('site.contact.room')}
        </Descriptions.Item>

        {/* <Descriptions.Item label={t('site.label.telephone')}>
          {t('site.contact.telephone')}
        </Descriptions.Item> */}

        <Descriptions.Item label={t('site.label.address')} >
          {t('site.contact.address')}, {t('site.contact.postIndex')},<br/>
          {t('site.contact.town')}, {t('site.contact.county')},<br/>
          {t('site.contact.country')}
        </Descriptions.Item>

      </Descriptions>
    </>
  );
}
export default ContactInfo;