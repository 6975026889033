import {
  PlusOutlined, ReloadOutlined, CheckCircleOutlined,
  CloseCircleOutlined, EditOutlined, UserAddOutlined,
  UserOutlined, DeleteOutlined, ExportOutlined,
  MoreOutlined, SearchOutlined,
} from '@ant-design/icons';
import {
  Avatar, Button, Form, Input, message, Modal, Row, Space,
  Tag, Tooltip, Typography, Grid, Col, theme, Flex, Divider, Dropdown, DropdownProps, MenuProps, Popconfirm, PopconfirmProps,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import { ActionTypes } from '@App/settings/reducers';
import { ApplicationState } from '@App/settings/StateManager';
import { UserForm } from '@App/components/forms/UserForm';
import { formatDateTime } from '@App/components/helpers';
import { useKeycloak } from '@App/settings/keycloak';
import { handler } from '@App/settings/ApiHandler';
import AppTable from '@App/components/tables/TableComponent';
import DeleteButton from '@App/components/buttons/DeleteButton';

import { DataContext } from '../../AccessView';
import { MenuInfo } from 'rc-menu/lib/interface';
import UserSearchModal, {LdapUserInfo} from "@App/components/UserSearchModal";
import KC from "@App/@types/keycloakTypes";


const tableName = 'usersList';

const emailStyles = {
  fontFamily: 'Rubik',
  fontWeight: 300,
  fontSize: '1rem',
};

const postUserData = (
  kc: KC.KCType,
  payload: API.PayloadType,
  uuid?: string | undefined
) => handler<Responses.Default>({
  method: typeof uuid == 'string' ? 'PUT' : 'POST',
  path: `/v1/manage/users/${uuid ?? ''}`,
  payload,
}, kc);


export const UsersList: React.FC = () => {
  const {state, dispatch} = React.useContext(ApplicationState);
  const { users, isLoading, setLoading, ...rest } = React.useContext(DataContext);
  
  const [popconfirmPos, setPopconfirmPos] = useState({ x: 0, y: 0 });
  const [showPopconfirm, setShowPopconfirm] = React.useState(false);
  const [activeUuid, setActiveUuid] = React.useState<string|null>(null);

  const [openAction, setOpenAction] = useState<string|null>(null);
  const [open, setOpen] = React.useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = React.useState(false);
  const [filteredUsers, setFilteredUsers] = React.useState<Responses.UserType[]>([]);
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [showLdapUserSearch, setShowLdapUserSearch] = React.useState<boolean>(false);
  const [action, setAction] = React.useState<'add'|'new'|'update'>('new');
  const [search, setSearch] = React.useState('');
  const userValues = React.useRef<Responses.UserType | null>(null);
  
  const { keycloak } = useKeycloak();
  const { token } = theme.useToken();
  const [ form ] = Form.useForm();
  const { md } = Grid.useBreakpoint();
  const { t } = useTranslation();

  // React.MouseEvent<HTMLElement, MouseEvent>
  const handleShowPopconfirm = (e: MenuInfo, uuid:string) => {
    // e?.stopPropagation(); // Prevent dropdown from closing
    //@ts-ignore
    const { clientX, clientY } = e.domEvent;
    
    setPopconfirmPos({
      x: clientX as number,
      y: clientY as number
    });
    setActiveUuid(uuid);
    setShowPopconfirm(true);
  };
  
  const handleConfirmDelete = (uuid?: string) => {
    if (uuid ?? activeUuid) {
      handleUserDelete(uuid || activeUuid || '');
      setShowPopconfirm(false);
    }
  };
  
  const handleCancelPopconfirm = () => {
    setShowPopconfirm(false);
    setActiveUuid(null);
    setOpenAction(null);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  const handleFormSubmit = (values: any) => {
    setLoading(true);
    const payload = { action, values, lastUpdated: userValues.current?.last_updated ?? null };

    postUserData(keycloak!, payload, values?.uuid)
    .then((data) => {
      if (data.success) {
        setModalOpen(false);
        message.success(data.success)
        rest.doRefresh();
      }
    })
    .catch((err)=>{
      message.error(`Error: ${err}`)
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleCancel = () => {
    setModalOpen(false);
    form.resetFields();
  };

  const handleUserAutoUpdate = (values: LdapUserInfo, task: 'add' | 'update') => {
    form.resetFields();

    if (task === 'update') {
      const foundUserValues = users.find(
          ({username}) => 'username' in values && username === values.username
      );
      if (foundUserValues) {
        userValues.current = foundUserValues;
        form.setFieldsValue(foundUserValues);
      } else {
        message.warning(t('forms.warning.userNotFound'));
      }
    }

    form.setFieldsValue({
      fullname: `${values.firstName} ${values.lastName}`,
      username: values.username,
      email: values.email
    });

    setAction(task === 'add' ? 'new' : task);
    setModalOpen(true);
  };

  const handleUserFormUpdate = (uuid: string) => {
    form.resetFields();
    const foundUserValues = users.find(u => u.uuid === uuid);

    if (foundUserValues) {
      userValues.current = foundUserValues;
      form.setFieldsValue(foundUserValues);
    } else {
      message.warning(t('forms.warning.userNotFound'));
    }
    
    setAction('update');
    setModalOpen(true);
  };

  const handleNewForm = () => {
    form.resetFields();
    userValues.current = null;
    setAction('new')
    setModalOpen(true);
  };
  
  const handleUserDeleteCB = (u: Responses.UserType) => {
    rest.doRefresh();
    setOpenAction(null);
    message.success(`${u.fullname} account has been successfully deleted.`)
  };

  React.useEffect(() => {
    if (users.length) {
      const newFilteredList = users.filter((user) =>
        user.fullname.toLowerCase().includes(search.toLowerCase()) ||
        user.username.toLowerCase().includes(search.toLowerCase()) ||
        user.email && user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.faculties?.join(' ').toLowerCase().includes(search.toLowerCase())
      )
      setFilteredUsers(newFilteredList);
    }
  }, [search, users]);

  const handleUserDelete = (uuid: string) => {
    const payload = {
      action: 'delete',
      uuid,
      lastUpdated: userValues.current?.last_updated ?? null,
    } as API.PayloadType;

    postUserData(keycloak!, payload, uuid + '/delete')
    .then(res => {
      if (res.success) {
        //@ts-ignore
        'item' in res && handleUserDeleteCB(res.item);
        setActiveUuid(null)
        setOpenAction(null);
      }
    })
  };

  const userActions = (uuid: string): MenuProps['items'] => [
    {
      key: '1',
      label: t('forms.btn.update'),
      onClick: () => handleUserFormUpdate(uuid),
      icon: <EditOutlined />,
    },
    // {
    //   key: '2',
    //   label: t('forms.btn.update'),
    //   onClick: () => nav(`/manage/access/users/${uuid}`),
    //   icon: <ExportOutlined />,
    // },
    {
      key: '3',
      label: (
        <Popconfirm
          title={t('forms.title.confirmDelete')}
          description={t('forms.actions.confirm.delete', { item: t('forms.actions.user') })}
          okText={t('forms.label.yes')}
          // cancelText={t('forms.label.no')}
          okButtonProps={{ loading: false }}
          // open={showPopconfirm}
          onConfirm={() => handleConfirmDelete(uuid)}
          onCancel={handleCancelPopconfirm}
      >
        {t('forms.btn.delete')}
      </Popconfirm>
      ),
      onClick: (e) => handleShowPopconfirm(e, uuid),
      icon: <DeleteOutlined /> },
  ];

  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const columns: ColumnsType<Responses.UserType> = [
    {
      key: 'id',
      // title: '#',
      dataIndex: 'roles',
      width: 50,
      align: 'center',
      defaultSortOrder: 'ascend',
      // sortDirections: ["descend"],
      sorter: {
        multiple: 1,
        compare: (a, b) => {
          const aRoles = a.roles as number[] ?? [] as number[];
          const bRoles = b.roles as number[] ?? [] as number[];
          const aIsAdmin = aRoles.includes(1) ? 1 : 0;
          const bIsAdmin = bRoles.includes(1) ? 1 : 0;
          return bIsAdmin - aIsAdmin;
        },
      },
      render: (roles: number[], record: Responses.UserType) => {
        const isManager = roles?.includes(1);
        return (
          <Avatar
            size='small'
            style={{ backgroundColor: isManager ? token.colorPrimary : undefined }}
          >
            {record.fullname.split(' ').map((n) => n?.[0])}
          </Avatar>
      )},
    },
    {
      key: 'fullname',
      title: t('forms.label.fullname'),
      dataIndex: 'fullname',
      sorter: {
        multiple: 3,
        compare: (a, b) => a.fullname.localeCompare(b.fullname)
      },
      render: (_, record: Responses.UserType) => {
        const hasEmail = (record.email?.length ?? 0) > 2;
        const roles = record.roles as number[] ?? [] as number[];
        return (
          <Space direction='vertical'>
            <Typography.Text strong>
              {record.fullname}
            </Typography.Text>
            <Space direction='horizontal'>
              <Typography.Text type='secondary'>
                {record.username}
              </Typography.Text>
              { roles?.includes(1) && (
                  <Tag color='green'>admin</Tag>
              )}
              { roles?.includes(6) && (
                  <Tag color='blue'>auditor</Tag>
              )}
              { hasEmail && (
                  <Tooltip title={record.email} color='#108ee9'>
                    <Button type='link' color='primary' style={emailStyles}>@</Button>
                  </Tooltip>
              )}
            </Space>
          </Space>
        )
      },
    },
    {
      key: 'active',
      title: t('forms.label.isActive'),
      dataIndex: 'isActive',
      sorter: {
        multiple: 4,
        compare: (a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1)
      },
      width: 130,
      align: 'center',
      responsive: ['md'],
      render: (isActive: boolean) => isActive
        ? <CheckCircleOutlined style={{color:token.colorSuccess}} />
        : <CloseCircleOutlined style={{color:token.colorError}} />,
    },
    {
      key: 'created_at',
      title: t('forms.label.createdAt'),
      dataIndex: 'created_at',
      defaultSortOrder: 'ascend',
      sorter: {
        multiple: 1,
        compare: (a, b) => a.created_at - b.created_at,
      },
      // sortDirections: ["descend"],
      width: 130,
      align: 'center',
      responsive: ['md'],
      render: (createdAt, record) => (
        <Tooltip title={formatDateTime(createdAt, true, 'DD.MM.YYYY - hh:mm:ss')}>
          <Tag bordered={false}>
            {formatDateTime(createdAt, true, 'DD.MM.YYYY')}
          </Tag>
        </Tooltip>
      )
    },
    {
      key: 'action',
      title: t('forms.label.action'),
      // width: md ? 240 : 100,
      width: 80,
      align: 'center',
      render: (_: string, record: Responses.UserType) => (
        <Dropdown
          // open={openAction === record.uuid}
          // trigger={['click']}
          placement='bottomRight'
          menu={{
            items: userActions(record.uuid),
            // onClick: handleMenuClick,
          }}
        >
          <Button
            onClick={(e) => {e.preventDefault(); setOpenAction(record.uuid)}}
            type='text'
            // shape='round'
            icon={<MoreOutlined />}
          />
        </Dropdown>
      ),
      // render: (_: string, record: Responses.UserType) => (
      //   <Space size='small'>
      //     <Button
      //       type='text'
      //       icon={<EditOutlined />}
      //       onClick={()=> handleUserFormUpdate(record.uuid) }
      //     >
      //       { md && t('forms.btn.update')}
      //     </Button>
      //     <Button
      //       type='text'
      //       icon={<EditOutlined />}
      //       onClick={()=> nav(`/manage/access/users/${record.uuid}`) }
      //     >
      //       { md && t('forms.btn.update')} page
      //     </Button>
      //     {/* <EditButton record={record} /> */}
      //     <DeleteButton
      //       type='text'
      //       item='user'
      //       path={`/v1/manage/user/${record.uuid}`}
      //       onDelete={handleUserDeleteCB}
      //       record={record}
      //       iconOnly={!md}
      //     />
      //   </Space>
      // ),
    },
  ];
 
  const loadTableConfig = () => {
    dispatch({ type: ActionTypes.Load_table, payload: { tableName } });
    console.info({ type: ActionTypes.Load_table }, tableName);
  };

  React.useEffect(() => {
    if (!state.tables[tableName]) {
      loadTableConfig();
    };
  }, []);

  return (
    <div>
      <Row
        gutter={[10,10]}
        justify='space-between'
        style={{ margin: '1rem 0' }}
      >
        <Col sm={24} md={10}>
          <Input.Search 
            placeholder={t("table.texts.placeholders.searchUsers")}
            value={search}
            onSearch={handleSearch}
            onChange={handleSearchChange}
            // prefix={<SearchOutlined />}
          />
        </Col>
        <Col >
          <Button
            shape={'round'}
            type={'link'}
            icon={<SearchOutlined />}
            onClick={() => setShowLdapUserSearch(true)}
            style={{ marginRight: 'auto' }}
          >
            {t('forms.btn.searchUtUser', 'UT kasutaja otsing')}
          </Button>
        </Col>
      </Row>

      <AppTable<Responses.UserType & { status?: string; }>
        rowKey='uuid'
        tableName={tableName}
        tableTitle='pages.title.usersList'
        loading={isLoading}
        extraColumns={columns}
        dataSource={filteredUsers}
        isAdmin={true}
        isSelectable={false}
        counter={users.length}
        sticky={{ offsetHeader: 64 }}
        scroll={{ x: 300 }}
        headerButtons={[
          <Button
            shape='round'
            key={"refresh"}
            icon={<ReloadOutlined/>}
            onClick={rest.doRefresh}
          >
            { md && t('buttons.refresh')}
          </Button>,
          <Button
            key='new-user'
            shape='round'
            type='primary'
            icon={<PlusOutlined />}
            onClick={handleNewForm}
          >
            {t('forms.btn.add')}
          </Button>
        ]}
      />

      <UserSearchModal
          visible={showLdapUserSearch}
          onClose={() => setShowLdapUserSearch(false)}
          onSelectUser={handleUserAutoUpdate}
          existingUsers={users ?? []}
      />

      <Modal
        title={
          <Flex gap='.5rem' align='center' style={{color: token.colorPrimary, fontSize: '1.4rem'}}>
            { action == 'new'
              ? <UserAddOutlined />
              : <UserOutlined />
            }
            <Typography.Title
              level={4}
              style={{color: token.colorTextSecondary, margin: 0}}
            >
              {t(`forms.title.${action}User`) }
            </Typography.Title>
          </Flex>
        }
        open={modalOpen}
        // onOk={handlePost}
        okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={md ? 650 : 'auto'}
        footer={false}
      >
        <Divider style={{
            borderColor: token.colorPrimary,
            marginTop: 0,
            opacity: .3
        }} />
        <UserForm
          form={form}
          //@ts-ignore
          action={action}
          isReady={setIsReadyToSubmit}
          onFinish={handleFormSubmit}
        >
          <Form.Item noStyle>
            <Flex gap='.5rem' justify='end'>
              <Button
                htmlType='reset'
                type='text'
                shape='round'
                onClick={handleCancel}
              >
                {t(`forms.btn.cancel`)}
              </Button>
              <Button
                type={isReadyToSubmit ? 'primary' : 'default'}
                shape='round'
                htmlType='submit'
                loading={isLoading}
              >
                {t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
              </Button>
            </Flex>
          </Form.Item>
        </UserForm>
      </Modal>

    </div>
  );
};
