import { Grid, theme } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { Language } from '@AppRoot';
import CircleLogo from '@App/assets/images/ut-circle-blue.svg';
import EngLogo from '@App/assets/images/pealogo_eng_sinine.svg';
import EstLogo from '@App/assets/images/pealogo_est_sinine.svg';


export const AppLogo: React.FC = () => {
  const { i18n } = useTranslation();
  const { token } = theme.useToken();
  const screens = Grid.useBreakpoint();

  const logoStyles = {
    minWidth: 200,
    maxHeight: 56,
    paddingTop: 6,
    color: token.colorUTLogo || '#2C5697'
  };

  return (
    <div className='logo-wrapper' style={logoStyles}>
    {!screens.md
      ? <CircleLogo />
      : i18n.language.includes( Language.ET )
        ? <EstLogo aria-label="Tartu Ülikooli logo" />
        : <EngLogo aria-label="University of Tartu logo" />
    }
    </div>
  );
};


export const UTLogo: React.FC = () => {
  const { i18n } = useTranslation();
  const { token } = theme.useToken();

  const logoStyles = { color: token.colorUTLogo || '#2C5697' };

  return (
    <div className='logo-wrapper' style={logoStyles}>
      { i18n.language.includes( Language.ET )
        ? <EstLogo aria-label="Tartu Ülikooli logo" />
        : <EngLogo aria-label="University of Tartu logo" />
      }
    </div>
  );
};
