import {
  LockOutlined, DownOutlined, ColumnHeightOutlined,
  LeftOutlined, CloseOutlined, SettingTwoTone,
  InsertRowLeftOutlined, InsertRowRightOutlined,
  ExportOutlined, ControlOutlined,
} from '@ant-design/icons';
import {
  Table, Typography, Grid, RadioChangeEvent, Drawer, Button,
  Space, Switch, Menu, Radio, Select, Flex, theme
} from 'antd';
import { useTranslation } from 'react-i18next';
import React, { ReactNode, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { ActionTypes } from '@App/settings/reducers';
import { TableTitle } from './TableTitleComponent';
import ColumnSelect from './ColumnSelect';

import type {TableLocale, SorterResult, TablePaginationConfig, ColumnType, ColumnTitle } from 'antd/es/table/interface';
import type { ColumnsType, TableProps } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import type { PaginationLocale } from 'antd/es/pagination/Pagination';

import { ExportFileTypes, exportToFile } from '../FileExporters';


const defaultPageSizeOptions: number[] = [10, 50, 100, 200];

const defaultConfig: App.TableMeta = {
  size: 'small',
  pageSize: 50,
  compactMode: true,
  hiddenColumns: [],
  filters: null,
  sorter: []
}

type ConfirmPayload = {
  newStatus: string,
  items: React.Key[] | undefined,
}

interface TableComponentProps<T> extends TableProps<T> {
  isAdmin: boolean;
  isSelectable: boolean;
  tableName: string;
  tableTitle?: string;
  extraColumns: ColumnsType<T>;
  counter?: number;
  customEmptyText?: React.ReactNode | (() => React.ReactNode);
  onConfigChange?: (config: App.TableMeta) => void;
  onConfirm?: ({newStatus, items}: ConfirmPayload) => void;
  headerButtons?: ReactNode | ReactNode[];
  isFilterable?: boolean;
}

const AppTable = <T extends {status?: string}>({
  counter,
  tableName,
  tableTitle,
  extraColumns,
  onConfigChange,
  isAdmin = false,
  isSelectable = false,
  isFilterable = false,
  ...props
}: TableComponentProps<T>) => {
  const {state, dispatch} = useContext(ApplicationState);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(
    state.tables?.[tableName]?.pageSize ?? 50);
  // const [resultSize, setResultSize] = useState<number>(0);
  // const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  // const [sortedInfo, setSortedInfo] = useState<SorterResult<T> | SorterResult<T>[] | undefined>();
  
  type Option = {value:string, label:string | ColumnTitle<T>} | string;
  const [fixedLeftItems, setFixedLeftItems] = useState<Array<Option>>(
    state.tables?.[tableName]?.fixedColumnsLeft ?? []
  );
  const [fixedRightItems, setFixedRightItems] = useState<Array<Option>>(
    state.tables?.[tableName]?.fixedColumnsRight ?? []
  );

  const [rowSelection, setRowSelection] = useState<TableRowSelection<T> | undefined>({
    selectedRowKeys: [],
    onChange: (selectedRowKeys) => {
      setRowSelection(prevState => ({
        ...prevState,
        selectedRowKeys
      }));
    },
    //@ts-ignore
    getCheckboxProps: (record) => {
      if ("status" in record) {
        return {
          disabled: record.status == 'confirm'
        };
      }
      return undefined
    },
    renderCell: (checked, record, index, originNode) => {
      if ("status" in record && (isAdmin || !['confirm', 'active'].includes(record.status ?? ''))) {
        return originNode
      }
      return null;
    }
    // renderCell: (checked, record, index, originNode) => {
    //   const handleSelect = (isSelected:boolean) => {
    //     let newSelectedRowKeys = [...rowSelection.selectedRowKeys];
    //     if (!isSelected) {
    //       newSelectedRowKeys.push(record.uuid);
    //     } else {
    //       const keyIndex = newSelectedRowKeys.indexOf(record.uuid);
    //       if (keyIndex > -1) {
    //         newSelectedRowKeys.splice(keyIndex, 1);
    //       }
    //     }
    //     setRowSelection({
    //       ...rowSelection,
    //       selectedRowKeys: newSelectedRowKeys
    //     });
    //   }
    //   return record.status == 'pending' ? (
    //     <>
    //     {/* Render custom icon here based on `checked` state */}
    //     {checked
    //       ? <CheckSquareTwoTone onClick={()=> handleSelect(true)} style={{fontSize:'1.4rem'}} />
    //       : <BorderOutlined onClick={()=> handleSelect(false)} style={{fontSize:'1.4rem'}} />
    //     }
    //     {/* <Checkbox
    //       checked={checked}
    //       onChange={(e) => handleSelect(e.target.checked)}
    //     /> */}
    //     </>
    //   ) : null;
    // },
  });

  const newCustomColumns = useRef<{hiddenColumns: string[], columnsOrder: string[]}|null>();

  const { token } = theme.useToken();
  const {t} = useTranslation();

  const [tableConfig, setTableConfig] = useState<App.TableMeta>(
    state.tables?.[tableName] ?? defaultConfig,
  );

  const [tableConfigOpen, setTableConfigOpen] = useState(false);
  const [columnsOpen, setColumnsOpen] = useState(false);

  const closeAll = () => {
    setColumnsOpen(false);
    setTableConfigOpen(false);
  };

  const closeConfigDrawer = () => {
    setTableConfigOpen(false);
  };

  const closeColumnDrawer = () => {
    setColumnsOpen(false);
  };

  const clearAll = () => {
    const updatedConfig = { ...tableConfig, filters: null };
    setTableConfig(updatedConfig);
    updateTableConfig(updatedConfig);
  };

  const handlePageSizeChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
    // Delay the propagation of this change to the global state or make it conditional
    // if (size !== state.tables?.[tableName]?.pageSize) {
    //   console.log('page size update state')
    //   updateTableConfig({ ...tableConfig, pageSize: size });
    // }
    // setTableConfig(prevConfig => ({ ...prevConfig, pageSize: size }));
    setTableConfig({ ...tableConfig, pageSize: size });
    updateTableConfig({ ...tableConfig, pageSize: size });
  };
  
  const handleTableChange: TableProps<T>['onChange'] = (
    pagination, filters, sorter, extra
  ) => {
    if (
      JSON.stringify(filters) != JSON.stringify(tableConfig.filters) ||
      JSON.stringify(sorter) != JSON.stringify(tableConfig.sorter)
      ) {
        const newConfig: App.TableMeta = {
          ...tableConfig,
          ...state.tables?.[tableName],
          pageSize,
          sorter,
          filters,
        };
      setTableConfig(newConfig);
      updateTableConfig(newConfig);
    }
    // setResultSize(extra.currentDataSource.length);
  };

  const handleStatusUpdate = (newStatus: string): void => {
    props.onConfirm && props.onConfirm({
      newStatus,
      items: rowSelection?.selectedRowKeys,
    });
  };

  const updateTableConfig = (data: App.TableMeta) => {
    dispatch({ type: ActionTypes.Update_table, payload: { tableName, data } });
    console.info({ type: ActionTypes.Update_table, tableName, data });
  };

  const handleConfigChange = (newConfig: App.TableMeta | any) => {
    setTableConfig(newConfig);
    updateTableConfig(newConfig);
    onConfigChange && onConfigChange(newConfig);
  };

  const handleColumnOrderUpdate = (columnsOrder: string[], hiddenColumns: string[]) => {
    newCustomColumns.current = { columnsOrder, hiddenColumns };
  };

  const orderIndex = (itemKey: React.Key): number => {
    return state.tables?.[tableName]?.columnsOrder?.indexOf(itemKey as string) ?? 0;
  };

  const isLocked = (key: string): 'left'|'right'|undefined => {
    const foundRight = fixedRightItems.find(
      i => (typeof i == 'string' ? i : i['value']) == key
    );
    const foundLeft = fixedLeftItems.find(
      i => (typeof i == 'string' ? i : i['value']) == key
    );

    if (foundRight) return 'right';
    else if (foundLeft) return 'left'
    else return undefined;
  };

  const columns: ColumnsType<T> = useMemo(() => extraColumns.map((col, index) => ({
      ...col,
      fixed: isLocked(col.key as string),
      ellipsis: (state.tables?.[tableName] || tableConfig).compactMode,
      filteredValue: (state.tables[tableName] || tableConfig)?.filters?.[`${col.key+''}`] ?? null,
      sortOrder: (state.tables[tableName] || tableConfig).sorter?.columnKey === col.key ? (state.tables[tableName] || tableConfig).sorter?.order : null,
    }))
    // .sort((a,b) => orderIndex(a.key as string) - orderIndex(b.key as string))
    //.filter(i => state.tables?.[tableName].hiddenColumns.includes(i.key as string))
  , [extraColumns, state.tables[tableName], tableConfig, fixedRightItems, fixedLeftItems]);
  
  const handleSaveColumns = () => {
    if (newCustomColumns.current) {
      updateTableConfig({
        ...state.tables?.[tableName],
        ...newCustomColumns.current
      });
      closeColumnDrawer();
    }
  }

  const handleToggleCompactMode = () => {
    setTableConfig( prev => ({ ...prev, compactMode: !prev.compactMode }) );
    updateTableConfig({
      ...state.tables?.[tableName],
      compactMode: !(state.tables?.[tableName]?.compactMode)
    })
  };

  const handleRowSize = (a: RadioChangeEvent) => {
    setTableConfig( prev => ({ ...prev, size: a.target.value }) );
    const prevConf = state.tables?.[tableName];
    updateTableConfig({ ...prevConf, size: a.target.value });
  };
  
  const handleFileExport = (exportType: ExportFileTypes) => {
    if (
      props.dataSource &&
      props.dataSource.length &&
      ['csv'].includes(exportType)
    ) {
      let data = props.dataSource as any[];
      if (rowSelection?.selectedRowKeys && rowSelection.selectedRowKeys.length) {
        data = data.filter(
          i => rowSelection?.selectedRowKeys?.includes(i.uuid)
        )
      }
      exportToFile(//@ts-ignore
        columns.filter(filterHidden).sort(columnOrder),
        data.sort(columnOrder),
        exportType
      );      
    }
  };

  const handleFixedCol = (side: 'left' | 'right') =>
    (value: Option[]) => {
      if (side == 'left') {
        setFixedLeftItems(value);
      }
      else if (side == 'right') {
        setFixedRightItems(value);
      }
  };

  const columnOrder = (a: ColumnType<T>, b: ColumnType<T>) =>
    orderIndex(a.key as string) - orderIndex(b.key as string);

  const filterHidden = (i: ColumnType<T>): boolean => !(
    ( state.tables?.[tableName] ?? tableConfig ).hiddenColumns?.includes(i.key as string)
  );

  const columnsOptions = extraColumns.map(i => ({
    value: i.key as string,
    label: i.title
  }));

  const filteredOptions = React.useMemo( () => {
    const curHiddenList = state.tables?.[tableName]?.hiddenColumns ?? [] as string[];
    return columnsOptions.filter(
      (o) =>
        !([...fixedLeftItems, ...fixedRightItems].includes(o))
        // && !(curHiddenList.includes(o.value))
    );
  }, [fixedLeftItems, fixedRightItems])

  const tableActions = [
    {
      key: 'page-size',
      label: (
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography.Text>
            {t('table.label.tablePageSize')}
          </Typography.Text>
          <Select
            defaultValue={state.tables?.[tableName]?.pageSize}
            options={defaultPageSizeOptions.map(i=> ({value:i, label:i}))}
            onChange={(v) => handlePageSizeChange(1,v)}
            style={{
              width: '100%',
              minWidth: 80
            }}
          />
        </Space>
      )
    },
    {
      key: 'compact_mode',
      title: t('table.tooltip.toggleCompactMode'),
      onClick: handleToggleCompactMode,
      label: (
        <Space style={{ justifyContent: 'space-between', width: '100%' }}>
          <Typography.Text>{t('table.labels.toggleCompactMode')}</Typography.Text>
          <Switch
            size='small'
            checked={state.tables?.[tableName]?.compactMode}
          />
        </Space>
      )
    },
    {
      key: 'row-size',
      // type: 'group',
      label: t('table.labels.tableSize'),
      icon: <ColumnHeightOutlined />,
      children: [
        {
          key: 'switch-size',
          label: (
            <Radio.Group
              onChange={handleRowSize}
              value={state.tables?.[tableName]?.size}
              optionType="button"
              buttonStyle="solid"
              className='btn-radio-round'
              options={[
                {value: 'small', label: t('buttons.small')},
                {value: 'middle', label: t('buttons.middle')},
                {value: 'large', label: t('buttons.large')},
              ]}
            />
            
          )
        },
      ],
    },
    {
      key: 'locked-columns',
      title: t('table.tooltip.lockedColumns'),
      label: t('table.button.lockedColumns'),
      icon: <LockOutlined />,
      // disabled: true,
      children: [
        {
          key: 'fixed-columns-left',
          label: t('table.label.lockedColumnsLeft'),
          type: 'group',
          children: [
            {
              key: 'fixed-columns-left-select',
              icon: <InsertRowLeftOutlined />,
              className: 'full-height',
              label: (
                <Select
                  allowClear
                  mode="multiple"
                  maxCount={3}
                  value={fixedLeftItems}
                  onChange={handleFixedCol('left')}
                  suffixIcon={(
                    <>
                      <span>{fixedLeftItems.length} / 3</span>
                      <DownOutlined />
                    </>
                  )}
                  options={filteredOptions}
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              )
            }
          ]
        },
        {
          key: 'fixed-columns-right',
          label: t('table.label.lockedColumnsRight'),
          type: 'group',
          children: [
            {
              key: 'fixed-columns-right-select',
              icon: <InsertRowRightOutlined />,
              className: 'full-height',
              label:  (
                <Select
                  mode='multiple'
                  allowClear
                  maxCount={3}
                  value={fixedRightItems}
                  onChange={handleFixedCol('right')}
                  suffixIcon={(
                    <>
                      <span>{fixedRightItems.length} / 3</span>
                      <DownOutlined />
                    </>
                  )}
                  options={filteredOptions}
                  style={{
                    width: '100%'
                  }}
                />
              )
            }
          ]
        },
      ],
    },
    {
      key: 'visible-columns',
      label: t('table.title.customizeColumns'),
      // icon: <TableOutlined />,
      icon: <ControlOutlined />,
      onClick: () => setColumnsOpen(true),
    },
    {
      key: 'export',
      label: t('table.labels.export', 'Export'),
      icon: <ExportOutlined />,
      // disabled: true,
      children: [
        {
          key: 'export-csv',
          onClick: () => handleFileExport('csv'),
          label: <strong>.csv</strong>,
        },
        {
          key: 'export-xls',
          onClick: () => handleFileExport('xls'),
          label: <strong>.xls</strong>,
          disabled: true
        },
        {
          key: 'export-excel',
          onClick: () => handleFileExport('xlsx'),
          label: <strong>.xlsx</strong>,
          disabled: true,
        },
        {
          key: 'export-pdf',
          onClick: () => handleFileExport('pdf'),
          label: <strong>.pdf</strong>,
          disabled: true,
        },
      ],
    },
  ];

  const tableTranslations: TableLocale = {
    emptyText: props.customEmptyText ?? t('table.texts.emptyText'),
    filterConfirm: t('table.texts.filterConfirm'),
    filterReset: t('table.texts.filterReset'),
    filterSearchPlaceholder: t('table.texts.placeholders.filterFaculty'),
    filterCheckall: t('table.texts.filterCheckAll'),
    // filterTitle: t('table.texts.filterTitle'),
    // filterEmptyText: t('table.texts.filterEmptyText'),
    selectAll: t('table.sort.selectAll'),
    selectNone: t('table.texts.selectNone'),
    selectInvert: t('table.texts.selectInvert'),
    selectionAll: t('table.texts.selectionAll'),
    // sortTitle: t('table.texts.sortTitle'),
    cancelSort: t('table.sort.cancel'),
    // expand: t('table.texts.expand'),
    // collapse: t('table.texts.collapse'),
    // triggerAsc: t('table.sort.asc'),
    // triggerDesc: t('table.sort.desc'),
  };

  const paginationTranslations: PaginationLocale = {
    items_per_page: t("table.pagination.itemsPerPage"),
    jump_to: t("table.pagination.jumpTo"),
    jump_to_confirm: t("table.pagination.jumpToConfirm"),
    page: t("table.pagination.page"),
    prev_page: t("table.pagination.prevPage"),
    next_page: t("table.pagination.nextPage"),
    prev_5: t("table.pagination.prev5"),
    next_5: t("table.pagination.next5"),
    prev_3: t("table.pagination.prev3"),
    next_3: t("table.pagination.next3"),
  };

  const pagination: TablePaginationConfig = {
    locale: paginationTranslations,
    showTotal: (total, [start, end]) => t('table.pagination.result', {start, end, total}),
    showSizeChanger: true,
    onShowSizeChange: handlePageSizeChange,
    current: currentPage,
    pageSize: state.tables?.[tableName]?.pageSize,
    onChange: (page: number, pageSize: number) => {
      setCurrentPage(page);
      setPageSize(pageSize);
    },
    pageSizeOptions: defaultPageSizeOptions,
    className: 'table-pagination',
    responsive: true,
    style: {
      borderColor: token.colorBorder,
      background: token.Table?.footerBg
    }
  };

  const tableProps: TableProps<T> = {
    title: () => (
      <TableTitle
        isAdmin={isAdmin}
        tableName={tableName}
        tableTitle={tableTitle ?? tableName}
        tableConfig={tableConfig}
        onConfirm={handleStatusUpdate}
        selectedRowsLength={rowSelection?.selectedRowKeys?.length}
        clearFilters={clearAll}
        cb={handleConfigChange}
        // onAction={showDrawer}
        // onOpen={showDrawer}
        onOpenConfig={() => setTableConfigOpen(true)}
        isFilterable={isFilterable}
        extraButtons={props.headerButtons}
        renderCounter={counter && (counter ?? props.dataSource?.length) }
      />
    ),
    ...(isSelectable ? {rowSelection} : undefined),
    locale: tableTranslations,
    size: state.tables?.[tableName]?.size,
    pagination,
    className: `ak-table ${props.className}`,
    rowClassName: state.tables?.[tableName]?.compactMode ? ' compact-row ' : '',
    ...props,
    columns: columns.filter(filterHidden).sort(columnOrder),
    onChange: handleTableChange,
  };

  return (
    <>

      <Table<T> {...tableProps} />

      <Drawer
        key='config'
        placement='right'
        size='default'
        closable={false}
        open={tableConfigOpen}
        onClose={closeConfigDrawer}
        title={
          <Space>
            <SettingTwoTone />
            {t('table.title.tableConfig')}
          </Space>
        }
        style={{ width: '100%', height: '100%' }}
        styles={{
          body: {
            position: 'relative',
            display: 'block',
            padding: 0,
            width: '100%'
          }
        }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Button
              shape='round'
              type='text'
              onClick={closeAll}
            >
              {t('buttons.close')}
            </Button>
            {/* <Button
              shape='round'
              type='link'
              onClick={clearAll}
            >
              {t('forms.btn.reset')}
            </Button> */}
          </Space>
        }
      >
        {/*
          Here starts menu for direct config updates
        */}
        <Menu
          mode='inline'
          items={tableActions}
        />
        
        {/*
          drawer for customizing columns order and visibility
        */}
        <Drawer
          key='columns'
          placement='right'
          closable={false}
          onClose={closeColumnDrawer}
          open={columnsOpen}
          title={
            <Flex gap={4} align='start'>
              <Button
                type='text'
                shape='circle'
                title={tableConfigOpen ? 'back' : 'exit'}
                icon={
                  tableConfigOpen
                  ? <LeftOutlined style={{ fontSize: '80%' }}/>
                  : <CloseOutlined style={{ fontSize: '80%' }}/>
                }
                onClick={closeColumnDrawer}
                style={{ marginLeft: '-1rem' }}
              />
              <Flex vertical>
                <Typography.Title level={4}>
                  {t('table.title.customizeColumns')}
                </Typography.Title>
                <Typography.Text type='secondary' style={{ fontSize: '.9em' }}>
                  {t('table.description.customizeColumns')}
                </Typography.Text>
              </Flex>
            </Flex>
          }
          footer={
            <Flex justify='space-between'>
              <Button
                shape='round'
                type='text'
                onClick={closeColumnDrawer}
              >
                {t('buttons.close')}
              </Button>
              <Button
                shape='round'
                type={newCustomColumns ? 'primary' : 'default'}
                onClick={handleSaveColumns}
              >
                {t('forms.btn.save')}
              </Button>
            </Flex>
          }
          style={{ width: '100%', height: '100%' }}
          styles={{
            body: {
              position: 'relative',
              display: 'block',
              padding: 0,
              width: '100%'
            }
          }}
        >
          <ColumnSelect
            rows={extraColumns}
            tableName={tableName}
            initialHiddenList={state.tables?.[tableName]?.hiddenColumns}
            callback={handleColumnOrderUpdate}
            isDrawerOpen={columnsOpen}
          />
        </Drawer>
            
      </Drawer>
      
    </>
  );
};

export default AppTable;
