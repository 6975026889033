import { ActionTypes, AllActions } from './';
import { ActionMap } from './types';


type ThemePayload = {
  [ActionTypes.Change_theme]: {
    theme: App.Themes;
  };
};

export type ThemeActions =
  ActionMap<ThemePayload>[keyof ActionMap<ThemePayload>];

export const themeReducer = (state: App.Themes, action: AllActions) => {
  switch (action.type) {
    case ActionTypes.Change_theme:
      if (typeof window !== 'undefined') {
        localStorage.setItem('theme', action.payload.theme);
      }
      return action.payload.theme;

    default:
      return state;
  }
};
