import { Col, Grid, Layout, Row, Space, theme, Typography } from 'antd';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import config from '../../config';
import { MobileLogo } from '../MobileLogo';
import { useTranslation } from 'react-i18next';

const {useBreakpoint} = Grid;

export const AppFooter: React.FC = () => {
  const { state } = React.useContext(ApplicationState);
  const { token } = theme.useToken();
  const screens = useBreakpoint();
  const {t} = useTranslation('info');

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const handleTestPrint = () => {
    console.log('loaded conf: ', config);
    console.log('current state: ', state);
  };

  const currentAppEnv = config.env !== 'production' ? config.env : '';

  return (
    <Layout.Footer className='site-footer' style={{
      position: 'relative',
      padding: 0,
      zIndex: 0,
      backgroundColor: token.colorBgElevated,
      boxShadow: 'inset 0px 10px 10px -10px rgba(0,0,0,0.23)',
    }}>
      <div className='footer-logo'style={{
        padding: '1rem',
        backgroundColor: token.colorBgElevated,
        boxShadow: 'inset 0px 10px 10px -10px rgba(0,0,0,0.23)',
      }}>
        <MobileLogo style={{
          fill: state.theme === 'dark' ? token.colorTextBase : '#2C5696',
          color: state.theme === 'dark' ? token.colorTextBase : '#2C5696'
        }}/>
      </div>
      
      <Space
        direction='vertical'
        className='app-footer'
        style={{
          width: '100%',
          // marginTop: '-5rem',
          padding: '5rem 1rem 1.4rem',
          // boxShadow: '0px 0px 1rem 0px rgba(0,0,0,0.23)',
          // backgroundColor: token.colorBgElevated,
 
          zIndex:2
        }}
      >

        <Row justify='center'>
          <Col>
            <Typography.Link
              className='underlineOnHover'
              href='https://www.ut.ee'
              target='_blank'
              rel="noreferrer"
              style={{ marginRight: 6 }}
            >
              {t('site.organization')}
            </Typography.Link>
            <Typography.Text>
              {t('site.name')}
            </Typography.Text>
          </Col>
        </Row>

        <Row justify='center'>

          <Col>
            <Typography.Text
              type='secondary'
              onClick={handleTestPrint}
              style={{ marginRight: 6 }}
            >
              {t('site.createdBy')}
            </Typography.Text>
            <Typography.Link
              className='underlineOnHover'
              href='https://hpc.ut.ee'
              target='_blank'
              rel="noreferrer"
            >
              UTHPC
            </Typography.Link>

            {` @ ${currentAppEnv} ${new Date().getFullYear()}`}
          </Col>
            

        </Row>
      </Space>

    </Layout.Footer>
  );
}
