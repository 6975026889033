import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { i18nConfig } from './config';
import { localTranslations } from "./i18nLocalTranslations";
import CustomI18nBackend from "./i18nBackend";

/**
 * i18next initialization and configuration
 * Sets up the translation system with:
 * - Custom backend for API translation loading
 * - Language detection
 * - React integration
 * - Local translations for immediate availability
 */
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(CustomI18nBackend)
    .init({
        resources: localTranslations,  // Local translations available immediately
        backend: {
            // loadPath: '',  // use this only for loading local translation files
            translationsEndpoint: `${i18nConfig.apiUrl}/api/${i18nConfig.apiVersion}/translations`,
        },
        lng: i18nConfig.defaultLanguage,
        fallbackLng: i18nConfig.fallbackLanguages,
        interpolation: {escapeValue: false},
        detection: {
            order: ['localStorage', 'sessionStorage', 'queryString', 'cookie', 'navigator'],
            caches: ['localStorage', 'sessionStorage', 'cookie'],
        },
        debug: i18nConfig.debug ?? false,
        saveMissing: i18nConfig.saveMissing ?? false,
        react: {
            useSuspense: true // This allows for async loading without Suspense
        },
        partialBundledLanguages: true, // This is key - tell i18next to load additional resources even though we have some
        load: 'all'
    });


export default i18n;
