import {
  AppstoreOutlined,
  BarsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Outlet, useLocation } from 'react-router-dom';
import { useNavigation } from '@App/settings/NavigationProvider';
import { Col, Grid, Row, Segmented } from 'antd';
import React from 'react';

import { handler } from '@App/settings/ApiHandler';
import { useKeycloak } from '@App/settings/keycloak';
import { useTranslation } from 'react-i18next';
import KC from "@App/@types/keycloakTypes";


type AccessDataLists = {
  users: Responses.UserType[];
  faculties: API.FacultyItem[];
  facultyOptions: API.FacultyItem[];
  groups: API.GroupType[];
  permissions: API.Permission[];
  facultiesGrouped?: Array<API.FacultyItem | API.FacultyGroupType>;
}

type DefaultRes = {success?: string, error?: string}
type AccessData = AccessDataLists & {
  isLoading: boolean;
  doRefresh: () => void;
  setLoading: (isLoading: boolean) => void;
  // post(path: string, payload: API.PayloadType): void;
  post: (path: string, payload: API.PayloadType) => Promise<unknown>;
  // post(path: string, payload: API.PayloadType): Promise<{data: {success?:string,error?:string}}>;
}

export const DataContext = React.createContext<AccessData>({
  users: [],
  faculties: [],
  facultyOptions: [],
  facultiesGrouped: [],
  groups: [],
  permissions: [],
  isLoading: false,
  doRefresh: () => {},
  setLoading: () => {},
  post: async (path: string, payload: API.PayloadType) => ({ data:{} }),
});


const getAccessData = (
  kc: KC.KCType,
  signal: AbortSignal
) => handler<AccessDataLists>({
  method: 'GET',
  path: '/v2/manage/access',
  signal
}, kc );

const postAccessData = (
  kc: KC.KCType,
  path: string,
  payload: API.PayloadType
) => handler<AccessDataLists>({
  method: 'POST', path, payload
}, kc );

type ActiveTabType = number | string | undefined;

export const AccessView: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState<ActiveTabType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<AccessDataLists>({
    users: [],
    groups: [],
    faculties: [],
    permissions: [],
    facultyOptions: [],
  });
  const {keycloak} = useKeycloak();
  const location = useLocation();
  const {md} = Grid.useBreakpoint();
  const { t } = useTranslation();

  const { handleNavigate: nav } = useNavigation();

  const post = (path:string, payload:API.PayloadType) => {
    const abortController = new AbortController();
    setIsLoading(true);
    
    return postAccessData(keycloak!, path, payload)
    .then((responseData) => {
      // setData(responseData);
      return responseData;
    })
    .catch((error) => {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error(error);
      }
      throw error; 
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const getData = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getAccessData(keycloak!, abortController.signal)
      .then((responseData) => {
        if (
          responseData['users'] &&
          responseData['groups'] &&
          responseData['faculties'] &&
          responseData['permissions']
        ) {  
          setData(responseData);
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      abortController.abort();
    };
  };
  
  React.useEffect(() => {
    const cleanup = getData();
    return () => {
      cleanup();
    };
  }, []);

  React.useEffect(() => {
    const newTab = location.pathname.split('/')[3] || 'users';
    setActiveTab(newTab);
  }, [location.pathname]);

  return (
    <Row align='middle' style={{ maxWidth: 1200, margin: '0 auto'  }}>
      <Col span={24}>
      {/* <DividerTitle
        title='Manage application'
        style={{ fontSize:'1.2rem', fontWeight:700 }}
      /> */}
        {/* <Typography.Text
          color='secondary'
          style={{
            margin: 0,
            marginRight: 8,
            fontSize: '.9em'
          }}
        >
          Manage
        </Typography.Text> */}
          
        <Segmented
          defaultValue={'users'}
          value={activeTab}
          onChange={(selected) => nav('/manage/access/' + selected)}
          options={[
            {
              label: md && t('links.manage.users'),
              value: 'users',
              icon: <UserOutlined />,
            },
            {
              label: md && t('links.manage.faculties'),
              value: 'faculties',
              icon: <AppstoreOutlined />,
            },
            {
              label: md && t('links.manage.groups'),
              value: 'groups',
              icon: <BarsOutlined />,
            },
            {
              label: md && t('links.manage.permissions'),
              value: 'permissions',
              icon: <BarsOutlined />,
            },
          ]}
        />

      <DataContext.Provider
        value={{
          isLoading,
          doRefresh: getData,
          setLoading: setIsLoading,
          post,
          ...data
        }}
      >

        <Outlet />
      
      </DataContext.Provider>

      </Col>
    </Row>
  );
};
