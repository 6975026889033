import {
  PlusOutlined,
  RightSquareOutlined,
  DownSquareFilled, SearchOutlined, UserDeleteOutlined, EyeOutlined, CheckOutlined, CloseOutlined,
} from '@ant-design/icons';
import {
  Button, Col, Divider, Dropdown, Input, Form, Modal, Row,
  Space, Typography, Menu, Checkbox, message, Empty, Tooltip, Tag, TableColumnsType, List
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import {DefaultOptionType} from "antd/es/select";
import { ColumnsType, ColumnType, SorterResult } from 'antd/es/table/interface';

import { useKeycloak } from '@App/settings/keycloak';
import { handler } from '@App/settings/ApiHandler';
import { ApplicationState } from '@App/settings/StateManager';
import { renderValue } from '@App/components/tables/TableHelpers';
import { ResearchExpandRowContent } from '@App/components/tables/ResearchExpandableRow';
import {formatDateTime, formatDateValue} from '@App/components/helpers';
import AppTable from '@App/components/tables/TableComponent';
import DynamicFormModal from '@App/components/forms/DynamicFormModal';
import PageWrapper from '@App/components/wrappers/PageWrapper';
import UserSearchModalPublic, {LdapUserInfo} from "@App/components/UserSearchModalPublic";
import KC from "@App/@types/keycloakTypes";
import {wrapWithTooltip} from "@App/components/wrapWithTooltip";
import {useNavigation} from "@App/settings/NavigationProvider";
import { Language } from '@AppRoot';


const tableName = 'researchProjectTable';

type Res = {
  success?: string;
  error?: string;
  faculties: Responses.FacultyType[] | API.FacultyItem[];
  forms: Form.FormType[];
  // projects: Responses.ProjectData[];
  sharedItems: Responses.ResearchData[];
  shared: Responses.ResearchData[];
  myItems: Responses.ResearchData[];
  items: Responses.ResearchData[];
  all?: Responses.ResearchData[];
  allItems?: Responses.ResearchData[];
}

const getTtrData = (kc: KC.KCType) => handler<Res>({
  method: 'GET',
  path: '/v3/researchProjects/',
}, kc!)

const postTTRData = (
  kc: KC.KCType,
  payload: API.PayloadType,
  path: string = '/v1/researchProject/',
) => handler<{success?: string, error?: string }>({
  method: 'PUT', path, payload
}, kc! );

// type ItemType = 'myData' | 'sharedData' | 'publicData' | undefined;
type ItemType = 'all' | 'my' | 'shared' | 'public';
const tagsData: ItemType[] = ['all', 'my', 'shared'];

export const UserResearches: React.FC = () => {
  const [ action, setAction ] = React.useState<API.ActionType>('new');
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
  const [ modalOpen, setModalOpen ] = React.useState<boolean>(false);
  const [ hiddenColumns, setHiddenColumns] = React.useState<string[]>([])
  const [search, setSearch] = React.useState<string | undefined>(undefined);
  const [searchedData, setSearchedData] = React.useState<Responses.ResearchData[]|undefined>(undefined);
  
  const [ fFields, setFFields ] = React.useState<Form.FormType['fields']|API.Form['fields']>([]);
  const [researchData, setResearchData] = React.useState<Responses.ResearchData[]>([]);
  const [myData, setMyData] = React.useState<Responses.ResearchData[]>([]);
  const [sharedData, setSharedData] = React.useState<Responses.ResearchData[]>([]);
  const [faculties, setFaculties] = React.useState<Array<Responses.FacultyType|API.FacultyItem>>([]);
  const [formsData, setFormsData] = React.useState<Array<Form.FormType|API.Form>>([]);
  
  const selectedForm = React.useRef<Form.FormType>();
  const selectedItem = React.useRef<Responses.ResearchData>();
  
  const { keycloak } = useKeycloak();
  const [ form ] = Form.useForm();
  const { i18n, t } = useTranslation();
  const { handleNavigate: nav } = useNavigation();
  const [resultSize, setResultSize] = React.useState<number>(0);
  const [visibleColumns, setVisibleColumns] = React.useState<Array<string>>([]);
  const [lockedColumns, setLockedColumns] = React.useState<{[key:string]: 'left'|'right'|undefined}>({});

  const [sortedInfo, setSortedInfo] = React.useState<SorterResult<Responses.ResearchData>>({});

  const [showItems, setShowItems] = React.useState<ItemType>('all');
  const [showSharedItems, setShowSharedItems] = React.useState<boolean>(false);
  const [showLdapUserSearch, setShowLdapUserSearch] = React.useState<boolean>(false);
  const [sharedWithUsers, setSharedWithUsers] = React.useState<API.UserMeta[]>([]);
  const [selectedTags, setSelectedTags] = React.useState<string[]>(['all']);

  const {state} = React.useContext(ApplicationState);
  
  const compactMode = state.tables?.[tableName]?.compactMode;

  const get = () => {
    const abortController = new AbortController();
    setIsLoading(true);

    getTtrData(keycloak!)
      .then((data) => {
        if (data.forms) {
          setFormsData(data.forms);
        }
       
        if (data.items || data.allItems) {
          setResearchData(data.items || data.allItems);
        }

        if (data.myItems) {
          setMyData(data.myItems);
        }

        if (data.sharedItems) {
          setSharedData(data.sharedItems);
        }

      })
      .finally(() => {
        setIsLoading(false);
      });
    
    return () => {
      abortController.abort();
    };
  };

  const handleCancel = () => {
    setModalOpen(false);
  };
  
  const handleDataUpdate = (item: Responses.ResearchData) => {
    const selectedDataValues = researchData.find(i => i.uuid == item.uuid);
   
    if (selectedDataValues) {
      
      selectedItem.current = selectedDataValues;
      if (Object.keys(selectedDataValues.form_data).some(i => i.includes('duration'))) {
        let rangeValue: [Dayjs|string|null, Dayjs|string|null] = [null, null]
        const val = selectedDataValues.form_data['research_duration'];
        if (typeof val == 'string') {
          rangeValue = [null, null]  
        }
        else if (Array.isArray(val)) {
          rangeValue = [dayjs(val[0]), dayjs(val[1])]  
        }
        selectedItem.current = {
          ...selectedDataValues,
          form_data: {
            ...selectedDataValues.form_data,
            // @ts-ignore
            research_duration: rangeValue
          }
        };
      }
      setSharedWithUsers(selectedDataValues.shared_with_users);
      // form.setFieldsValue(selectedDataValues.form_data);
      // Object.entries(selectedDataValues.form_data).map(
      //   ([fieldKey, v], idx)=> {
      //     if (fieldKey.includes('duration')) {
      //       console.log('set time', fieldKey, v)
      //       form.setFieldValue(fieldKey, v)
      //       // const val = selectedDataValues.form_data['research_duration'];
      //       // selectedDataValues.form_data['research_duration'] = [dayjs(val[0]), dayjs(val[1])]
      //     }
      //     else if (Array.isArray(v) && typeof v[0] == 'object') {
      //       let values: string[] = []
      //       // console.log(fieldKey, v)
      //       // values.push
      //       v.map((selectValue) => Object.entries(selectValue).map(a=>values.push(a[0]) ))
      //       form.setFieldValue(fieldKey, values)
      //     }
      //     else {
      //       form.setFieldValue(fieldKey, v)
      //     }

      //   })
      // form.setFieldValue('faculty_id', selectedDataValues.faculty_id);
      // form.setFieldValue('faculty_code', selectedDataValues.faculty_code);
      setAction('update');
      setModalOpen(true);
    }
  };

  const handleDataDelete = (e?: any) => {
    // console.log(e);
    message.success(t(e.success))
    setAction('delete');
    setModalOpen(false);
    get();
  };

  const handlePost = (values: object) => {
    setIsLoading(true);
    // const formPayload = form.getFieldsValue();
    // console.log(formPayload);
    // debugger
    const payload = {
      // form: selectedForm.current,
      form_id: selectedForm.current?.id,
      form_uuid: selectedForm.current?.uuid ?? selectedItem.current?.form_uid,
      form_slug: selectedForm.current?.slug ?? selectedItem.current?.form_uid,
      uuid: selectedItem.current?.uuid,
      form_data: values,
      sharedWith: sharedWithUsers
    }
    // console.log(payload)
    postTTRData( keycloak!, {
      action, payload
    }, `/v3/researchProjects/${selectedItem.current?.uuid}`)
      .then((res) => {
        // console.log(res)
        if (res.success) {
          message.success(
              t('forms.success.'+res.success))
        }
        get();
        setModalOpen(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // form.validateFields().then(() => {

    // })
    // .catch((err)=> {
    //   if (err.errorFields) {
    //     message.error(t('forms.error.missingFields'))
    //   }
    // })
    // .finally(()=> {
    //   setIsLoading(false)
    // })
    
  };


  React.useEffect(() => {
    const cleanup = get();
    
    return () => {
      cleanup();
    };
  }, []);


  React.useEffect(() => {
    if (formsData.length > 0) {
      setFFields(formsData[0].fields);
    }
  }, [formsData]);


  const formatColumns = (list: Array<API.Field>): Array<ColumnType<Responses.ResearchData>> => list
      // .filter(i => i.label !== '')
      // .sort((a, b) => a.order - b.order)
      .map((field) => {
    // if (field.label == '' || field.settings.dependencies) {
    //   console.log(field);
    // }
    const columnTitle = t(`table.column.label.${field.name}`,
      field.settings?.labelTexts?.[i18n.language as Language] ?? field.settings.placeholderTexts?.[i18n.language as Language] ?? t(`forms.label.${field.name}`, field.name)
    )

    let filterProps: ColumnType<Responses.ResearchData> = {
      key: field.name,
      title: columnTitle,
      ellipsis: compactMode,
      dataIndex: ['form_data', field.name],
      width: columnTitle.length > 20 ? 340 : 140,
      fixed: lockedColumns[field.name],
    };

    if (['date', 'date-time', 'date-range', 'date-range-time'].includes(field.type)) {
      return {
        ...filterProps,
        // filtered: visibleColumns?.includes(field.name) ?? true,
        render: (value) => value ? formatDateValue(value ?? '') : value,
      };
    }
    if (field.settings.options?.length) {
      filterProps.filterMultiple = true;
      filterProps.filters = field.settings.options.map((v,vIdx) => ({
        key: vIdx, text: v.label[i18n.language as Language], value: v.value
      }));
      filterProps.filterMultiple = true;
      filterProps.filterMode = 'tree';
      filterProps.onFilter = (value, record) => {
        if (record.form_data && field.name in record.form_data) {
          const item = record.form_data[field.name];
          if (item === null || item === undefined) return false;
          
          else if (typeof item == "string") {
            return item == value+'' || item.includes(value+'');
          }
          else if (Array.isArray(item)) {
            return item.includes(value+'') || item.some(v=> v.includes(value+''));
          }
          console.info('no filter match')
          // return record.form_data[field.name].includes(value+'');
        }
        return false;
      }
    }
    
    return {
      // filtered: visibleColumns?.includes(field.name) ?? true,
      ...filterProps,
      render: (value) => {
        if (field.settings.dependencies) {
          const parentFieldNames = field.settings.dependencies.dependsOn;
        }
        return renderValue(value, `${compactMode ? 'is' : 'non'}-compact`, compactMode, field, i18n.language as Language);
      },
    };
  });

  const columns = React.useMemo(() => {
    const firstColumns: ColumnsType<Responses.ResearchData> = [
      {
        key: 'uid',
        // title: t('table.column.label.isFilled', 'filled'),
        title: t('table.column.label.isFilled', 'filled'),
        dataIndex: 'form_filled',
        // fixed: 'left',
        width: 100,
        align: 'center',
        render: (isFilled) => (
          <Typography.Text type={isFilled ? 'success':'danger'}>
            {isFilled ? <CheckOutlined /> : <CloseOutlined />}
          </Typography.Text>
        )
      },
      {
        key: 'by_user',
        dataIndex: 'created_by',
        title: t('table.column.createdBy'),
        width: 140,
        hidden: showItems === 'my',
        // sortOrder: sortedInfo.columnKey === 'form_filled' ? sortedInfo.order : null,
        sorter: {
          compare: (a, b) => a.created_by.localeCompare(b.created_by),
        },
        // render: (isFilled) => isFilled ? <CheckOutlined /> : <CloseOutlined />,
      },
    ];

    const otherColumns: ColumnsType<Responses.ResearchData> = [
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: t('table.column.created'),
        width: 140,
        fixed: lockedColumns.created_at,
        sortOrder: sortedInfo.columnKey === 'created_at' ? sortedInfo.order : null,
        sorter: {
          multiple: 1,
          compare: (a, b) => a.created_at - b.created_at
        },
        render: (createdAt: number) => formatDateTime(createdAt, true),
      },
      {
        key: 'updated_at',
        dataIndex: 'updated_at',
        title: t('table.column.lastUpdated'),
        width: 140,
        fixed: lockedColumns.updated_at,
        sortOrder: sortedInfo.columnKey === 'updated_at' ? sortedInfo.order : null,
        sorter: {
          multiple: 2,
          compare: (a, b) => (a.updated_at ?? 1) - (b.updated_at ?? 1),
        },
        render: (updatedAt: number|null) => formatDateTime(updatedAt, true)
      },
      // {
      //   key: 'actions',
      //   title: t('table.column.action'),
      //   // dataIndex: '',
      //   align: 'center',
      //   width: 100,
      //   sorter: {
      //     compare: (a, b) => a.version - b.version,
      //     multiple: 3,
      //   },
      // }

    ];

    const extraColumns = formatColumns(fFields)

    return [
      ...firstColumns,
      ...extraColumns,
      ...otherColumns
    ];
  }, [fFields, showItems, visibleColumns, compactMode, lockedColumns, i18n.language]);

  // const columns = React.useMemo(() => {
  //   const firstColumns: ColumnsType<Responses.TTRData> = [
  //     {
  //       key: 'form_filled',
  //       title:
  //         <Typography.Text
  //           // type={isFilled ? 'success':'danger'}
  //           style={{
  //             position: 'absolute',
  //             color: 'inherit',
  //             whiteSpace: 'nowrap',
  //             right: 6,
  //             top: 'calc(50% - 0.8rem)',
  //           }}
  //         >
  //           {t('table.column.label.isFilled', 'filled')}
  //         </Typography.Text>
  //       ,
  //       dataIndex: 'form_filled',
  //       // fixed: 'left',
  //       width: 80,
  //       align: 'center',
  //       render: (isFilled) => (
  //         <Typography.Text type={isFilled ? 'success':'danger'}>
  //           {isFilled ? <CheckOutlined /> : <CloseOutlined />}
  //         </Typography.Text>
  //       )
  //     },
  //     {
  //       key: 'created_by',
  //       dataIndex: 'created_by',
  //       title: t('table.column.createdBy'),
  //       width: 140,
  //       hidden: showItems === 'my',
  //       // sortOrder: sortedInfo.columnKey === 'form_filled' ? sortedInfo.order : null,
  //       // sorter: {
  //       //   compare: (a, b) =>
  //       //     a.form_filled < b.form_filled,
  //       // },
  //       // render: (isFilled) => isFilled ? <CheckOutlined /> : <CloseOutlined />,
  //     },
  //     /*{
  //       key: 'faculty_code',
  //       title: t('table.column.label.faculty'),
  //       dataIndex: 'faculty_code',
  //       filterSearch: true,
  //       filterMultiple: true,
  //       filters: faculties.map(faculty => ({
  //         key: faculty.code,
  //         text: `${faculty.code} ${faculty.labels[i18n.language as 'et'|'en']} `,
  //         value: faculty.code,
  //       })),
  //       onFilter: (value, record) => record.faculty_code == value,
  //       sorter: {
  //         compare: (a, b) => a.faculty_code.localeCompare(b.faculty_code),
  //       },
  //       width: 150,
  //       render: (fCode: number|string, record) => {
  //         const faculty = faculties.find((f) => f.code == fCode);
  //         return (
  //           <Tooltip title={fCode}>
  //             {faculty?.labels?.[i18n.language as 'en'|'et'] ?? fCode}
  //           </Tooltip>
  //         );
  //       }
  //     },
  //     {
  //       key: 'art30_eesmark',
  //       title: t('table.column.label.art30_eesmark'),
  //       dataIndex: ['form_data', 'art30_eesmark'],
  //       ellipsis: compactMode,
  //       sorter: {
  //         compare: (a, b) => {
  //           let valueA = a.form_data['art30_eesmark'];
  //           let valueB = b.form_data['art30_eesmark'];
  //
  //           if (Array.isArray(valueA)) {
  //             valueA = valueA.join(', ');
  //           }
  //           if (Array.isArray(valueB)) {
  //             valueB = valueB.join(', ');
  //           }
  //           return valueA.trim().localeCompare(valueB.trim());
  //         },
  //       },
  //       filterSearch: true,
  //       filterMultiple: true,
  //       defaultSortOrder: 'ascend',
  //       onFilter: (value, record) => record.faculty_code == value,
  //       width: 220,
  //       render: (fCode: number|string, record) => {
  //         const faculty = faculties.find(f => f.code == fCode);
  //         return (
  //           <Tooltip title={fCode}>
  //             {faculty ? faculty.labels[i18n.language as 'en'|'et'] : fCode }
  //           </Tooltip>
  //         );
  //       }
  //     },*/
  //   ];
  //
  //   const lastColumns: ColumnsType<Responses.TTRData> = [
  //     {
  //       key: 'created_at',
  //       dataIndex: 'created_at',
  //       title: t('table.column.created'),
  //       align: 'center',
  //       width: 100,
  //       sorter: {
  //         compare: (a, b) => a.created_at - b.created_at,
  //       },
  //       render: (createdAt) => (
  //         <Tag bordered={false} title={formatDateTime(createdAt, true,  'DD.MM.YYYY - hh:mm Z')}>
  //           {formatDateTime(createdAt, true,  'DD.MM.YYYY')}
  //         </Tag>
  //       ),
  //     },
  //     {
  //       key: 'updated_at',
  //       dataIndex: 'updated_at',
  //       title: t('table.column.lastUpdated'),
  //       align: 'center',
  //       width: 130,
  //       sorter: {
  //         compare: (a, b) => (a.updated_at ?? 0) - (b.updated_at ?? 0),
  //       },
  //       render: (updatedAt: number | null) => updatedAt ? (
  //         <Tag bordered={false} title={formatDateTime(updatedAt, true,  'DD.MM.YYYY - hh:mm Z')}>
  //           {formatDateTime(updatedAt, true,  'DD.MM.YYYY')}
  //         </Tag>
  //       ) : '-'
  //     },
  //     {
  //       key: 'status',
  //       dataIndex: 'status',
  //       align: 'center',
  //       width: 100,
  //       title: t('table.column.label.status'),
  //       filters: statusItems.map((i, idx) => ({ key: idx, text: i.label, value: i?.key })),
  //       onFilter: (value, record) => record.status == value,
  //       render: (status) => {
  //         const color =
  //           status === 'draft' ? 'gold'
  //           : status === 'active' ? 'green'
  //           : status === 'confirm' ? 'green'
  //           : status === 'deleted' ? 'red'
  //           : 'cyan';
  //         return (
  //           <Tag color={color}>
  //             {typeof status == 'string'
  //               ? t(`settings.status.${status}`, status.toUpperCase())
  //               : '-'}
  //           </Tag>
  //         );
  //       },
  //     },
  //   ];
  //
  //   // const processFields = (fields: Form.FieldType[], compactMode: boolean) => {
  //   //   const sortedList = fields.sort((a, b) => (a.order ?? 99) - (b.order ?? 99))
  //   //   return sortedList.reduce<ColumnsType<Responses.TTRData>>((acc, field) => {
  //   //     const column: ColumnType<Responses.TTRData> = {
  //   //       key: field.name,
  //   //       title: `${field.label}  ${field.order}`,
  //   //       ellipsis: compactMode,
  //   //       dataIndex: ['form_data', field.name],
  //   //       width: 200,
  //   //       sorter: undefined,
  //   //     };
  //   //
  //   //     if (['date', 'date-time', 'date-range', 'date-range-time'].includes(field.type)) {
  //   //       column.render = (value) => formatDateValue(value);
  //   //     }
  //   //
  //   //     if (field.settings.options?.length) {
  //   //       column.filterMode = 'tree';
  //   //       column.filterMultiple = true;
  //   //       column.filters = field.settings.options.map((v, vIdx) => ({
  //   //         key: vIdx,
  //   //         text: v.label,
  //   //         value: v.value ?? v.label
  //   //       }));
  //   //       column.onFilter = (value, record) => {
  //   //         if (record.form_data && field.name in record.form_data) {
  //   //           const item = record.form_data[field.name];
  //   //           if (item === null || item === undefined) return false;
  //   //           else if (typeof item == "string") return item == value + '' || item.includes(value + '');
  //   //           else if (Array.isArray(item)) return item.includes(value + '') || item.some(v => v.includes(value + ''));
  //   //         }
  //   //         return false;
  //   //       };
  //   //     }
  //   //
  //   //     if (field.settings.dependencies) {
  //   //       const parentFieldNames = field.settings.dependencies.dependsOn;
  //   //       const parentColumn = acc.find(col => parentFieldNames.includes(col.key as string));
  //   //       if (parentColumn) {
  //   //         parentColumn.children = parentColumn.children || [];
  //   //         parentColumn.children.push(column);
  //   //       } else {
  //   //         acc.push(column);
  //   //       }
  //   //     } else {
  //   //       acc.push(column);
  //   //     }
  //   //
  //   //     return acc;
  //   //   }, []);
  //   // };
  //
  //   const processFields = (fields: Form.FieldType[], compactMode: boolean) => {
  //     // Sort the fields based on their 'order'
  //     const sortedList = fields.sort((a, b) => (Number(a.order) ?? 99) - (Number(b.order) ?? 99));
  //     console.log(sortedList)
  //     const buildColumn = (field: Form.FieldType): ColumnType<Responses.TTRData> => {
  //       const column: ColumnType<Responses.TTRData> = {
  //         key: field.name,
  //         title: `${field.label}  ${field.order}`,
  //         ellipsis: compactMode,
  //         dataIndex: ['form_data', field.name],
  //         width: 260,
  //         // sorter: undefined,
  //       };
  //
  //       if (['date', 'date-time', 'date-range', 'date-range-time'].includes(field.type)) {
  //         column.render = (value) => formatDateValue(value);
  //       }
  //
  //       if (field.settings.options?.length) {
  //         column.filterMode = 'tree';
  //         column.filterMultiple = true;
  //         column.filters = field.settings.options.map((v, vIdx) => ({
  //           key: vIdx,
  //           text: v.label,
  //           value: v.value ?? v.label,
  //         }));
  //         column.onFilter = (value, record) => {
  //           if (record.form_data && field.name in record.form_data) {
  //             const item = record.form_data[field.name];
  //             if (item === null || item === undefined) return false;
  //             else if (typeof item === 'string') return item === value || item.includes(value);
  //             else if (Array.isArray(item)) return item.includes(value) || item.some((v) => v.includes(value));
  //           }
  //           return false;
  //         };
  //       }
  //
  //       return column;
  //     };
  //
  //     const cols: ColumnType<Responses.TTRData>[] = [];
  //
  //     sortedList.forEach((field) => {
  //       const column = buildColumn(field);
  //
  //       if (field.settings.dependencies) {
  //         // If there are dependencies, we group them side by side.
  //         const parentFieldNames = field.settings.dependencies.dependsOn;
  //         const siblingColumns = cols.filter((col) => parentFieldNames.includes(col.key as string));
  //
  //         if (siblingColumns.length) {
  //           siblingColumns.forEach((siblingColumn) => {
  //             // Merge the columns side by side (this just adds them after each other, no nesting)
  //             const siblingIndex = cols.indexOf(siblingColumn);
  //             cols.splice(siblingIndex + 1, 0, column); // Insert after the sibling column
  //           });
  //         } else {
  //           cols.push(column); // If no matching parent field, just add it normally
  //         }
  //       } else {
  //         cols.push(column); // For non-dependent fields, simply add to the list
  //       }
  //     });
  //
  //     return cols;
  //   };
  //
  //
  //   const extraColumns = processFields(fFields.sort((a, b) => (a.order ?? 99) - (b.order ?? 99)), compactMode);
  //
  //   const groupedColumns: TableColumnsType<Responses.TTRData> = [
  //     ...firstColumns,
  //     ...extraColumns,
  //     ...lastColumns,
  //   ];
  //
  //   return groupedColumns;
  // }, [fFields, t, compactMode, showItems]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setSearch(e.target.value);
  };

  React.useEffect(() => {
    if (!search) {
      // if (shearedData && shearedData.length) {
      //   setSearchedData([...researchData, ...shearedData]);
      // }
      // else {
      //   setSearchedData(researchData);
      // }
      if (showItems === 'all' && researchData.length) {
        setSearchedData(researchData);
      }
      else if (showItems === 'my' && myData && myData.length) {
        setSearchedData(myData);
      }
      else if (showItems === 'shared' && sharedData.length) {
        setSearchedData(sharedData);
      }
      else if (showSharedItems && sharedData && sharedData.length) {
        setSearchedData(sharedData);
      }
      else {
        setSearchedData(researchData);
      }
    }
    else {
      const newFilteredList = [...myData].filter(
        (d) => JSON.stringify(d.form_data).toLowerCase().includes(search.toLowerCase())
      );
      setSearchedData(newFilteredList);
    }
  }, [search, researchData, showItems, showSharedItems]);

  const projectsOptionItems: DefaultOptionType[] = React.useMemo(() => {
    return [{key: 'opt.test', name: 'test'}]?.map( ({key, name}) => ({
      value: key,
      search: `${key} ${name}`,
      label: t(key, name)
    })
    ) ?? []
  }, [t]);

  const handleSharedUserSelection = (user: API.UserMeta) => {
    const userExists = sharedWithUsers.find(u => u.username === user.username);
    console.log(user, userExists)
    if (userExists) {
      message.info('Kasutaja juba valitud');
    } else {
      setSharedWithUsers((prev) => [...prev, user])
    }
  };

  const handleSharedUserRemove = (index: number) => {
    setSharedWithUsers((prev) => {
      return prev.filter((_, i) => i !== index);
    })
  };

  const handleTagChange = (tag: ItemType, checked: boolean) => {
    setShowItems(tag);
  };

  return (
    <>
      <PageWrapper>
        <Row style={{marginBottom: 0}}>
          <Col span={24}>
            <Typography.Title level={2}>
              { t(`links.researchProjects`) }
            </Typography.Title>
          </Col>
        </Row>
        <Row justify='space-between'>
          <Col>
            <Input.Search
              allowClear
              placeholder={t("forms.placeholders.search")}
              value={search}
              onSearch={handleSearch}
              onChange={handleSearchChange}
              // prefix={<SearchOutlined />}
              />
          </Col>
          <Col>
            <Button
              type='link'
              shape='round'
              className='btn-border'
              onClick={() => nav('/forms')}
            >
              {t('pages.nav.forms')}
            </Button>
          </Col>
        </Row>
      </PageWrapper>

          
      <AppTable<Responses.ResearchData>
        rowKey='uuid'
        tableName={tableName}
        tableTitle='table.title.projects.research'
        loading={isLoading}
        dataSource={searchedData}
        headerButtons={
          tagsData.map<React.ReactNode>((tag) => (
            <Tag.CheckableTag
              key={tag}
              checked={showItems === tag}
              onChange={(checked) => setShowItems(tag)}
              style={{ borderRadius: 40 }}
            >
              {t(`buttons.show${tag[0].toUpperCase()}${tag.substring(1, tag.length)}`)}
              {
                tag == 'all' ? researchData.length > 0 && ` (${researchData.length})`
                : tag == 'my' ? myData.length > 0 && ` (${myData.length})`
                : tag == 'shared' ? sharedData.length > 0 && ` (${sharedData.length})`
                : null
              }
            </Tag.CheckableTag>
          ))
        }
        extraColumns={columns}
        sticky={{ offsetHeader: 64 }}
        scroll={{ x: 1024 }}
        rowClassName={compactMode ? ' compact-row ' : ''}
        expandable={{
          rowExpandable: (record) => record.form_data !== null && Boolean(Object.keys(record.form_data).length),
          expandedRowRender: (record) => (
            <ResearchExpandRowContent
              key={record.uuid}
              record={record}
              onEdit={handleDataUpdate}
              // onAdminEdit={handleEntityChange}
              formFields={fFields}
              isAdmin={false}
            />
          ),
          // expandedRowClassName: compactMode? ' compact ' : 'non-compact',
          expandIcon: ({ expanded, onExpand, record }) => expanded
            ? <DownSquareFilled onClick={(e) => onExpand(record, e)} style={{ fontSize: '1.4rem' }} />
            : <RightSquareOutlined onClick={(e) => onExpand(record, e)} style={{ fontSize: '1.4rem' }} />
        }}
        // onChange={handleChange}
        // onConfigChange={console.log}
        style={{ width: '100%' }}
        isAdmin={false}
        isSelectable={false}
        customEmptyText={(
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            // image={emptyImg}
            imageStyle={{ height: 60 }}
            description={t('table.texts.emptyText')}
          >
            <Button
              shape='round'
              // type="primary"
              icon={<PlusOutlined />}
              onClick={() => nav('/forms/researchProject')}
            >
            {t('links.researchProjects.add')}
            </Button>
          </Empty>
        )}
      />

      <Modal
        title={t(`forms.titles.${action}Article30`)}
        open={modalOpen}
        onOk={handlePost}
        okText={t(`forms.btn.${action === 'new' ? 'create' : 'update'}`)}
        cancelText={t('forms.btn.cancel')}
        confirmLoading={isLoading}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Typography>
          {selectedForm.current?.description}
        </Typography>
        
        <Divider />
        <DynamicFormModal
          formData={formsData[0] ?? {fields:[]}}
          initialValues={selectedItem.current}
          options={undefined}
          isAdmin={false}     
          onFinish={handlePost} 
          onCancel={handleCancel}
          facultyOptions={faculties}
          action={action}
          onDelete={handleDataDelete}
          //@ts-ignore
          projectOptions={projectsOptionItems}
          extra={
            <>
              <Button
                  shape={'round'}
                  type={'default'}
                  icon={<SearchOutlined />}
                  onClick={() => setShowLdapUserSearch(true)}
                  style={{ margin: '0 1rem 1rem' }}
              >
                {t('forms.btn.searchUtUser', 'UT kasutaja otsing')}
              </Button>
              { sharedWithUsers.length > 0 ? (
                  <List<LdapUserInfo>
                      itemLayout="horizontal"
                      size={'small'}
                      dataSource={sharedWithUsers}
                      renderItem={(user, userIdx) => {
                        // const isExistingUser = userExists(user.username);
                        const isExistingUser = false;
                        return (
                            <List.Item
                                extra={
                                  <Button
                                      shape={'round'}
                                      size={'small'}
                                      type={'dashed' }
                                      icon={<UserDeleteOutlined />}
                                      className={'user-action-button'}
                                      onClick={() => handleSharedUserRemove(userIdx)}
                                  >
                                    {t(`forms.btn.remove`)}
                                  </Button>
                                }
                            >
                              <List.Item.Meta
                                  title={
                                    <Space>
                                      {//@ts-ignore
                                        `${user.firstName || user.firstname} ${user.lastName || user.lastname}`}
                                      <Tag color="processing" bordered={false}>{user.email}</Tag>
                                    </Space>
                                  }
                              />
                            </List.Item>
                        );
                      }}
                  />
              ) : null}
            </>
          }
        />

      </Modal>

      <UserSearchModalPublic
          visible={showLdapUserSearch}
          onClose={() => setShowLdapUserSearch(false)}
          //@ts-ignore
          onSelectUser={handleSharedUserSelection}
          existingUsers={sharedWithUsers}
      />

    </>
  );
};
