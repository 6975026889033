import React from 'react';
import ReactDOM from 'react-dom/client';

import { Application } from "./App";
import { StateManager } from "./settings/StateManager";
import KeycloakProvider from "./settings/keycloak/KeycloakProvider";

import "./i18n";

import './assets/css/fonts.css';
import './assets/css/index.less';

ReactDOM.createRoot(document.getElementById('root')! as HTMLElement).render(
    <KeycloakProvider>
        <StateManager>
            <Application />
        </StateManager>
    </KeycloakProvider>
);
