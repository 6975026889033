import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import React from 'react';

import Layouts from '@App/components/layouts';
import View from '@App/views/index';

import { permissions } from './navigation';
import * as Api from './ApiActions';

export const uiRouter = createBrowserRouter([
  {
    // pages and paths to render that are meant for all users
    path: "/",
    element: <Layouts.LayoutWithSidebar />,
    errorElement: <Layouts.LayoutWithSidebar><View.ErrorPage /></Layouts.LayoutWithSidebar>,
    children: [
      { index: true, element: <View.LandingPage /> },
      // { path: "search", element: <View.SearchPage /> },
      // { path: "quiz", element: <View.TestKnowledge /> },
      // { path: "kkk", element: <View.FAQ /> },
      // { path: "report", element: <View.ReportPage /> },
      { path: "profile", element: <View.ProfilePage /> },
      { path: "maintenance", element: <View.MaintenancePage /> },
      {
        path: "forms",
        element: <View.FormsView />,
        errorElement: <View.FormsErrorPage />,
        // loader: fetchAllForms,
        children: [
          {
            index: true,
            element: <View.FormsPage />,
            loader: Api.fetchAllForms,
          },
          {
            path: ":formIdentifier",
            element: <View.SingleFormPage />,
            loader: Api.fetchForm
          },
        ],
      },
      { path: "researchProjects", element: <View.UserResearches /> },
      { path: "oppetoo", element: <View.UserTTR /> },
      {
        path: "unauthorized",
        element: <View.UnauthorizedPage errorCode={401} />,
        errorElement: <View.ErrorPage />,
      },
      {
        // pages and paths to render that are meant for application admins users
        path: "manage",
        // element: <Layouts.Protected roles={permissions.admin} />,
        // element: <Layouts.LayoutWithSidebar />,
        errorElement: <View.ErrorPage />,
        children: [
          { index: true, element: <View.ManagePage /> },
          { path: "translations", element: <View.TranslationPage /> },
          {
            path: "ttr",
            element: <View.AdminFormView />,
            children: [
              { index: true, path: "forms", element: <View.FormsList /> },
              { path: 'projects', element: <View.AdminProjects /> },
              { path: 'researchProjects', element: <View.AdminResearchProjects /> },
              { path: 'art30Projects', element: <View.AdminArt30Projects /> },
              { path: 'oppetoo', element: <View.AdminArt30Projects /> },
            ],
          },
          {
            path: "forms",
            element: <View.AdminFormView />,
            errorElement: <View.FormsErrorPage />,
            children: [
              { index: true, element: <View.FormBuilder /> },
              { path: "fields", element: <View.FieldsList /> },
              { path: "fields/new", element: <View.ManageFieldPage /> },
              { path: "fields/:fieldItem",
                element: <View.ManageFieldPage />,
                loader: Api.fetchField
              },
              { path: "builder/new", element: <View.ManageFormPage /> },
              { path: "new", element: <View.ManageFormPage /> },
              { path: "builder/:identifier", element: <View.ManageFormPage /> },
              { path: 'builder', element: <View.FormBuilder /> },
            ],
          },
          {
            path: "access",
            element: <View.AccessView />,
            children: [
              { path: "permissions/:id", element: <View.Permission /> },
              { path: "permissions", element: <View.PermissionsList /> },
              { path: "groups/:id", element: <View.GroupPage /> },
              { path: "groups", element: <View.GroupList /> },
              
              { path: "faculties/:code", element: <View.FacultyFormPage />, loader: Api.fetchFaculty },
              { path: "faculties", element: <View.FacultiesList /> },
              
              {
                path: "users/:uuid",
                element: <View.UserFormPage />,
                loader: Api.fetchUsers,
              },
              { path: "users", element: <View.UsersList /> },
              { index: true, element: <View.UsersList /> },
            ],
          },
        ],
      },
    ],
  },
]);

export default function UIRoutes() {
  return <RouterProvider router={uiRouter} />;
}
