import { Button, MenuProps, Tooltip } from 'antd';
import { FloatButton, Layout, Space } from 'antd';
import { LoginOutlined, LogoutOutlined, UserOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { MobileDrawer } from './MobileDrawer';
import { useKeycloak } from '@App/settings/keycloak';
import { ActionTypes } from '@App/settings/reducers';
import { Theme } from '@AppRoot';
import { useLocation } from 'react-router-dom';
import useTheme from '@App/settings/useTheme';


type MenuItem = Required<MenuProps>['items'][number];

interface IHeaderProps {
  navItems?: MenuItem[];
  children?: React.ReactNode
}


export const WhiteHeader: React.FC<IHeaderProps> = (props) => {
  const { state, dispatch } = React.useContext(ApplicationState);
  const {t} = useTranslation();
  const { keycloak, userData } = useKeycloak();
  const {isDark, setTheme} = useTheme();
  const location = useLocation();

  const headerWrapperStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    // justifyItems: 'flex-start',
    width: '100%',
    // height: screens.sm ? '1rem' : 64,
    background: location.pathname === '/' ? 'transparent' : state.theme !== 'light' ? '#001529' : '#fff',
    // background: 'transparent',
    padding: '0 1rem',
    position: 'sticky',
    top: 0,
    zIndex: 10
  };

  const handleThemeToggle = () => {
    const newValue = state.theme === Theme.Light ? Theme.Dark : Theme.Light;
    setTheme(newValue);
    dispatch({
      type: ActionTypes.Change_theme,
      payload: { theme: newValue },
    });
  };

  const handleLangToggle = () => {
    dispatch({
      type: ActionTypes.Toggle_language,
      payload: {}
    });
  };

  const handleLogout = () => keycloak?.logout();

  const handleLogin = () => keycloak?.login();

  return (
    <Layout.Header style={headerWrapperStyles}>
      <Space align='center' wrap={false} style={{width: '100%'}}>
        
        <MobileDrawer />

        {/*
          location.pathname !== '/' &&
            <>
              <Tooltip title= {t('buttons.back')}>
                <Button
                  type='text'
                  onClick={() => nav(-1)}
                  icon={<ArrowLeftOutlined />}
                  disabled={window.history.length <= 3}
                  />
              </Tooltip>
            </>
        */}

        {props.children}
        {/*
        <FloatButton.Group
          trigger="click"
          style={{
            top: 12,
            right: 12,
            bottom: -100,
            height: 48
          }}
          icon={<UserOutlined />}
          >
            <FloatButton
              tooltip={t('settings.theme.toggler')}
              onClick={handleThemeToggle}
              className='dark-toggler'
              icon={state.theme.includes(Theme.Dark) ? Theme.Light : Theme.Dark}
              style={{fontWeight: 700}}
            />

          <FloatButton
            icon={state.language}
            onClick={handleLangToggle}
            className='language-toggler'
            tooltip={
              <>
                {t('settings.language.toggler')}<br/>
                {t('settings.language.current')}{' '}{state.language}
              </>
            }
          />

          {
            keycloak?.authenticated
            ? <FloatButton
                tooltip={t('buttons.logout', {username: userData?.username} )}
                icon={<LogoutOutlined />}
                onClick={handleLogout}
              />
            : <FloatButton
                tooltip={t('buttons.login')}
                icon={<LoginOutlined />}
                onClick={handleLogin}
              />
          }
        </FloatButton.Group>
        */}
      </Space>
    </Layout.Header>
    
  );
};
