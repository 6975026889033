/**
 * this file contains default settings/configurations
 * for application build-in components
 */
const defaultTablePageSize: number[] = [
  10, 50, 100, 200 
]

const oldDefaultFieldTypeOptions = [
  { value: 'text', label: 'Regular text' },
  { value: 'textarea', label: 'Big textarea' },
  { value: 'select', label: 'Select' },
  { value: 'multi-select', label: 'Multi Select' },
  { value: 'radio', label: 'Radio (choose only one of)' },
  { value: 'checkbox', label: 'Checkbox (choose one or more)' },
  { value: 'email', label: 'Email(s)' },
  { value: 'number', label: 'Number' },
];

const defaultFieldTypeOptions = [
  { value: 'text', label: 'field.text' },
  { value: 'textarea', label: 'field.textarea' },
  { value: 'select', label: 'field.select' },
  { value: 'multi-select', label: 'field.multiSelect' },
  { value: 'radio', label: 'field.radio' },
  { value: 'checkbox', label: 'field.checkbox' },
  { value: 'email', label: 'field.email' },
  { value: 'number', label: 'field.number' },
  { value: 'url', label: 'field.url' },
  { value: 'date', label: 'field.date' },
  { value: 'date-time', label: 'field.dateTime' },
  { value: 'date-range', label: 'field.dateDuration' },
  { value: 'project-name', label: 'field.projectName' },
  { value: 'faculty-select', label: 'field.facultySelect' },
];


export default {
  defaultTablePageSize,
  defaultFieldTypeOptions
}