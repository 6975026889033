import { useEffect, useState } from 'react';

/**
 * Enumeration for application's supported theme modes.
 * - `Auto`: Theme will be determined based on system preferences.
 * - `Dark`: Dark theme mode.
 * - `Light`: Light theme mode.
 */
export enum Theme {
  Auto = "auto",
  Dark = "dark",
  Light = "light",
}

/**
 * Custom hook for managing the application's theme.
 *
 * @returns An object containing:
 * - `currentTheme`: The current theme as a string. Possible values are 'auto', 'dark', or 'light'.
 * - `isDark`: Boolean indicating if the current theme is dark mode.
 * - `setTheme`: Function to update the theme.
 */
const useTheme = () => {
  // State to store the current theme, initialized from local storage or defaulting to light theme
  const [theme, setTheme] = useState<Theme>(localStorage.getItem('theme') as Theme || Theme.Light);

  // Check if the user prefers dark mode, based on their system preferences
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  // Function to determine if the current theme is dark
  const isDarkTheme = (): boolean => {
    if (theme === Theme.Auto) return prefersDarkMode;
    return theme === Theme.Dark;
  };

  // Effect to listen for changes in the local storage
  useEffect(() => {
    const handleStorageChange = () => {
      setTheme(localStorage.getItem('theme') as Theme || Theme.Light);
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Effect to update the `data-theme` attribute on the HTML body element
  useEffect(() => {
    document.body.setAttribute(
      'data-theme',
      isDarkTheme() ? Theme.Dark : Theme.Light
    );
  }, [theme]); // Re-run this effect when `theme` changes

  // Function to update the theme both in local storage and state
  const updateTheme = (newTheme: Theme) => {
    localStorage.setItem('theme', newTheme);
    setTheme(newTheme);
  };

  return {
    currentTheme: theme,
    isDark: isDarkTheme(),
    setTheme: updateTheme
  };
};

export default useTheme;
