import { MoreOutlined, } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Flex, Table, Tag, theme } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ApplicationState } from '@App/settings/StateManager';
import { useTranslation } from 'react-i18next';

interface DataType {
  key: string;
  title: string;
}

const systemKeys = [
  'id', 'uuid', 'version', 'status',
  'created_by', 'created_at', 'updated_at',
  'form_data', 'form_filled'
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
  children: React.ReactNode | React.ReactElement | Array<React.ReactNode | React.ReactElement>
  cb?: (rowKey: string) => void;
}

const Row = (tableName: string) => ({ children, cb, ...props }: RowProps) => {
  const { state } = useContext(ApplicationState);
  const { token } = theme.useToken();
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  
  const isHidden = !state.tables?.[tableName]?.hiddenColumns?.includes(props['data-row-key']);

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {React.Children.map(children, (child, childIndex) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            key: `${(child as React.ReactElement).key}-${childIndex}`,
            style: {
              color: isHidden ? token.colorPrimary : '#ccc',
            },
            className: `custom-columns-sort ${isHidden ? 'isActive' : 'notActive'}`,
            children: [
              <div
                key={`${(child as React.ReactElement).key}-${childIndex}-inner`}
                ref={setActivatorNodeRef}
                {...listeners}
                style={{
                  display: 'flex',
                  color: isHidden ? token.colorPrimary : token.colorTextSecondary,
                  fontSize: '1.4em',
                  touchAction: 'none',
                  cursor: 'move',
                }}
              >
                <MoreOutlined style={{ margin: '0 -8px' }} />
                <MoreOutlined style={{ margin: '0 -8px' }} />
              </div>
            ],
          });
        }
        return child;
      })}
    </tr>
  );
};


type Props = {
  rows: any[];
  tableName: string;
  initialHiddenList?: string[];
  isDrawerOpen?: boolean;
  callback?: (columnsOrder: string[], hideList: string[]) => void;
}

const ColumnSelect: React.FC<Props> = (props) => {
  const [dataSource, setDataSource] = useState(props.rows);
  const [hiddenList, setHiddenList] = useState<string[]>(
    props.initialHiddenList ?? []
  );

  const { t } = useTranslation();
  
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const handleHiddenListChange = (item: string) => {
    setHiddenList( prev => {
      if (prev.includes(item)) {
        return prev.filter(i => i !== item);
      }
      return [ ...prev, item];
    })    
  };

  const handleShowAll = () => {
    setHiddenList([]);
  };

  const handleHideAll = () => {
    const allItems = props.rows.map(i => i.key);
    setHiddenList(allItems);
  };

  const columns: ColumnsType<DataType> = [
    { key: 'sort' },
    {
      key: 'isShown',
      dataIndex: 'key',
      className: 'px-0',
      render: (itemKey: string) => (
        <Checkbox
          checked={!hiddenList.includes(itemKey)}
          onChange={() => handleHiddenListChange(itemKey)}
          style={{
            // opacity: props.initialHiddenList?.includes(itemKey) ? .64 : .87,
            maxWidth: '70svw',
            fontSize: '.9em'
            // color: list.includes(itemKey) ? `rgba(${token.colorTextBase}, 0.64)` : `rgba(${token.colorTextBase}, 0.87)`
          }}
        />
      ),
    },
    {
      key: 'isHidden',
      dataIndex: 'title',
      className: 'non-compact',
      render: (title: string, record) => (
        <span
          onClick={() => handleHiddenListChange(record.key)}
          style={{ display: 'inline' }}
        >
          {title}
          {
            systemKeys.includes(record.key) && (
              <Tag
                bordered={false}
                color="processing"
                style={{ fontSize: '.8em', marginLeft: '.5rem' }}
              >
                SYSTEM
              </Tag>
            )
          }
        </span>
      ),
    },
  ];

  useEffect(() => {
    const newListOrder = dataSource.map(i => i.key as string);
    props.callback?.(newListOrder, hiddenList);
  }, [dataSource, hiddenList])

  useEffect(() => {
    if (props.isDrawerOpen == true) {
      setHiddenList(props.initialHiddenList ?? []);
    }
  }, [props.isDrawerOpen]);

  return (
    <>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="key"
            components={{
              body: {
                row: Row(props.tableName)
              },
            }}
            columns={columns}
            dataSource={dataSource}
            showHeader={false}
            pagination={false}
          />
        </SortableContext>
      </DndContext>

      <Flex justify='center' style={{ width: '100%', padding: '.5rem' }}>
        <Button
          type='text'
          onClick={handleHideAll}
          disabled={hiddenList.length == props.rows.length}
        >
          {t('table.label.hideAllColumns', 'Peida kõiki veerud')}
        </Button>
        <Button
          type='text'
          onClick={handleShowAll}
          disabled={hiddenList.length == 0}
        >
          {t('table.label.showAllColumns', 'Kuva kõiki veerge')}
        </Button>      
      </Flex>
        
    </>
  );
};

export default ColumnSelect;