import { Language } from "@AppRoot";
import { Typography, Space } from "antd";
import React from "react";

const missingStrValues = ['', ' ', 'puuduvad', 'puudub', undefined];

type ObjValueType = { [key: string]: ValueType };

type SelectValue = { value: string, label: string };

const emptyField = {
  id: 0,
  type: "",
  name: "",
  label: "",
  settings: {
    order: 0,
    required: false,
    dependencies: null,
    options: []
  }
}

export type ValueType = boolean | string | string[] | ObjValueType | ObjValueType[] | SelectValue | SelectValue[];

export const hasDependenceValue = (formValues: object, field: AppFields.FieldType) => {
  if (field.settings.dependencies == undefined) {
    return;
  }

}

export const renderValue = (
  value: ValueType,
  className: string = '',
  isCompactMode: boolean = false,
  field: AppFields.FieldType = emptyField,
  language: Language = Language.ET): JSX.Element => {

  if (typeof value === 'string') {
    return (
      <Typography.Text
        className={className}
        type={missingStrValues.includes(value) ? 'danger' : undefined}
        style={{
          margin: 0,
          whiteSpace: isCompactMode ? 'pre' : 'normal'
        }}
      >
        {field.settings.options?.length
          ? field.settings.options?.find(option => option.value == value)?.label[language] ?? value
          : value}
      </Typography.Text>
    )
  }
  else if (Array.isArray(value)) {
    return (
      <Space
        direction={isCompactMode ? 'horizontal' : 'vertical'}
        split={isCompactMode ? <span style={{ marginRight: 5 }}>,</span> : null}
        style={{ gap: 0 }}
      >
        {value.map((item, index) => (
          <React.Fragment key={index}>
            {renderValue(item, className, isCompactMode, field, language)}
            {/* {!isCompactMode && index < value.length - 1 ? <br/> : null} */}
          </React.Fragment>
        ))}
      </Space>
    );
  }
  else if (typeof value === 'object' && value !== null) {
    if ('value' in value && 'label' in value) {
      return (
        <Typography.Text
          className={className}
          // @ts-ignore
          type={missingStrValues.includes(value) ? 'danger' : undefined}
          style={{
            margin: 0,
            whiteSpace: isCompactMode ? 'pre' : 'normal'
          }}
        >
          {/* @ts-ignore */}
          {value.label}
        </Typography.Text>
      );
    } else {
      // @ts-ignore
      return (
        <>
          {Object.entries(value).map(([key, val], index) => (
            <Typography.Paragraph
              key={index}
              // @ts-ignore
              type={missingStrValues.includes(val) ? 'danger' : undefined}
              style={{
                margin: 0,
                whiteSpace: 'pre'
              }}
            >
              <strong>{key}:</strong> {renderValue(val, className, isCompactMode, field, language)}
            </Typography.Paragraph>
          ))}
        </>
      );
    }
  } else {
    return (
      <Typography.Text
        // @ts-ignore
        type={missingStrValues.includes(value) ? 'warning' : undefined}
      >
        {/* @ts-ignore */}
        {missingStrValues.includes(value) && '-'}
      </Typography.Text>
    )
  }
}

/**
 * Converts a given string to a unique hexadecimal color code.
 * 
 * @param str - The input string to be converted to a color.
 * @param alpha - Optional alpha value for the color (0-1). If provided, adds opacity to the color.
 * @return A hexadecimal color string. If alpha is provided, the color will include opacity.
 */
export function stringToColor(str: string, alpha?: number): string {
  // Initialize hash to zero. This will be used to generate a number based on the input string.
  let hash = 0;

  // Loop through each character in the string
  for (let i = 0; i < str.length; i++) {
    // For each character, update the hash value. This uses bitwise operations to create a unique hash.
    // str.charCodeAt(i) gets the ASCII value of the char at position i.
    // (hash << 5) - hash is equivalent to hash * 32 - hash, a common way to mix up the bits.
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Start building the color string
  let color = '#';

  // Generate a 6-digit hexadecimal color code
  for (let i = 0; i < 3; i++) {
    // Extract parts of the hash to create RGB color values
    const value = (hash >> (i * 8)) & 0xFF;

    // Convert the value to hexadecimal and append it to the color string
    // Ensures the hexadecimal is always two digits
    color += ('00' + value.toString(16)).slice(-2);
  }

  // If an alpha value is provided, process it
  if (alpha !== undefined) {
    // Convert alpha to a 0-255 range and then to a hexadecimal string
    const alphaValue = Math.round(alpha * 255);
    color += ('00' + alphaValue.toString(16)).slice(-2);
  }

  // Return the final color string
  return color;
}
