import {
  HomeOutlined,
  PlusOutlined,
  ProfileOutlined,
  QuestionOutlined,
  FileTextOutlined,
  SearchOutlined,
  ProjectOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  ScheduleOutlined,
  LockOutlined,
  WechatOutlined,
  ToolOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
  DatabaseOutlined,
  GlobalOutlined, FormOutlined,
} from '@ant-design/icons';
import React from 'react';

const mainIconStyles = {
  fontSize: '1.1rem'
}
const admin = ['admin', 'ak-admin', 'ak-admins'];
const ttr = ['ttr-edit', 'ttr-read'];
const ut = ['ut_tootajad_auto'];
const devEnv = ['uma_authorization'];

export const permissions = {
  admin,
  ttr: [...admin, ...ttr],
  ut: [...admin, ...ut, ...ttr],
  user: ['uma_authorization'],
}


export const newNav: Array<App.NavGroup | App.NavItem> = [
  {
    key: '1',
    label: 'links.homepage',
    icon: <HomeOutlined style={mainIconStyles} />,
    roles: [],
    path: '/',
  },
  {
    key: '80',
    label: 'links.TTR',
    icon: <ProjectOutlined style={mainIconStyles} />,
    roles: permissions.ttr,
    items: [
      {
        key: '80-1',
        label: 'links.new.data',
        icon: <PlusOutlined />,
        roles: permissions.ttr,
        path: '/forms/',
      },
      {
        key: '80-2',
        label: 'links.TTRlist',
        icon: <DatabaseOutlined />,
        roles: permissions.ttr,
        path: '/oppetoo/',
      },
      {
        key: '80-3',
        label: 'links.researchProjects',
        icon: <DatabaseOutlined />,
        // roles: permissions.ut,
        path: '/researchProjects/',
      },
      // {
      //   key: '80-4',
      //   label: 'links.myProjects',
      //   icon: <UnorderedListOutlined />,
      //   roles: permissions.ttr,
      //   path: '/user/projects',
      // },
      // {
      //   key: '80-0',
      //   label: 'links.userInfo',
      //   icon: <InfoCircleOutlined />,
      //   roles: permissions.ttr,
      //   path: '/user',
      // }
    ],
  },
  {
    key: '31',
    label: 'links.projects',
    icon: <ProjectOutlined style={mainIconStyles} />,
    roles: ['admin'],
    items: [
      {
        key: '31-5',
        label: 'links.new.data',
        icon: <PlusOutlined />,
        roles: permissions.admin,
        path: '/projects/forms',
      },
      {
        key: '31-333',
        label: 'links.myProjects',
        icon: <FileTextOutlined />,
        roles: permissions.admin,
        path: '/user/projects',
      },
      {
        key: '31-3',
        label: 'links.myFaculties',
        icon: <FileTextOutlined />,
        roles: permissions.admin,
        path: '/user/faculties',
      },
      {
        key: '31-4',
        label: 'links.allProjects',
        icon: <FileTextOutlined />,
        roles: permissions.admin,
        path: '/manage/projects',
      },
    ],
  },
  {
    key: '50',
    label: 'links.manage.title',
    icon: <ToolOutlined style={mainIconStyles} />,
    roles: permissions.admin,
    items: [
      {
        key: '5-5',
        label: 'links.manage.translations',
        icon: <GlobalOutlined />,
        path: '/manage/translations',
        roles: permissions.admin,
      },
      {
        key: '5-1',
        type: 'group',
        label: 'data',
        path: '/manage/forms/fields/new',
        icon: <DatabaseOutlined />,
        roles: permissions.admin,
        //@ts-ignore
        children: [
          {
            // key: '5-1-1',
            key: '/manage/ttr/art30Projects/',
            label: 'links.TTRlist',
            // icon: <DatabaseOutlined />,
            roles: permissions.admin,
            path: '/manage/ttr/art30Projects/',
          },
          {
            // key: '5-1-2',
            key: '/manage/ttr/researchProjects/',
            label: 'links.researchProjects',
            // icon: <DatabaseOutlined />,
            roles: permissions.admin,
            path: '/manage/ttr/researchProjects/',
          },
        ]
      },
      {
        key: '5-2',
        type: 'group',
        label: 'forms',
        path: '/manage/forms/fields/new',
        icon: <FormOutlined />,
        roles: permissions.admin,
        //@ts-ignore
        children: [
          {
            key: '/manage/forms/builder',
            label: 'links.manage.forms',
            roles: permissions.admin,
          },
          {
            key: '/manage/forms/fields/new',
            label: 'links.new.field',
            roles: permissions.admin,
          },
          {
            key: '/manage/forms/builder/new',
            label: 'links.new.form',
            roles: permissions.admin,
          },
        ]
      },
      {
        key: '5-3',
        type: 'group',
        label: 'access',
        path: '/manage/forms/fields/new',
        icon: <LockOutlined />,
        roles: permissions.admin,
        //@ts-ignore
        children: [
          {
            key: '/manage/access/users/',
            label: 'links.manage.access',
            roles: permissions.admin,
          },
          {
            key: '/manage/access/faculties',
            label: 'links.manage.faculties',
            roles: permissions.admin,
          },
          {
            key: '/manage/access/groups',
            label: 'links.manage.groups',
            roles: permissions.admin,
          },
        ]
      },
    
      // {
      //   key: '50-1',
      //   label: 'links.new.field',
      //   icon: <PlusOutlined />,
      //   path: '/manage/forms/fields/new',
      //   roles: permissions.admin,
      // },
      // {
      //   key: '50-2',
      //   label: 'links.new.form',
      //   icon: <PlusOutlined />,
      //   path: '/manage/forms/builder/new',
      //   roles: permissions.admin,
      // },
      // {
      //   key: '50-3',
      //   label: 'links.manage.forms',
      //   icon: <ProjectOutlined />,
      //   path: '/manage/forms/builder',
      //   roles: permissions.admin,
      // },
      // {
      //   key: '50-4',
      //   label: 'links.manage.access',
      //   icon: <LockOutlined />,
      //   path: '/manage/access/users',
      //   roles: permissions.admin,
      // },
      // {
      //   key: '50-6',
      //   label: 'links.TTRlist',
      //   icon: <DatabaseOutlined />,
      //   roles: permissions.admin,
      //   path: '/manage/ttr/art30Projects/',
      // },
      // {
      //   key: '80-7',
      //   label: 'links.researchProjects',
      //   icon: <DatabaseOutlined />,
      //   roles: permissions.admin,
      //   path: '/manage/ttr/researchProjects/',
      // },
    ],
  },
  // {
  //   key: '11',
  //   label: 'links.reportViolation',
  //   icon: <NotificationOutlined style={mainIconStyles} />,
  //   roles: [],
  //   path: '/report',
  // },
  // {
  //   key: '15',
  //   label: 'links.FAQ',
  //   icon: <QuestionOutlined style={mainIconStyles} />,
  //   roles: [],
  //   path: '/kkk',
  // },
];

// The userHasAccess function remains the same as before
export function hasAccess(pageRoles: string[], userRoles: string[]): boolean {
  return pageRoles.some(role => userRoles.includes(role));
  // return roles.every(role => userRoles.includes(role));
}
// export function userHasAccess(roles: string[], userRoles: string[]): boolean {
//   return roles.some(role => userRoles.includes(role));
//   // return roles.every(role => userRoles.includes(role));
// }

export const userHasAccess = ( userRoles?: string[] ) => (pageData: App.NavItem | App.NavGroup ): boolean => {
  if ( !pageData.roles || pageData.roles.length == 0 ) {
    return true
  }
  if ( userRoles ) {
    return hasAccess(pageData.roles, userRoles)
  };
  // return !pageData.roles || pageData.roles.length == 0 || (userRoles && hasAccess(pageData.roles, userRoles));
  // return roles.every(role => userRoles.includes(role));
  return false;
}

/**
 * 
 * @returns {object} where a key presents nav item key and value presents nav item path
 */
export function createKeyPathMapping(): Record<string, string> {
  const mapping: Record<string, string> = {};

  newNav.forEach(page => {
    if ('path' in page) {
      mapping[page.key] = page.path;
    }
    
    if ('items' in page) {
      page.items.forEach(subPage => {
        if ('path' in subPage) {
          mapping[subPage.key] = subPage.path;
        }
      });
    }
  });
  return mapping;
}
