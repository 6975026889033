import { UnderConstruction } from './UnderConst';
import { FAQ } from './FAQ';

import { ErrorPage } from './ErrorPage';
import { UnauthorizedPage } from './UnauthorizedPage';
import { LandingPage } from './LandingPage';
import { ManagePage } from './ManagePage';
import { SearchPage } from './SearchPage';
import ReportPage from './ReportViolationForm';

import { AdminFormView } from './AdminFormView';
import { FieldSingle } from './manage/forms/FieldSingle';
import { FieldsList } from './manage/forms/FieldsList';
import { FormBuilder } from './manage/forms/FormBuilder';
import { FormsList } from './manage/forms/FormsList';
import { NewFieldPage } from './manage/forms/NewField';

import { Project } from './user/Project';
import { UserFaculties } from './user/UserFaculties';

import { AccessView } from './AccessView';

import { Permission } from './manage/Permission';
import { FacultyFormPage } from './manage/access/FacultyFormPage';
import { ProfilePage } from './user/ProfilePage';
import { GroupPage } from './manage/Group';
import TranslationPage from './manage/TranslationPage';
import TestKnowledge from './TestKnowledge';

import { UserResearches } from './user/UserResearches';
import { AdminResearchProjects } from './manage/ttr/AdminResearchProjects';
import { AdminArt30Projects } from './manage/ttr/AdminArt30Projects';
import { UserTTR } from './user/UserTTR';
import { AdminProjects } from './manage/ttr/AdminProjects';
import { MaintenancePage } from './MaintenancePage';

import { UserFormPage } from './manage/access/UserFormPage';
import { UsersList } from './manage/access/UsersList';
import { FacultiesList } from './manage/access/FacultiesList';
import { PermissionsList } from './manage/access/PermissionsList';
import { GroupList } from './manage/access/GroupList';

import { FormsPage } from './forms/FormsPage';
import { SingleFormPage } from './forms/SingleFormPage';
import { FormsView } from './forms/FormsView';
import { FormsErrorPage } from './forms/FormsErrorPage';
import { ManageFormPage } from './manage/forms/ManageFormPage';
import { ManageFieldPage } from './manage/forms/ManageFieldPage';

/**
 * To import all application pages/views from this folder
 * @usage import * as Pages from "root/src/views"
 * @return all application pages/views
 */

const pages = {
  MaintenancePage,
  UnauthorizedPage,
  ProfilePage,
  LandingPage,
  FormsList,
  FormBuilder,
  FieldsList,
  ManagePage,
  FieldSingle,
  ErrorPage,
  NewFieldPage,
  
  Project,
  FAQ,
  
  SearchPage,
  UsersList,
  
  FacultiesList,
  FacultyFormPage,
  UnderConstruction,
  GroupList,
  PermissionsList,
  Permission,
  AccessView,
  AdminFormView,
  ReportPage,
  UserFaculties,
  
  TranslationPage,
  TestKnowledge,
  GroupPage,
  UserTTR,
  UserResearches,
  
  UserFormPage,
  SingleFormPage,
  FormsErrorPage,
  FormsPage,
  FormsView,
  
  ManageFormPage,
  ManageFieldPage,
  AdminResearchProjects,
  AdminProjects,
  AdminArt30Projects
};

export default pages;
