import {
    App,
    Button,
    Col,
    Descriptions,
    Empty, Grid,
    Input,
    List,
    Row,
    Space,
    Tag,
    theme,
    Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import React, {useContext, useState} from 'react';

import { ApplicationState } from '@App/settings/StateManager';
import { DividerTitle } from '@App/components/texts/DividerTitle';
import { useKeycloak } from '@App/settings/keycloak';
import ContactInfo from '@App/components/ContactInfo';
import PageWrapper from '@App/components/wrappers/PageWrapper';
import config from "@AppConfig";
import {highlightText} from "@App/components/helpers";
import {NumberOutlined} from "@ant-design/icons";


const NoFaculties: React.FC = () => (
  <Empty
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{ height: 60 }}
    description={
      <span>
        Customize <a href="#API">Description</a>
      </span>
    }
  >
    <Button type="primary">Create Now</Button>
  </Empty>
);

type LdapUserInfo = {
  id: any;
  firstName: string;
  lastName: string;
  username: string;
};

const {Text, Paragraph} = Typography;

export function ProfilePage() {
  const { state: {session} } = useContext(ApplicationState);
  const { keycloak, userData } = useKeycloak();
  const { t, i18n } = useTranslation(['forms', 'info']);
  const { token } = theme.useToken();
  const { md } = Grid.useBreakpoint();
  const { message } = App.useApp();

  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [searchUser, setSearchUser] = useState<string>('');
  const [users, setUsers] = useState<LdapUserInfo[] | undefined>(undefined);

  // Fetch users from Keycloak User Search API
  const fetchUsers = async (searchTerm: string) => {
    if (keycloak?.token) {
      setUserLoading(true);
      const response = await fetch(
        `${config.keycloak.url}/admin/realms/${config.keycloak.realm}/users?search=${searchTerm}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${keycloak?.token}` // Use the Keycloak token here
          }
        }
      );

      if (response.ok) {
        const data = await response.json();
        setUsers(data); // Save the list of users
      } else {
        console.error('Error fetching users from Keycloak:', response);
        message.error(`'Error fetching users from Keycloak: ${response}`)
      }
    }
  };

  const handleSearchChange = (searchTerm: string) => {
    setSearchUser(searchTerm);
    // Call fetchUsers after a delay to avoid spamming the API with every keystroke
    if (!userLoading && searchTerm.length > 2) {
      fetchUsers(searchTerm).finally(() => setUserLoading(false));
    }
  };

  const handleUserSelect = (user: LdapUserInfo) => {

  };

  return (
    <PageWrapper>

      <Row>
        <Col span={24}>
          <Button.Group>
              <Button onClick={()=> console.log(session)}>session</Button>
              <Button onClick={()=> console.log(keycloak?.tokenParsed)}>keycloak</Button>
              <Button onClick={()=> console.log(userData)}>userData</Button>
          </Button.Group>
          {/* */}

          <Typography.Title style={{ fontFamily: 'Rubik' }}>
            { t('pages.title.myData') }
          </Typography.Title>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
            <Input.Search
              enterButton
              loading={userLoading}
              value={searchUser}
              onChange={({target}) => setSearchUser(target.value)}
              onSearch={(s) => handleSearchChange(s)}
              placeholder={t('placeholders.searc', "Search for users by name")}
            />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
            {users === undefined ? (
              <Paragraph>
                  Search for users by name to get started
              </Paragraph>
            ) : (
            <List<LdapUserInfo>
              grid={{
                lg: 3,
                md: 2,
                sm: 1,
                xs: 1
              }}
              itemLayout={'horizontal'}
              size={'small'}
              loading={userLoading}
              dataSource={users}
              renderItem={(user, i) => {
                return (
                  <List.Item onClick={() => handleUserSelect(user)}>
                    <List.Item.Meta
                      avatar={<Text type={'secondary'}><NumberOutlined />{i + 1}</Text>}
                      title={
                        <>
                          {highlightText(`${user.firstName} ${user.lastName}`, searchUser, token.colorInfoBgHover)}
                          <Tag color={'processing'} bordered={false}>{user.username}</Tag>
                        </>
                      }
                    />
                  </List.Item>
                )
              }}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <DividerTitle title={t('pages.user.info') } />

          <Descriptions layout="horizontal" >

            <Descriptions.Item label={t('site.label.utUsername') } span={3}>
              {userData?.username}
            </Descriptions.Item>

            <Descriptions.Item label={t('site.label.firstname') }>
              {userData?.firstName}
            </Descriptions.Item>

            <Descriptions.Item label={t('site.label.lastname') }>
              {userData?.lastName}
            </Descriptions.Item>
            
            <Descriptions.Item label={t('site.label.email') } >
              {userData?.email}
            </Descriptions.Item>

            {/* <Descriptions.Item label={t('forms.label.userFaculties') }>
            { session.faculties.length }
            </Descriptions.Item>

            <Descriptions.Item label={t('forms.label.userFaculties') }>
            <Button onClick={() => console.log(session)}>
              console
            </Button>
            </Descriptions.Item> */}

          </Descriptions>
        </Col>
      </Row>
      
      <Row>
        <Col span={24}>
          <DividerTitle title={t('pages.section.assignedFaculties') } />
          <List
            // header={t('forms.label.faculties') }
            itemLayout="vertical"
            dataSource={session.faculties}
            locale={{
              emptyText: t('')
            }}
            renderItem={(item: API.FacultyItem, index:number) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Tag
                      bordered={false}
                      // color={token.colorPrimary}
                      color="processing"
                    >
                      {item.code}
                    </Tag>
                  }
                  title={item.labels[i18n.language]}
                  // description={JSON.stringify(item.name, null, 2)}
                  />
              </List.Item>
            )}
            style={{ marginLeft:'2rem'}}
          />
        </Col>
      </Row>

      {/*
      <Row>
        <Col>
          <Descriptions layout="horizontal" column={1}>
            <Descriptions.Item label={t('label.access.roles', {ns: 'info'}) } >
              <pre>
              { JSON.stringify(keycloak?.realmAccess, null, 2)}
              </pre>
            </Descriptions.Item>
            <Descriptions.Item label={t('label.access.keycloak.userprofile', {ns: 'info'}) } >
              <pre>
              { JSON.stringify(keycloak?.tokenParsed?.['groups'], null, 2)}
              </pre>
            </Descriptions.Item>
            <Descriptions.Item label={t('label.access.resource', {ns: 'info'}) } >
              <pre>
              { JSON.stringify(keycloak?.resourceAccess, null, 2)}
              </pre>
            </Descriptions.Item>
            <Descriptions.Item label="KeyCloak" span={1}>
              <Collapse
                // onChange={onChange}
                items={[{
                  key: '1',
                  label: 'Keycloak data',
                  children: <pre style={{maxWidth:'55vw'}}>{JSON.stringify(keycloak, null, 2)}</pre>
                },
              ]}
              >
              </Collapse>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      */}

      <Row>
        <Col span={24}>
          <DividerTitle title={t('site.label.contact') } />
          <ContactInfo />
        </Col>
      </Row>

    </PageWrapper>
  );
}
