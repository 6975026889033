import { AppLayout } from './Layout';
import { LayoutWithSidebar } from './LayoutWithSidenav';
import { Protected } from './Protected';
import { ProtectedWithSidebar } from './ProtectedWithSidenav';

const layouts = {
  AppLayout,
  LayoutWithSidebar,
  ProtectedWithSidebar,
  Protected
};

export default layouts;
