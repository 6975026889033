import type { LocalTranslationsType } from "@App/@types/translationTypes";
import { Language } from "@AppRoot";

/**
 * Local translations available immediately on application load
 * These translations are used before backend translations are fetched
 * Organized by language and stored in the 'local' namespace
 *
 * Add here only translations that are internally without backend
 */
export const localTranslations: LocalTranslationsType = {
  [Language.ET]: {
    local: {
      "site.name": "Isikuandmete töötlemistoimingute register",
      "site.shortName": "TTR",
      "site.loading": "Laadimine...",
      "site.loadingAuth": "Autoriseerimine...",
      "site.loadingTranslations": "Tõlgete laadimine...",
      "site.authFailed": "Autentimisteenuse laadimine ebaõnnestus",
      "site.initializing": "initsialiseerimine ...",
    }
  },
  [Language.EN]: {
    local: {
      "site.name": "Register of personal data processing operations",
      "site.shortName": "TTR",
      "site.loading": "Loading...",
      "site.loadingAuth": "Authorization service loading...",
      "site.loadingTranslations": "Translations loading...",
      "site.authFailed": "Failed to load authentication service",
      "site.initializing": "initializing ...",
    }
  }
};
