import { Button, Grid, Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import React from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import {newNav, userHasAccess} from '@App/settings/navigation';
import { AppLogo } from '@App/components/Logo';
import { ApplicationState } from '@App/settings/StateManager';
import { MobileLogo } from '../MobileLogo';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@App/settings/keycloak';
import {ItemType} from "antd/lib/menu/interface";


type MenuItem = Required<MenuProps>['items'][number];

interface IHeaderProps {
  navItems?: MenuItem[];
  role?: 'public' | 'user' | 'admin';
}

const { useBreakpoint } = Grid;

export const AppHeader: React.FC<IHeaderProps> = (props) => {
  const { state } = React.useContext(ApplicationState);
  const [ collapsed, setCollapsed ] = React.useState<boolean>(false);

  const { t } = useTranslation('nav');
  const { keycloak } = useKeycloak();
  const screens = useBreakpoint();

  const formatNavItem = ({label, ...rest}: App.NavItem | App.NavGroup): ItemType => {
      //@ts-ignore
    return { ...rest, label: t(label) }
  }
  
  const mainMenuItems = newNav
    .filter(
      userHasAccess(state.session.permissions) ||
      userHasAccess(keycloak?.realmAccess?.roles)
    )
    .map((pageItem) => ('items' in pageItem || 'children' in pageItem)
      ? {
          type: 'submenu',
          ...pageItem,
          label: t(pageItem.label),
            //@ts-ignore
          children: pageItem.items.filter(
              userHasAccess(state.session.permissions) ||
              userHasAccess(keycloak?.realmAccess?.roles)
            )
            .map(formatNavItem)
        }
      : formatNavItem(pageItem)
    );
  
  return (
    <Layout.Header className="header" style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      width: '10%',
      display: 'flex',
      alignItems: 'center',
    }}>

      <div className="logo" style={{ maxHeight: 70, maxWidth:360 }}>
        <AppLogo />asd
      </div>

      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: '16px',
          width: 64,
          height: 64,
        }}
      />
      <div className='logo-wrapper'>
        <a  
          type='link'
          href='/'
          style={{
            margin: '.5rem .75rem 0',
            color: state.theme === 'dark' ? '#fff' : '#2C5696'
          }}>
          { screens.xs ? <MobileLogo /> : <AppLogo /> }
        </a>
      </div>

      {}
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['2']}
        //@ts-ignore
        items={mainMenuItems}
      />

    </Layout.Header>
  );
};
