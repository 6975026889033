import {I18nConfig} from "@App/@types/translationTypes";
import KC from "@App/@types/keycloakTypes";


const isBuild = import.meta.env.PROD || import.meta.env.MODE === 'production';

const backendUrlFallback = isBuild ? `https://${window.location.hostname}` : 'http://localhost:8001';

/**
 * @return {string} development | production | staging accepted environment values
 */
function getEnvironment(): 'development'|'production'|'staging' {
    const isHostStaging = window.location.hostname === 'demo.ak.ut.ee';
    const isHostProduction = window.location.hostname === 'ak.ut.ee';

    if (isBuild && isHostStaging) return 'staging';
    else if (isBuild && isHostProduction) return 'production';
    else return 'development';
}

/**
 * @return {string} development | production | staging accepted environment values
 */
function getBackendUrl(): string {
    const isHostStaging = window.location.hostname.includes('demo.ak.ut.ee');
    const isHostProduction = window.location.hostname.includes('ak.ut.ee');

    if (import.meta.env.MODE === 'staging' || isHostStaging) return 'https://demo.ak.ut.ee';
    else if (isBuild && isHostProduction) return 'https://ak.ut.ee';
    else {
        return import.meta.env?.VITE_APP_PUBLIC_URL?.length > 0
            ? import.meta.env.VITE_APP_PUBLIC_URL
            : 'http://localhost:8001'
    }
}

const keycloakConfig: KC.Config = {
  url: import.meta.env.VITE_KEYCLOAK_URL || 'https://keycloak.hpc.ut.ee',
  realm: import.meta.env.VITE_KEYCLOAK_REALM || 'HPC',
  clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID || 'AK-test'
}

const elasticApmConfig = {
  serverUrl: 'https://apm.hpc.ut.ee',
  serviceName: 'ak-frontend',
  environment: import.meta.env.MODE || getEnvironment()
}

export const i18nConfig: I18nConfig = {
  apiUrl: import.meta.env.VITE_APP_BACKEND || getBackendUrl(),
  apiVersion: 'v4',
  saveMissing: false,
  defaultLanguage: 'et',
  fallbackLanguages: ['et', 'en'],
  debug: import.meta.env.VITE_APP_DEBUG,
};

export const config: App.Config & { i18nConfig: I18nConfig } = {
  env: import.meta.env.MODE,
  backendUrl: import.meta.env.VITE_APP_BACKEND || getBackendUrl(),
  keycloak: keycloakConfig,
  elasticApm: elasticApmConfig,
  i18nConfig
};

// uncomment if front should console log application configuration values
if (!import.meta.env.PROD) {
    const confItems = ['[APP CONFIG]\n'];
    Object.entries(config).map(([key, val]) => {
        if (typeof val === 'string') {
            confItems.push(`${key} (${typeof val}): ${val}\n`);
        } else {
            confItems.push(`${key} (${typeof val}):`);
            confItems.push(val);
        }
    });
    console.info(...confItems);
}

export default config;
