import {
  Form, Input, Select, Space, Switch, Typography, message, FormProps, theme,
} from 'antd';
import type { FilterFunc } from 'rc-select/lib/Select';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import React from 'react';

import { DataContext } from '@App/views/AccessView';
import { Language } from '@AppRoot';
import { handler } from '@App/settings/ApiHandler';
import KC from "@App/@types/keycloakTypes";
// import { useKeycloak } from '@App/settings/keycloak';
// import JsonValidator from '@App/components/fields/JsonValidator';


const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const postFaculty = (kc: KC.KCType, payload: API.PayloadType, uuid?: string) => handler({
  method: 'POST',
  path: '/v2/manage/faculties/' + (uuid ?? ''),
  payload,
}, kc);

type FacultyFormProps = FormProps & {
  action: 'new'|'update';
  onUpdate?: (values: object) => void;
  children?: React.ReactNode;
}

export const FacultyForm: React.FC<FacultyFormProps> = ({ action, onUpdate,...props}) => {
  const {faculties, facultyOptions} = React.useContext(DataContext);

  const { t, i18n } = useTranslation();
  const { token } = theme.useToken();
  const { uuid } = useParams();

  const filterFaculties: FilterFunc<API.FacultyItem> = (input, option) => {
    return (
      option?.code.toLowerCase().includes(input.toLowerCase()) ||
      option?.name.toLowerCase().includes(input.toLowerCase()) ||
      // option?.labels.toString().toLowerCase().includes(input.toLowerCase()) ||
      false
    );
  }

  const formatFacultyItems = (faculty: API.FacultyItem, idx:number) => {
    return {
      key: faculty.id || `${idx+1}-${faculty.code}`,
      value: faculty.id || faculty.code,
      name: faculty.name,
      search: faculty.name,
      code: faculty.code,
      label: (
        <Space key={`${idx}-${faculty.code}`}>
          <Typography.Text strong style={{ color: token.colorPrimary }} >
            {faculty.code}
          </Typography.Text>
          <Typography.Text>
            {faculty.labels && faculty.labels?.[i18n.language as Language]}
          </Typography.Text>
        </Space>
      )
    }
  }

  return (
    <Form
      name={t(`forms.titles.${action}`)}
      layout='horizontal'
      onFinish={onUpdate && onUpdate}
      {...layout}
      {...props}
    >
      <Form.Item
        name={['parent']}
        label={t('forms.label.facultyParent')}
      >
        <Select
          placeholder={t('forms.placeholders.selectFacultyParent')}
          showSearch
          allowClear
        // @ts-ignore
          options={facultyOptions.map(formatFacultyItems)}
          filterOption={filterFaculties}
          filterSort={(optionA, optionB) =>
            (optionA?.code.toString() ?? '').toLowerCase().localeCompare((optionB?.code.toString() ?? '').toLowerCase())
          }
        />
      </Form.Item>

      <Form.Item
        name={['code']}
        label={t('forms.label.faculty_code')}
        rules={[{ required: true }]}
        >
        <Input
          showCount
          maxLength={60}
        />
      </Form.Item>

      <Form.Item
        name={['name']}
        label={t('forms.label.facultyName')}
        rules={[{ required: true }]}
      >
        <Input
          showCount
          maxLength={250}/>
      </Form.Item>

      <Form.Item
        name={['labels', 'et']}
        label={t('forms.label.etTranslation')}
        rules={[{ required: true }]}
      >
        <Input
          showCount
          maxLength={2000}
        />
      </Form.Item>

      <Form.Item
        name={['labels', 'en']}
        label={t('forms.label.enTranslation')}
        rules={[{ required: true }]}
      >
        <Input
          showCount
          maxLength={2000}
        />
      </Form.Item>

      {/* <Form.Item
        name={['category']}
        label={t('forms.label.isFacultyCategory')}
        valuePropName='checked'
      >
        <Switch
          checkedChildren={t('forms.label.yes')}
          unCheckedChildren={t('forms.label.no')}
        />
      </Form.Item>

      <Form.Item
        name={['parent']}
        label={t('forms.label.facultyParent')}
      >
        <Select
          placeholder={t('forms.placeholders.selectFacultyParent')}
          showSearch
          allowClear
          options={facultyOptions.map(formatFacultyItems)}
          filterOption={filterFaculties}
          filterSort={(optionA, optionB) =>
            (optionA?.code.toString() ?? '').toLowerCase().localeCompare((optionB?.code.toString() ?? '').toLowerCase())
          }
        />
      </Form.Item> */}

      <Form.Item
        name='description'
        label={t('forms.label.facultyDescription')}
        rules={[{ required: false }]}
      >
        <Input.TextArea showCount maxLength={1000}/>
      </Form.Item>

      <Form.Item
        name={['category']}
        label={t('forms.label.isFacultyCategoryy', 'Kas tegu on kategoori üksusega (nt Valdkonnad) ')}
        valuePropName='checked'
        colon={false}
        labelCol={{ span: 24 }}
        // wrapperCol={{ span: 18, offset: 6 }}
      >
        <Switch
          checkedChildren={t('forms.label.yes')}
          unCheckedChildren={t('forms.label.no')}
          style={{margin: 5}}
        />
      </Form.Item>

      {props.children}

    </Form>
  );
};
