import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { AppFooter } from '@App/components/footer';
import Header from '@App/components/header';


export const AppLayout: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      
      <Header.AppHeader />
      
      <Layout.Content style={{ margin: '0 16px' }}>

        <Header.AppBreadcrumbs />

        <Outlet />

      </Layout.Content>
        
      <AppFooter />

    </Layout>
  );
};
