import {
  FilterOutlined, SettingOutlined, DownOutlined, ClearOutlined,
  ReloadOutlined, PlusOutlined, CheckSquareOutlined,
} from '@ant-design/icons';
import {
  Badge, Button, Dropdown, Flex, Grid, MenuProps, Space, Tag,
  theme, Tooltip, Typography 
} from "antd";
import { useTranslation } from "react-i18next";
import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import { ApplicationState } from '@App/settings/StateManager';


export const statusItems: string[] = [
  'confirmed', 'active', 'pending', 'inactive', 'draft', 'deleted'
];

type AdminStatusProps = {
  itemCount: number,
  initialValue: string,
  isLoading?: boolean,
  onChange: (v:string)=> void,
}

const AdminStatusSetter: FC<AdminStatusProps> = (props) => {
  const [newStatus, setStatus] = useState<string|undefined>(undefined);
  const {t} = useTranslation();

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setStatus(e.key);
  };

  const items = statusItems.map((i)=> ({
    key: i,
    label: t(`settings.status.${i}`)
  }));
  
  useEffect(() => {
    if (newStatus || newStatus !== props.initialValue) {
      setStatus(props.initialValue)
    }
  }, [props.initialValue])
  
  return  (
    <Dropdown.Button
      type={ newStatus !== props.initialValue ? "primary" : undefined}
      icon={<DownOutlined />}
      loading={props.isLoading}
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      onClick={() => newStatus && props.onChange(newStatus)}
    >
      { newStatus
        ? `(${props.itemCount}) ${t(`settings.status.${newStatus}`)}`
        : t('settings.label.selectEntryStatus', {count:props.itemCount})
      }
    </Dropdown.Button>
  );
}


interface TableTitleProps {
  isAdmin: boolean;
  tableName: string;
  tableTitle: string;
  tableConfig: App.TableMeta;
  selectedRowsLength?: number;
  cb?: (newValues: App.TableMeta) => void;
  onAdd?: () => void;
  onRefresh?: () => void;
  doRefresh?: () => void;
  onAction?: () => void;
  onConfirm?: (newStatus: string) => void;
  onOpen?: (drawerName: string) => void;
  onOpenConfig?(): void;
  clearFilters: () => void;
  renderCounter?: number;
  extraButtons?: ReactNode | ReactNode[];
  isFilterable: boolean;
}

export const TableTitle: FC<TableTitleProps> = (props) => {
  const {sm, lg, xl} = Grid.useBreakpoint();
  const {t} = useTranslation();
  const {token} = theme.useToken();

  const handleStatusChange = (newStatus: string = 'confirmed') => {
    props.onConfirm && props.onConfirm(newStatus);
  };

  return (
    <Flex
      justify='space-between'
      vertical={!sm}
      wrap='wrap'
      gap={14}
      align='center'
    >
      <Flex gap={6} align='center'>
        <Typography.Title
          level={4}
          style={{
            color: token.colorTextSecondary,
            margin: 0
          }}
        >
          {t(props.tableTitle, props.tableName)}
        </Typography.Title>
        {
          props.renderCounter && (
            <Tag
              color="processing"
              style={{
                borderRadius: "10rem",
                fontSize: ".9em",
                padding: '3px 6px'
              }}
            >
              {props.renderCounter}
            </Tag>
          )
        }
      </Flex>

      <Space wrap>
        {
          (
            typeof props.selectedRowsLength === 'number'
            && props.selectedRowsLength > 0
          ) ? props.isAdmin
              ? <AdminStatusSetter  
                  initialValue=""
                  onChange={handleStatusChange}
                  itemCount={props.selectedRowsLength}
                />
              : <Button
                  type='primary'
                  shape='round'
                  title={t('forms.confirm.data')}
                  onClick={() => handleStatusChange()}
                  icon={<CheckSquareOutlined />}
                >
                  {
                    (
                      props.selectedRowsLength &&
                      props.selectedRowsLength > 0
                    )
                      ? t('settings.label.confirmEntry', {count: props.selectedRowsLength ?? 0})
                      : null
                  }
                </Button>
            : null
        }
        {
          (
            props.isFilterable &&
            props.tableConfig.filters &&
            // typeof props.tableConfig.filters === 'object' &&
            Object.values(props.tableConfig.filters).filter(i=>i).length
          ) ? (
            <Tooltip title={t('forms.btn.clearAllFilters')}>
              <Button
                shape='circle'
                icon={<ClearOutlined style={{fontSize: '.9em'}} />}
                onClick={props.clearFilters}
              />
            </Tooltip>
            ) : null
        }

        { props.extraButtons }

        {
          props.onAdd && (
            <Button
              type='primary'
              shape="round"
              icon={<PlusOutlined />}
              onClick={props.onAdd}
            >
              {lg && t('links.new.data')}
            </Button>
          )
        }
        {
          props.onRefresh && (
            <Tooltip title={t('buttons.refresh')}>
              <Button icon={<ReloadOutlined />} onClick={props.onRefresh}>
                {lg && t('buttons.refresh')}
              </Button>
            </Tooltip>
          )
        }
        {/*
          props.isFilterable && (
            <Badge
              size="small"
              count={filtersCount}
              style={{ zIndex: 999 }}
            >
              <Button
                shape="round"
                icon={<FilterOutlined style={{fontSize: '.9em'}}/>}
                onClick={() => props.onOpen?.('filters')}
                style={{
                  color: filtersCount ? token.colorPrimary : 'inherit'
                }}
              >
                {t('forms.btn.filters')}
              </Button>
            </Badge>
          )
        */}
        <Tooltip title={t('table.title.tableConfig')}>
          <Button
            shape="circle"
            icon={<SettingOutlined style={{fontSize: '.9em'}}/>}
            onClick={props.onOpenConfig}
          />
        </Tooltip>

      </Space>
    </Flex>
  )
}
