import { ActionMap } from './types';
import { ActionTypes, AllActions } from './';
import { Language } from '@AppRoot';

type LanguagePayload = {
  [ActionTypes.Change_language]: {
    language: Language;
  };
  [ActionTypes.Toggle_language]: {};
};

export type LanguageActions =
  ActionMap<LanguagePayload>[keyof ActionMap<LanguagePayload>];

export const languageReducer = (
  state: Language,
  action: AllActions
) => {
  switch (action.type) {
    case ActionTypes.Change_language:
      return action.payload.language;
    
    case ActionTypes.Toggle_language:
      return state === Language.ET ? Language.EN : Language.ET;

    default:
      return state;
  }
};
