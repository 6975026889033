import React from 'react';

import { InitialState, initialState } from './initialState';
import {ActionTypes, AllActions, mainReducer} from './reducers';
import {useTranslation} from "react-i18next";
import {useKeycloak} from "./keycloak";
import {LoadingPage} from "../views/LoadingPage";
import {handler} from "./ApiHandler";


/**
 * Application state manager
 * @param state current state value
 * @param dispatch a dispatch func for update state
 */
interface StateManager {
  state: InitialState;
  dispatch: React.Dispatch<AllActions>;
}

const ApplicationState = React.createContext<StateManager>({
  state: initialState,
  dispatch: () => null,
});

type InitState = {
  translationsLoaded: boolean;
  userDataLoaded: boolean;
  error?: Error;
  currentStep: string;
};
const StateManager: React.FC<{ children: React.ReactNode }> = (props) => {
  const [state, dispatch] = React.useReducer(mainReducer, initialState);
  const { authenticated, userData, keycloak } = useKeycloak();
  const { i18n } = useTranslation();
  const [initState, setInitState] = React.useState<InitState>({
    translationsLoaded: false,
    userDataLoaded: false,
    currentStep: 'Loading translations...'
  });

  // Handle translations loading
  const loadTranslations = React.useCallback(async () => {
    try {
      setInitState(prev => ({ ...prev, currentStep: 'Loading translations...' }));
      const userLang = userData?.locale || state.language || 'et';
      await i18n.changeLanguage(userLang);

      setInitState(prev => ({
        ...prev,
        translationsLoaded: true,
        currentStep: 'Loading user data...'
      }));
    } catch (error) {
      console.error('Failed to load translations:', error);
      setInitState(prev => ({
        ...prev,
        error: error instanceof Error ? error : new Error('Failed to load translations')
      }));
    }
  }, [i18n, userData?.locale, state.language]);

  // Handle user data loading
  const loadUserData = React.useCallback(async () => {
    if (!keycloak) return;

    try {
      setInitState(prev => ({ ...prev, currentStep: 'Loading user data...' }));

      const payload: API.GetRequest = {
        method: 'GET',
        path: '/v2/user'
      };

      const userData = await handler<API.UserType>(payload, keycloak);

      const ldapGroups = keycloak.tokenParsed?.groups || [];
      dispatch({
        type: ActionTypes.Update_user,
        payload: {
          ...state.session,
          roles: userData.roles || [],
          groups: [...ldapGroups, ...(userData.groups || [])],
          faculties: userData.faculties || [],
          permissions: userData.permissions || [],
        }
      });

      setInitState(prev => ({
        ...prev,
        userDataLoaded: true,
        currentStep: 'Initialization complete'
      }));
    } catch (error) {
      console.error('Failed to load user data:', error);
      setInitState(prev => ({
        ...prev,
        error: error instanceof Error ? error : new Error('Failed to load user data')
      }));
    }
  }, [keycloak, state.session]);

  // Sequential initialization
  React.useEffect(() => {
    if (authenticated && !initState.translationsLoaded) {
      loadTranslations();
    }
  }, [authenticated, initState.translationsLoaded, loadTranslations]);

  React.useEffect(() => {
    if (initState.translationsLoaded && !initState.userDataLoaded) {
      loadUserData();
    }
  }, [initState.translationsLoaded, initState.userDataLoaded, loadUserData]);

  // Show loading state if still initializing
  if (!authenticated || !initState.translationsLoaded || !initState.userDataLoaded) {
    return (
      <LoadingPage
        currentStep={initState.currentStep}
        error={initState.error}
        onRetry={!initState.translationsLoaded ? loadTranslations : loadUserData}
      />
    );
  }

  return (
    <ApplicationState.Provider value={{ state, dispatch }}>
      {props.children}
    </ApplicationState.Provider>
  );
};

export { StateManager, ApplicationState };
