import React, { useState } from 'react';
import { Form, Input, Button, message, Typography, List, Row, Col, Spin, Steps } from 'antd';
import Questionnaire from '@App/components/forms/FormFlow';

interface ReportFormData {
  name: string;
  email: string;
  description: string;
}

type UrlItem = {
  key: string;
  name: string;
  path: string;
  icon?: React.ReactNode;
}
const mockLinks: UrlItem[] = [
  { key: "5", name: "Korduma kippuvad küsimused", path: "/kkk"},
  { key: "1", name: "Andmekaitse põhimõisted", path: "/to/blin"},
  { key: "2", name: "Privacy Policy", path: "/privacy-policy"},
  { key: "3", name: "Data Protection Guidelines", path: "/data-protection-guidelines"},
  { key: "4", name: "Terms of Use", path: "/terms-of-use"},
];

type QuestionType = {
  question: string;
  options: string[];
  correctAnswer: number; // index of the correct option
}
const steps = [
  {
    title: 'First',
    content: 'First-content',
  },
  {
    title: 'Second',
    content: 'Second-content',
  },
  {
    title: 'Last',
    content: 'Last-content',
  },
];

const sampleQuestions: QuestionType[] = [
  {
    question: "What does GDPR stand for?",
    options: ["Global Data Protection Regulation", "General Data Processing Regulation", "General Data Protection Rule", "General Data Protection Regulation"],
    correctAnswer: 3
  },
  {
    question: "What does GDPR stand for?",
    options: ["Global Data Protection Regulation", "General Data Processing Regulation", "General Data Protection Rule", "General Data Protection Regulation"],
    correctAnswer: 3
  },
  {
    question: "What does GDPR stand for?",
    options: ["Global Data Protection Regulation", "General Data Processing Regulation", "General Data Protection Rule", "General Data Protection Regulation"],
    correctAnswer: 3
  },
];

const TestKnowledge: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const onFinish = async (values: ReportFormData) => {
    try {
      setLoading(true);

      // Simulate sending data to the server using Fetch API
      const response = await fetch('your-api-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        message.success('Violation report submitted successfully! Thank you for helping us ensure data protection.');
        form.resetFields();
      } else {
        message.error('Failed to submit violation report. Please try again later.');
      }
    } catch (error) {
      message.error('An error occurred while submitting the report. Please try again later.');
    } finally {
      // setLoading(false);
    }
  };

  const onAnonymousSubmit = () => {
    form.validateFields().then((values) => {
      const anonymousValues: ReportFormData = {
        name: 'Anonymous',
        email: 'anonymous@example.com',
        description: values.description,
      };
      onFinish(anonymousValues);
    });
  };

  const renderLinks = (item: UrlItem) => (
    <List.Item
      // style={{ justifyContent: 'center' }}
    >
      <Typography.Link href={item.path}>
        { item.name }
      </Typography.Link>
    </List.Item>
  );
  return (
    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      
      <Row style={{ marginTop: '2rem' }}>
        <Col span={24}>
          <Typography.Title level={2} style={{ textAlign: 'center', marginBottom: '1rem' }}>
            Test Your GDPR Knowledge
          </Typography.Title>
        </Col>
      </Row>

      <Row style={{ marginTop: '2rem' }}>
        <Col span={24}>

          <Spin spinning={loading}>
          <Steps current={current} items={items} />
          <Questionnaire questions={sampleQuestions} />
          </Spin>

        </Col>
      </Row>

      <Row style={{ marginTop: '2rem' }}>
        <Col span={24}>
          <Typography.Title level={3}>
            Learn More about Data Protection:</Typography.Title>
          <List
            rowKey='key'
            dataSource={mockLinks}
            renderItem={renderLinks}
          />
        </Col>
      </Row>

    </div>
  );
};

export default TestKnowledge;
