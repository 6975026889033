import {ReloadOutlined, UserAddOutlined, UserDeleteOutlined} from '@ant-design/icons';
import { App, Modal, Input, List, Typography, Tag, Spin, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { highlightText } from './helpers';
import { useKeycloak } from "@App/settings/keycloak";
import config from "@AppConfig"; // Assuming this is your helper function

const { Paragraph, Text } = Typography;

const baseKeycloakUsersUrl = `${config.keycloak.url}/admin/realms/${config.keycloak.realm}/users`;

const fetchUsers = (searchTerm: string, token: string) => fetch(
    `${baseKeycloakUsersUrl}?search=${searchTerm}`,
    {
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
    }
);

export type LdapUserInfo = {
    // id: string | number | React.Key;
    username: string;
    lastName: string;
    firstName: string;
    email?: string;
};

type UserSearchModalProps = {
    visible: boolean | undefined;
    onClose: () => void;
    onSelectUser: (user: LdapUserInfo, action: 'add' | 'update') => void;
    existingUsers?: LdapUserInfo[] | Responses.UserType[];
}

const UserSearchModal: React.FC<UserSearchModalProps> = ({ visible, onClose, onSelectUser, existingUsers = [] }) => {
    const [userLoading, setUserLoading] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [users, setUsers] = React.useState<LdapUserInfo[] | undefined>(undefined);

    const { t } = useTranslation(['forms', 'info']);
    const { keycloak } = useKeycloak();
    const { message } = App.useApp();

    // Fetch users from Keycloak User Search API
    const fetchKeycloakUsers = async (searchTerm: string) => {
        if (keycloak?.token) {
            setUserLoading(true);
            try {
                const response = await fetchUsers(searchTerm, keycloak?.token);
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data);
                } else {
                    message.error(`Error ${response.status} fetching users from Keycloak.`);
                }
            } catch (error) {
                console.dir(error)
                message.error('Something went wrong!');
            } finally {
                setUserLoading(false);
            }
        }
    };

    const handleSearchChange = (searchTerm: string) => {
        setSearchTerm(searchTerm);
        if (!userLoading && searchTerm.length > 2) {
            fetchKeycloakUsers(searchTerm);
        }
    };

    // Helper function to check if the user is in the existing list
    const userExists = (username: string) => {
        return existingUsers.some(user => user.username === username);
    };

    const handleUserSelect = (user: LdapUserInfo, action: 'add' | 'update') => {
        onSelectUser(user, action);
        // onClose();
    };

    return (
        <Modal
            title={t('forms.title.searchUtUsers')}
            open={visible}
            onCancel={onClose}
            footer={null}
            centered
            styles={{
                content: {
                    borderRadius: '2rem'
                },
            }}
            width={'80%'}
        >
            <Input.Search
                enterButton
                allowClear
                variant={'filled'}
                loading={userLoading}
                value={searchTerm}
                onChange={({ target }) => setSearchTerm(target.value)}
                onSearch={(s) => handleSearchChange(s)}
                placeholder={t('forms.placeholders.searchUTUsers')}
                className="ut-users-search-input"
                height={100}
            />

            {userLoading ? (
                <Spin>
                    <Paragraph style={{ marginTop: '1rem' }}>
                        {t('site.loading')}...
                    </Paragraph>
                </Spin>
            ) : users === undefined ? (
                <Paragraph type={'secondary'} style={{ textAlign: 'center', marginTop: '1rem' }}>
                    {t('forms.description.searchUTUsers', 'Search university users by first, last name or e-mail')}
                </Paragraph>
            ) : (
                <List<LdapUserInfo>
                    header={
                        <Text
                            strong
                            type={'secondary'}
                            style={{ fontSize: '.9em' }}
                        >
                            {t('forms.result.searchUTUsersCount', {count: users.length })}
                        </Text>
                    }
                    itemLayout="horizontal"
                    size={'small'}
                    dataSource={users}
                    renderItem={(user, i) => {
                        const isExistingUser = userExists(user.username);
                        return (
                            <List.Item
                                extra={
                                    <Button
                                        shape={'round'}
                                        size={'small'}
                                        type={isExistingUser ? 'primary' : 'dashed' }
                                        icon={isExistingUser ? <UserDeleteOutlined /> : <UserAddOutlined />}
                                        className={'user-action-button'}
                                        onClick={() => handleUserSelect(user, isExistingUser ? 'update' : 'add')}
                                    >
                                        {t(`forms.btn.${isExistingUser ? 'remove' : 'add'}`)}
                                    </Button>
                                }
                            >
                                <List.Item.Meta
                                    title={highlightText(`${user.firstName} ${user.lastName}`, searchTerm)}
                                    description={<Tag color="processing" bordered={false}>{user.email}</Tag>}
                                />
                            </List.Item>
                        );
                    }}
                />
            )}
        </Modal>
    );
};

export default UserSearchModal;
